import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetEmployeesList } from '../../hooks/useGetEmployeesList';

export const AssignLeaves = ({ rp }) => {

  const selectedRP = rp.find(role => role.name === 'Assign Leaves') || {};
  const navigate = useNavigate();

  const [assignedLeaves, setAssignedLeaves] = useState([]);

  const [employees, setEmployees] = useState([]);
  const { getEmployees } = useGetEmployeesList();

  const isAdmin = JSON.parse(localStorage.getItem('edetails'))['rName'] === 'Admin';

  // For get
  async function fetchAssignedLeaves() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/assignLeaves/getAssignedLeaves.php?isAdmin=${isAdmin}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        let message = "";
        if (response.status === 401) {
          // Handle unauthorized access
          message = 'Unauthorized access. Please login again.';
          localStorage.clear();
          navigate('/login');
        } else {
          // Handle other error responses
          message = 'Network response was not ok';
        }
        throw new Error(message);
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (error) {
        throw new Error('Error parsing JSON response');
      }

      if (responseData.success) {
        setAssignedLeaves(responseData.assignedLeaves);
      } else {
        alert(responseData.error);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    fetchAssignedLeaves();
    (async () => {
      const response = await getEmployees();
      setEmployees(response.filter(employee => employee.status == 1).map(employee => ({ username: employee.username, employeeID: employee.employeeID })));
    })();
  }, []);

  // For delete 
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this assigned leave?")) {
      return;
    }
    const formData = new FormData();
    formData.append('id', id);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/assignLeaves/deleteAssignedLeave.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });

      if (!response.ok) {
        let message = "";
        if (response.status === 401) {
          // Handle unauthorized access
          message = 'Unauthorized access. Please login again.';
          localStorage.clear();
          navigate('/login');
        } else {
          // Handle other error responses
          message = 'Network response was not ok';
        }
        throw new Error(message);
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (error) {
        throw new Error('Error parsing JSON response');
      }

      if (responseData.success) {
        alert(responseData.message);
      } else {
        alert(responseData.error);
      }

    } catch (error) {
      console.error('An error occurred in delete:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      fetchAssignedLeaves();
    }
  }
  // For add and edit
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // b: button (Add/Edit)
  const [bType, setBType] = useState('');
  const [leave, setLeave] = useState({
    id: null,
    assigned_paid_leaves: null,
    employeeID: null,
    from_date: null,
    to_date: null,
  });

  const handleAdd = () => {
    setBType('Add');
    setLeave({
      id: undefined,
      assigned_paid_leaves: 1,
      employeeID: undefined,
      from_date: new Date().toISOString().slice(0, 10),
      to_date: new Date().toISOString().slice(0, 10),
    })
    handleShow();
  }

  const handleEdit = (leaveDetails) => {
    const { id, assigned_paid_leaves, employeeID, from_date, to_date } = leaveDetails;
    setBType('Edit');
    setLeave({
      id: id,
      assigned_paid_leaves: assigned_paid_leaves,
      employeeID: employeeID,
      from_date: from_date,
      to_date: to_date,
    })
    handleShow();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    //Append all form data to formData
    Object.keys(leave).forEach(key => {
      formData.append(key, leave[key]);
    });

    let fileName = '';
    if (bType === 'Add') {
      fileName = "assignLeave.php";
    } else {
      fileName = "editAssignedLeave.php";
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/assignLeaves/${fileName}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData
      });

      if (!response.ok) {
        let message = "";
        if (response.status === 401) {
          // Handle unauthorized access
          message = 'Unauthorized access. Please login again.';
          localStorage.clear();
          navigate('/login');
        } else {
          // Handle other error responses
          message = 'Network response was not ok';
        }
        throw new Error(message);
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (error) {
        throw new Error('Error parsing JSON response');
      }

      if (responseData.success) {
        alert(responseData.message);
        handleClose();
      } else {
        alert(responseData.error);
      }
    } catch (error) {
      console.error('An error occurred in adding or editing leave:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      fetchAssignedLeaves();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeave({ ...leave, [name]: value });
  }
  return (
    <main className="main" id='main'>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                <h5 className="card-title">Assigned Leaves</h5>
                {(selectedRP && (selectedRP.add == 1)) && (
                  <button type="button" className="btn btn-sm btn-secondary" onClick={handleAdd}>
                    <i className="bi bi-plus-lg" /><span className='ms-1'>Add</span>
                  </button>)}
              </div>
              {(selectedRP && (selectedRP.view == 1)) ?
                (<table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {isAdmin && <th scope="col">Employee Name</th>}
                      <th scope="col">Duration</th>
                      <th scope="col">Assigned Leaves</th>
                      <th scope="col">Used Leaves</th>
                      {(selectedRP && ((selectedRP.delete == 1) || (selectedRP.edit == 1))) &&
                        (<th scope="col" className='text-center'>Actions</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {assignedLeaves.map((leave, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        {isAdmin && <td>{leave.employee_name}</td>}
                        <td>{new Date(leave.from_date).toLocaleDateString("en-GB")} - {new Date(leave.to_date).toLocaleDateString("en-GB")}</td>
                        <td>{leave.assigned_paid_leaves}</td>
                        <td>{leave.used_paid_leaves}</td>
                        {(selectedRP && ((selectedRP.delete == 1) || (selectedRP.edit == 1))) &&
                          (<td className='text-center'>
                            <div className='d-flex justify-content-center gap-2'>
                              {(selectedRP && (selectedRP.edit == 1)) &&
                                (<button className='btn btn-sm btn-primary' onClick={() => handleEdit(leave)}>
                                  <i className="bi bi-pencil-fill" />
                                </button>)}
                              {(selectedRP && (selectedRP.delete == 1)) &&
                                (<button className='btn btn-sm btn-danger' onClick={() => handleDelete(leave.id)}>
                                  <i className="bi bi-trash3-fill" />
                                </button>)}
                            </div>
                          </td>)}
                      </tr>
                    ))}
                  </tbody>
                </table>) : <p className='text-center'>No Access</p>}

              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <h5 className="modal-title">{bType} Leave</h5>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <input type="hidden" name="id" value={leave.id} />
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Select Employee</label>
                      <select name="employeeID" id="employeeID" className="form-select" onChange={handleChange} value={leave.employeeID}
                        aria-label="Default select example">
                        <option value="" hidden={true}>Select Employee</option>
                        {employees.map((employee) => (
                          <option key={employee.employeeID} value={employee.employeeID}>{employee.username}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">From Date</label>
                      <input type="date" className="form-control" id="name"
                        name="from_date" value={leave.from_date}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">To Date</label>
                      <input type="date" className="form-control" id="name"
                        name="to_date" value={leave.to_date}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        {bType == 'Add' ? 'Assign' : 'Assigned'} Leaves</label>
                      <input type="number" className="form-control" id="name"
                        name="assigned_paid_leaves" value={leave.assigned_paid_leaves}
                        onChange={handleChange} />
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type='submit' className="btn btn-sm btn-primary">Save changes</button>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
