import { create } from 'zustand';

const useTimeCounter = create((set) => {
    let timer;

    const reinitialize = async () => {
        let initialStartTime = 0;
        let timeTaken = 0;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/checkTime.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    localStorage.clear();
                }
                // Handle other error responses
                throw new Error('Network response was not ok.');
            }

            const responseData = await response.json();
            if (responseData.success && responseData.taskmode && responseData.taskmode === 'Start') {
                const startedTime = new Date(responseData.dateandtime);
                const currentTime = new Date();
                const time = Math.floor(currentTime - startedTime);
                initialStartTime = time;
                if (responseData.timetaken) {
                    timeTaken = responseData.timetaken;
                }
            }
        } catch (error) {
            console.error('Error initializing time counter:', error);
        }

        // Update the startTime state after initialization
        set({ startTime: initialStartTime });

        if (initialStartTime > 0) {
            startTimer(timeTaken);
        }
    };

    const startTimer = (timeTaken) => {
        const [hours, minutes, seconds] = timeTaken.split(':').map(Number);
        const totalMilliSeconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
        set((state) => {
            return { startTime: state.startTime + totalMilliSeconds };
        });
        timer = setInterval(() => {
            set((state) => {
                return { startTime: state.startTime + 1000 };
            });
        }, 1000);
    };

    const stopTimer = () => {
        clearInterval(timer);
        set({ startTime: 0 });
    };

    return {
        startTime: 0,
        reinitialize,
        startTimer,
        stopTimer
    };
});

export default useTimeCounter;
