// Add or edit task
import React, { useEffect, useRef, useState } from 'react';
import useGetTaskTypes from '../../hooks/useGetTaskTypes';
import { Link, useNavigate } from 'react-router-dom';
import { useGetProjectsList } from '../../hooks/useGetProjectsList';
import useGetMembers from '../../hooks/useGetMembers';
import useGetTeams from '../../hooks/useGetTeams';
import { Loader } from '../Loader';

export const AddTask = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const doc1Ref = useRef(null);
    const doc2Ref = useRef(null);
    const doc3Ref = useRef(null);

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);

    const { getTaskTypes } = useGetTaskTypes();
    const [taskTypes, setTaskTypes] = useState([]);

    const { getProjects } = useGetProjectsList();
    const [projects, setProjects] = useState([]);

    const { getTeams } = useGetTeams();
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        (async () => setMembers((await getMembers()).filter(member => member.status == 1)))();
        (async () => setTaskTypes(await getTaskTypes()))();
        (async () => setProjects(await getProjects()))();
        (async () => setTeams(await getTeams()))();
    }, []);

    const [task, setTask] = useState({
        title: '',
        taskpriority: '',
        ttId: '',
        description: '',
        timerequired: '',
        status: '1',
        doc1: null,
        doc2: null,
        doc3: null,
        assignedTo: '',
        pId: '',
        team_id: '',
        startDate: new Date().toISOString().slice(0, 10),
        deadline: new Date().toISOString().slice(0, 10)
    });
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            setTask({ ...task, [name]: e.target.files[0] });
        } else {
            setTask({ ...task, [name]: value });
        }
    }
    const handleSubmit = async (e) => {

        e.preventDefault();
        // validation
        if (!task.title || !task.taskpriority || !task.ttId || !task.description || !task.timerequired || !task.assignedTo || !task.pId || !task.team_id || !task.startDate) {
            alert('Please fill all the required fields');
            return;
        }
        const formData = new FormData();
        Object.keys(task).forEach(key => {
            formData.append(key, task[key]);
        });

        formData.append('pName', projects.find(p => p.pId == task.pId).pName);
        formData.append('assignedToName', members.find(m => m.employeeID == task.assignedTo).username);

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/addTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                // it is not necessary
                navigate('/tasks/taskslist');
                alert(responseData.message);
                // setTask({
                //     title: '',
                //     taskpriority: '',
                //     ttId: '',
                //     description: '',
                //     timerequired: '',
                //     status: '1',
                //     doc1: null,
                //     doc2: null,
                //     doc3: null,
                //     assignedTo: '',
                //     pId: ''
                // });
                // doc1Ref.current.value = "";
                // doc2Ref.current.value = "";
                // doc3Ref.current.value = "";
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the addition of task:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    }
    return (
        <main className="main" id='main'>
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between ">
                                <h5 className="card-title">Add Task</h5>
                                <Link to="/tasks/taskslist" className="btn btn-danger">
                                    <i className="bi bi-arrow-left" />
                                </Link>
                            </div>
                            <form className="row g-3" onSubmit={handleSubmit}>
                                <div className="col-9">
                                    <label htmlFor="title" className="form-label">
                                        Title <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="title"
                                        name="title" value={task.title} onChange={handleChange} required />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="taskpriority" className="form-label">
                                        Task Priority <span className="text-danger">*</span>
                                    </label>
                                    <select className="form-select" required
                                        name="taskpriority" value={task.taskpriority} onChange={handleChange}>
                                        <option hidden>Select Priority</option>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                        <option value="Critical">Critical</option>
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="description" className="form-label">
                                        Description <span className="text-danger">*</span>
                                    </label>
                                    <textarea className="form-control" id="description" required
                                        name="description" value={task.description} onChange={handleChange}>
                                    </textarea>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select className="form-select" name="status" value={task.status} onChange={handleChange}>
                                        <option hidden>Select Status</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="taskType" className="form-label">
                                        Task Type <span className="text-danger">*</span>
                                    </label>
                                    <select className="form-select" name="ttId" value={task.ttId} onChange={handleChange} required>
                                        <option hidden>Select Task Type</option>
                                        {taskTypes.map((taskType) => <option key={taskType.ttId} value={taskType.ttId}>{taskType.ttTitle}</option>)}
                                    </select>
                                </div>
                                {/* <div className="col-4">
                                    <label htmlFor="taskstatus" className="form-label">Task Status</label>
                                    <select className="form-select" name="taskstatus">
                                        <option hidden="">Select Status of Task</option>
                                        <option value="Not Attempted">Not Attempted</option>
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Deployed">Deployed</option>
                                        <option value="Completed">Completed</option>
                                        <option value="In Review by Client">In Review by Client</option>
                                        <option value="In Review by Senior">In Review by Senior</option>
                                    </select>
                                </div> */}
                                <div className="col-4">
                                    <label htmlFor="timerequired" className="form-label">
                                        Time Required {"(in mins)"} <span className="text-danger">*</span>
                                    </label>
                                    <input type="number" step={"0.01"} className="form-control" id="timerequired" required
                                        name="timerequired" value={task.timerequired} onChange={handleChange} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="assignedTo" className="form-label">
                                        Assign to <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="assignedTo" required
                                            name="assignedTo" value={task.assignedTo} onChange={handleChange}>
                                            <option hidden>Select Member</option>
                                            {members.map((employee) => <option key={employee.employeeID} value={employee.employeeID}>
                                                {employee.username}
                                            </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="projects" className="form-label">
                                        Project <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="projects" required
                                            name="pId" value={task.pId} onChange={handleChange}>
                                            <option hidden>Select Project</option>
                                            {projects.map((project) => <option key={project.pId} value={project.pId}>{project.pName}</option>)}

                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="teams" className="form-label">
                                        Team <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="teams" required
                                            name="team_id" value={task.team_id} onChange={handleChange}>
                                            <option hidden>Select Team</option>
                                            {teams.map((team) => <option key={team.tId} value={team.tId}>{team.tName}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="startDate" className="form-label">Start Date <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" id="startDate"
                                        name="startDate" value={task.startDate} onChange={handleChange} required/>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="deadline" className="form-label">Deadline</label>
                                    <input type="date" className="form-control" id="deadline"
                                        name="deadline" value={task.deadline} onChange={handleChange}/>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc1" className="form-label">Document 1</label>
                                    <input type="file" className="form-control" id="doc1"
                                        name="doc1" onChange={handleChange} ref={doc1Ref} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc2" className="form-label">Document 2</label>
                                    <input type="file" className="form-control" id="doc2"
                                        name="doc2" onChange={handleChange} ref={doc2Ref} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc3" className="form-label">Document 3</label>
                                    <input type="file" className="form-control" id="doc3"
                                        name="doc3" onChange={handleChange} ref={doc3Ref} />
                                </div>

                                {/* <div className="col-6">
                                    <label htmlFor="assignedby" className="form-label">Assign By</label>
                                    <input type="text" className="form-control" id="assignedby" name="assignedby" />
                                </div> */}
                                {/* <div className="col-6">
                                    <label htmlFor="approvedby" className="form-label">Approved By</label>
                                    <input type="text" className="form-control" id="approvedby" name="approvedby" />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="approveddate" className="form-label">Approved Date</label>
                                    <input type="date" className="form-control" id="approveddate" name="approveddate" />
                                </div> */}
                                <div className="d-flex justify-content-center">
                                    <button href="#" type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
