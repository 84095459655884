import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useGetMembers = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const getMembers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/getMembers.php`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert("Unauthorized access. Please login again.");
                    localStorage.clear();
                    navigate("/login");
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error("Network response was not ok.");
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                /*
                ==> responseData.members will be an array of members
                ----------------------------------------------------
                [
                    {
                        "email": "john.doe@example.com",
                        "employeeID": 1,
                        "phoneNumber": "1234567890",
                        "rId": 1,
                        "status": 1,
                        "username": "john_doe"
                    }, {...}, {...}
                ]
                */
                return responseData.members;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        } finally {
            setLoading(false);
        }
    };

    return { loading, getMembers };
}

export default useGetMembers;