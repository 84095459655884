import React, { useEffect, useState } from 'react'
import { LeaveForm } from './LeaveForm';
import { useNavigate, useParams } from 'react-router-dom';

const EditLeave = () => {

    const id = useParams().id;
    const [leaveForm, setLeaveForm] = useState({
        from_date: new Date(),
        to_date: new Date(),
        reason: '',
        leave_category_id: '',
        question: '',
        username: '',
        current_status: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchLeaveDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/getLeaveDetails.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ id })
                });

                if (!response.ok) {
                    let message = "";
                    if (response.status === 401) {
                        // Handle unauthorized access
                        message = 'Unauthorized access. Please login again.';
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        message = 'Network response was not ok';
                    }
                    throw new Error(message);
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    const data = responseData.leave_details;
                    setLeaveForm({
                        from_date: new Date(data.from_date),
                        to_date: new Date(data.to_date),
                        reason: data.reason,
                        leave_category_id: data.leave_category_id,
                        question: data.question,
                        username: data.username,
                        leave_status_id: data.leave_status_id,
                        id: data.id,
                        current_status: data.current_status
                    })
                } else {
                    alert(responseData.error);
                }

            } catch (error) {
                alert(error.message);
            }
        }

        fetchLeaveDetails();
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/editLeave.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(leaveForm)
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                navigate('/leave/all-leaves');
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <main id="main" className="main">
            <section className="section">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-center">
                            <h5 className="card-title">EDIT APPLIED LEAVE</h5>
                            {/* <Link to="/leave/all-leaves" className="btn btn-danger"><i className="bi bi-arrow-left"></i></Link> */}
                        </div>
                        <LeaveForm leaveForm={leaveForm} setLeaveForm={setLeaveForm} handleFormSubmit={handleFormSubmit} editMode={true} />
                    </div>
                </div>
            </section>
        </main>
    )
}

export default EditLeave
