import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import useGetTaskTypes from '../../hooks/useGetTaskTypes';
import useTaskTypes from '../../store/useTaskTypes';
import { useNavigate } from 'react-router-dom';

export const TaskType = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Task Type') || {};
    const navigate = useNavigate();
    // For get
    const { taskTypes, setTaskTypes } = useTaskTypes();
    const { getTaskTypes } = useGetTaskTypes();
    // For get
    useEffect(() => {
        if (taskTypes.length === 0) {
            (async () => setTaskTypes(await getTaskTypes()))();
        }
    }, []);

    // For delete 
    const handleDelete = async (ttId) => {
        if (!window.confirm("Are you sure you want to delete this task type?")) {
            return;
        }
        const formData = new FormData();
        formData.append('ttId', ttId);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/taskType/deleteTaskType.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }
            if (responseData.success) {
                alert(responseData.message);
                setTaskTypes(taskTypes.filter((taskType) => taskType.ttId !== ttId));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in deleting task type:', error);
            alert('An error occurred. Please try again later.');
        }
    }
    // For add and edit
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    // b: button 
    const [bType, setBType] = useState('');
    const [taskType, setTaskType] = useState({
        ttId: '',
        ttTitle: '',
        status: true
    });
    const handleAdd = () => {
        setBType('Add');
        setTaskType({
            ttId: '',
            ttTitle: '',
            status: true
        })
        handleShow();
    }

    const handleEdit = (ttDetails) => {
        const { ttId, ttTitle, status } = ttDetails;
        setBType('Edit');
        setTaskType({
            ttId: ttId,
            ttTitle: ttTitle,
            status: status === '1' ? true : false
        })
        handleShow();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!taskType.ttTitle) {
            alert('Task type title cannot be empty');
            return;
        }

        const formData = new FormData();

        //Append all form data to formData
        Object.keys(taskType).forEach(key => {
            formData.append(key, taskType[key]);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/taskType/${bType.toLowerCase()}TaskType.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setTaskTypes(await getTaskTypes());
                handleClose();
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in adding or editing task type:', error);
            alert('An error occurred. Please try again later.');
        }
    }
    return (
        <main className="main" id='main'>
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">TYPES OF TASK</h5>
                                {(selectedRP && (selectedRP.add == 1)) && (
                                    <button type="button" className="btn btn-secondary" onClick={handleAdd}>
                                        <i className="bi bi-plus-lg" /><span className='ms-1'>Add</span>
                                    </button>)}
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ?
                                (<table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Status</th>
                                            {(selectedRP && ((selectedRP.delete == 1) || (selectedRP.edit == 1))) &&
                                                (<th scope="col" className='text-center'>Actions</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taskTypes.map((tt, index) => (
                                            <tr key={tt.ttId}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{tt.ttTitle}</td>
                                                <td>{tt.status === '1' ? 'Active' : 'Inactive'}</td>
                                                {(selectedRP && ((selectedRP.delete == 1) || (selectedRP.edit == 1))) &&
                                                    (<td className='text-center'>
                                                        <div className='d-flex justify-content-center gap-2'>
                                                            {(selectedRP && (selectedRP.edit == 1)) &&
                                                                (<button className='btn btn-primary' onClick={() => handleEdit(tt)}>
                                                                    <i className="bi bi-pencil-fill" />
                                                                </button>)}
                                                            {(selectedRP && (selectedRP.delete == 1)) &&
                                                                (<button className='btn btn-danger' onClick={() => handleDelete(tt.ttId)}>
                                                                    <i className="bi bi-trash3-fill" />
                                                                </button>)}
                                                        </div>
                                                    </td>)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>) : <p className='text-center'>No Access</p>}
                            <Modal show={showModal} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <h5 className="modal-title">{bType} Task Type</h5>
                                </Modal.Header>
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">
                                                Title <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" id="name"
                                                name="ttTitle" value={taskType.ttTitle}
                                                onChange={(e) => setTaskType({ ...taskType, ttTitle: e.target.value })}
                                                placeholder="Enter Task Type" />
                                        </div>
                                        <fieldset className="mb-3">
                                            <label className="form-label pt-0">Status</label>
                                            <div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio"
                                                        id="gridRadios1" checked={taskType.status}
                                                        onChange={() => setTaskType({ ...taskType, status: true })} />
                                                    <label className="form-check-label" htmlFor="gridRadios1">
                                                        Active
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="gridRadios"
                                                        id="gridRadios2" checked={!taskType.status}
                                                        onChange={() => setTaskType({ ...taskType, status: false })} />
                                                    <label className="form-check-label" htmlFor="gridRadios2">
                                                        Inactive
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="modal-footer">
                                        <button type='submit' className="btn btn-primary">Save changes</button>
                                    </div>
                                </form>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    )
}
