import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetEmployeesList } from '../../hooks/useGetEmployeesList';
// import useGetPermissions from '../../hooks/useGetPermissions';

const EDMain = ({ rp, setRP }) => {

  const [selectedRP, setSelectedRP] = useState(rp.find(role => role.name === 'Employee Details') || {});
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);

  const { getEmployees } = useGetEmployeesList();
  // const { getPermissions } = useGetPermissions();

  useEffect(() => {
    (async () => setEmployees(await getEmployees()))();
    // (async () => {
    //   const res = await getPermissions();
    //   if (res != false) {
    //     setRP(res);
    //     setSelectedRP(res.find(role => role.name === 'Employee Details') || {});
    //   }
    // })();
  }, []);

  const deleteEmployee = async (id) => {
    const takePermission = window.confirm('Are you sure you want to delete this user?');
    if (!takePermission) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/employeeDetails/deleteEmployee.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ employeeID: id })
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Handle unauthorized access
          alert('Unauthorized access. Please login again.');
          localStorage.clear();
          navigate('/login');
        } else {
          // Handle other error responses
          throw new Error('Network response was not ok');
        }
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (error) {
        throw new Error('Error parsing JSON response');
      }

      if (responseData.success) {
        alert(responseData.message);
        setEmployees(employees.filter((employee) => employee.employeeID !== id));
      } else {
        alert(responseData.error);
      }

    } catch (error) {
      console.error('An error occurred in the deletion of employee in EDMain:', error);
      alert('An error occurred. Please try again later.');
    }
  }

  return (
    <main className="main" id="main">
      <section className="section">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
              <h5 className="card-title">EMPLOYEES</h5>
              {selectedRP && (selectedRP.add == 1) && (
                <Link to="/edmain/addnewemployee" className="btn btn-secondary">
                  <i className="bi bi-plus-lg" /><span className='ms-1'>ADD</span>
                </Link>)}
            </div>
            {(selectedRP && (selectedRP.view == 1)) ?
              (<table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Status</th>
                    <th scope="col">Phone Number</th>
                    {/* to check if user has permission to edit or delete */}
                    {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) && (<th scope="col" className='text-center'>Action</th>)}
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr key={employee.employeeID}>
                      <th scope="row">{index + 1}</th>
                      <td>{employee.username}</td>
                      <td>{employee.email}</td>
                      <td>{employee.rName}</td>
                      <td>{employee.status == 1 ? 'Active' : 'Inactive'}</td>
                      <td>{employee.phoneNumber}</td>
                      {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) &&
                        (<td>
                          <div className="d-flex gap-2 justify-content-center">
                            {/* to check if user has permission to edit */}
                            {
                              (selectedRP && (selectedRP.edit == 1)) &&
                              !(employee.rName == 'Admin') &&
                              <Link to={`/edmain/editemployee/${employee.employeeID}`} className="btn btn-primary">
                                <i className="bi bi-pencil-fill" />
                              </Link>
                            }
                            {/* to check if user has permission to delete */}
                            {selectedRP && (selectedRP.delete == 1) &&
                              <a onClick={() => deleteEmployee(employee.employeeID)}
                                className="btn btn-danger">
                                <i className="bi bi-person-dash" />
                              </a>}
                          </div>
                        </td>)}
                    </tr>
                  ))}
                </tbody>
              </table>) : <p className='text-center'>No Access</p>
            }
          </div>
        </div>
      </section>
    </main>
  )
}
export default EDMain
