import React, { useEffect, useState } from 'react'
import DatePicker from './DatePicker'
import DoughnutChart from './DoughnutChart';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import usePunchInStore from '../../store/usePunchIn';
import ForwardTimer from '../ForwardTimer';

const AdminDashboard = () => {

    const [doughnutChartData, setDoughnutChartData] = useState();
    const navigate = useNavigate();
    const [leavesData, setLeavesData] = useState({
        todayLeaves: 0,
        weekLeaves: 0,
        monthLeaves: 0,
        upcomingWeekLeaves: 0,
        upcomingMonthLeaves: 0
    });

    const [preEmps, setPreEmps] = useState([]);
    const [absEmps, setAbsEmps] = useState([]);

    const [todayTasksModal, setTodayTasksModal] = useState(false);
    const ttModalClose = () => setTodayTasksModal(false);
    const ttModalShow = () => setTodayTasksModal(true);
    const [todayTasks, setTodayTasks] = useState([]);

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const fetchLeavesData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/dashboard/getLeavesData.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                setLeavesData(responseData.leaves);

                setPreEmps(responseData.workingEmps.filter(emp => emp.punch_in_time));
                setAbsEmps(responseData.workingEmps.filter(emp => !emp.punch_in_time));

                if (responseData.todaysTask && responseData.todaysTask.length > 0) {
                    setTodayTasks(responseData.todaysTask)
                    ttModalShow();
                }

                // responseData.totalSecondsToday, responseData.last_time_stamp
                if (responseData.last_time_stamp == null) {
                    // the user is punched out 
                    setAttendanceHours(responseData.totalSecondsToday);
                } else {
                    // get the current time stamp and calculate the difference
                    const currentTimeStamp = new Date();
                    const lastTimeStamp = new Date(responseData.last_time_stamp);
                    const timeDifference = currentTimeStamp - lastTimeStamp;

                    // i need time difference in seconds
                    const totalSeconds = Math.floor(timeDifference / 1000) + responseData.totalSecondsToday;
                    setAttendanceHours(totalSeconds);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
            // alert(error.message);
        }
    }

    const { punchIn, fetchAttendance, handleAttendance } = usePunchInStore();
    const [attendanceHours, setAttendanceHours] = useState(0);

    useEffect(() => {
        fetchLeavesData();
        fetchAttendance();
    }, []);

    // For Punch Out Modal
    const [punchOutModal, setPunchOutModal] = useState(false);
    const punchOutModalClose = () => setPunchOutModal(false);
    const punchOutModalShow = () => setPunchOutModal(true);
    const [punchOutReason, setPunchOutReason] = useState('');

    const handleAttendanceButton = () => {
        if (punchIn) {
            handleAttendance();
        } else {
            const now = new Date();
            const startHour = 9;
            const endHour = 18;
            const currentHour = now.getHours();

            if (currentHour >= startHour && currentHour < endHour) {
                punchOutModalShow();
            } else {
                handleAttendance();
            }
        }
    }

    const handlePunchOutModal = () => {
        handleAttendance(punchOutReason);
    }

    return (
        <main className="main" id="main">
            <section className="section">
                <div className="row gap-3 mb-4 overflow-auto justify-content-between pe-3">
                    <DatePicker setDoughnutChartData={setDoughnutChartData} />
                    <div className="col-auto" style={{ height: '465px' }}>
                        <div className="row justify-content-between">
                            <DoughnutChart data={doughnutChartData} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card revenue-card fancy p-2">
                            <div className="card-body">
                                <h5 className="card-title">ATTENDANCE</h5>
                                <hr className="text-primary-emphasis" />
                                <button className="w-100 btn mb-3 attendanceBtn"
                                    style={{ backgroundColor: '#4154F1', color: '#fff' }}
                                    onClick={handleAttendanceButton} >
                                    {punchIn ? 'Punch In' : 'Punch Out'}
                                </button>
                                <hr className="text-primary-emphasis mt-0" />
                                <div className="d-flex align-items-center gap-5 pb-2">
                                    <h6 className='text-primary-emphasis'><b className=''>Today:</b></h6>
                                    <h6 className='text-primary-emphasis ms-2'>
                                        <ForwardTimer seconds={attendanceHours} running={!punchIn} /> Hours
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="card revenue-card fancy p-2">
                            <div className="card-body mt-0 row align-items-end">
                                <div className="col-lg-6">
                                    <h5 className="card-title">LEAVES SUMMARY</h5>
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>Today's Leaves: <Link to={"/leave/all-leaves?duration=today"}>{leavesData.todayLeaves} employee(s)</Link></h6>
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>This Week's Leaves: <Link to={"/leave/all-leaves?duration=thisweek"}>{leavesData.weekLeaves} employee(s)</Link></h6>
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>This Month's Leaves:  <Link to={"/leave/all-leaves?duration=thismonth"}>{leavesData.monthLeaves} employee(s)</Link></h6>
                                </div>
                                <div className="col-lg-6">
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>Upcoming Week's Leaves:  <Link to={"/leave/all-leaves?duration=upcomingweek"}>{leavesData.upcomingWeekLeaves} employee(s)</Link></h6>
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>Upcoming Month's Leaves:  <Link to={"/leave/all-leaves?duration=upcomingmonth"}>{leavesData.upcomingMonthLeaves} employee(s)</Link></h6>
                                    <hr className="text-primary-emphasis mt-0" />
                                    <h6 className='text-primary-emphasis'>Pending Leaves:  <Link to={"/leave/all-leaves?status=Pending"}>{leavesData.pendingLeaves} employee(s)</Link></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Online Employees</h5>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Last Punched In</th>
                                            <th scope="col">Office Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {preEmps.map((emp, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{emp.username}</td>
                                                <td>{emp.punch_in_time}</td>
                                                <td>{emp.worked}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Offline Employees</h5>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Last Punched Out</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {absEmps.map((emp, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{emp.username}</td>
                                                <td>{emp.punch_out_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Punch Out Modal */}
            <Modal show={punchOutModal} onHide={punchOutModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Punch Out</Modal.Title>
                </Modal.Header>
                <form onSubmit={handlePunchOutModal}>
                    <Modal.Body>
                        {/* ('Break', 'Day Off', 'Meeting', 'Switch Project', 'Completed') */}
                        <label className="form-label" htmlFor="stopreason">Punch Out Reason</label>
                        <select className="form-select" name="stopreason" id="stopreason"
                            value={punchOutReason.stopreason}
                            onChange={e => setPunchOutReason(e.target.value)}>
                            <option hidden selected value="">Select Punch Out Reason</option>
                            <option value="Break">Break</option>
                            <option value="Day Off">Day Off</option>
                            <option value="Lunch Break">Lunch Break</option>
                            <option value="Going out for Personal Work">Going out for Personal Work</option>
                            <option value="Going out for Client Meeting">Going out for Client Meeting</option>
                            <option value="To attend Phone Call">To attend Phone Call</option>
                        </select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-primary' type="submit">
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={todayTasksModal} onHide={ttModalClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Today's Tasks List - {new Date().toLocaleDateString("en-GB")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* taskId */}
                                <th scope="col">#</th>
                                {/* title */}
                                <th scope="col">Title</th>
                                {/* Assigned by */}
                                <th scope="col">Assigned By</th>
                                {/* ttId, ttTitle */}
                                <th scope="col">Task Type</th>
                                {/* taskpriority */}
                                <th scope="col">Priority</th>
                                {/* time required */}
                                <th scope="col">Time Required /  Taken</th>
                                {/* status */}
                                <th scope="col">Task Status</th>
                                {/* Action */}
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todayTasks.map((task, index) => (
                                <tr key={task.taskId}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                    <td>{task.assignedbyname}</td>
                                    <td>{task.ttTitle}</td>
                                    <td>{task.taskpriority}</td>
                                    <td>{convertMinutesToHHMMSS(task.timerequired)} / {task.timetaken}</td>
                                    <td>{task.taskstatus}</td>
                                    <td>
                                        <Link target='_blank' className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}> <i className="bi bi-eye-fill" /> </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </main>

    )
}

export default AdminDashboard