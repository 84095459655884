import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useGetTaskDetails = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getTaskDetails = async (taskId) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/getTDetails.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                /*
                => responseData.taskDetails (An Object)
                ---------------------------------------
                {
                    "taskId": 3,
                    "title": "Tele Caller DB creation",
                    "description": "pls discuss the Tele Caller db table creation ",
                    "timerequired": 30,
                    "timetaken": "00:00:00",
                    "team_id": 1,
                    "tName": "PHP Development",
                    "createddate": "2024-05-01",
                    "assignedto": {
                        "employeeID": 2,
                        "username": "Rahil Khatri"
                    },
                    "assignedby": {
                        "employeeID": 47,
                        "username": "Nilesh Soni"
                    },
                    "doc1": null,
                    "doc2": null,
                    "doc3": null,
                    "taskstatus": "Not Attempted",
                    "approveddate": null,
                    "approvedby": {
                        "employeeID": null,
                        "username": ""
                    },
                    "pId": 11,
                    "pName": "MyStudy Offers",
                    "ttId": 3,
                    "ttTitle": "Development",
                    "status": 1,
                    "taskpriority": "Medium"
                }
                */
                return responseData.tDetails;
            } else {
                alert(responseData.error);
                navigate('/tasks/taskslist');
                return [];
            }
        } catch (error) {
            console.error('An error occurred in ViewTask: ', error);
            alert('An error occurred. Please try again later.');
            navigate('/tasks/taskslist');
            return [];
        } finally {
            setLoading(false);
        }
    }

    return { getTaskDetails, loading };
}