import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import useGetMembers from '../../hooks/useGetMembers';
import { Loader } from '../Loader';
import { LogsModal } from '../LogsModal';
import { useGetProjectsList } from '../../hooks/useGetProjectsList';
import useGetTaskTypes from '../../hooks/useGetTaskTypes';

export const IndividualReport = ({ rp }) => {

    let employeeID = JSON.parse(localStorage.getItem('edetails')).employeeID;
    const selectedRP = rp.find(role => role.name === 'Individual Report') || {};
    const [loading, setLoading] = useState(false);
    const [totalTimeSpent, setTotalTimeSpent] = useState('00:00:00');

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);
    const [reportForm, setReportForm] = useState({
        employeeID: employeeID,
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        pId: "",
        ttId: ""
    });

    const [projects, setProjects] = useState([]);
    const { getProjects } = useGetProjectsList();

    const [taskTypes, setTaskTypes] = useState([]);
    const { getTaskTypes } = useGetTaskTypes();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReportForm({ ...reportForm, [name]: value });
    }

    const handleSearch = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getIndividualReport.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.tasks === undefined) {
                    alert(responseData.message);
                    setTasks([]);
                    setTotalTimeSpent('00:00:00');
                } else {
                    /*
                    => responseData.tasks (An Array of Objects)
                    -------------------------------------------
                    */
                    setTasks(responseData.tasks);
                    setTotalTimeSpent(responseData.total_time_spent);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);

    function convertMinutesToHMS(minutes) {
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        var seconds = Math.floor((minutes * 60) % 60);

        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    function formatDateAndTime(data, str) {
        if (data == null || data == '' || data == undefined) {
            return str;
        }
        return new Date(data).toLocaleDateString("en-GB") + data.substr(10);
    }

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        (async () => setMembers((await getMembers()).filter(member => (member.status == 1))))();
        (async () => setProjects(await getProjects()))();
        (async () => setTaskTypes(await getTaskTypes()))();
    }, []);

    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form className='row m-4 justify-content-center align-items-center g-3' onSubmit={handleSearch}>
                                <div className="col-4">
                                    <label htmlFor="employeeID" className="form-label">
                                        Select Employee <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="employeeID"
                                            name="employeeID" value={reportForm.employeeID} onChange={handleChange}>
                                            <option hidden>Select Employee</option>
                                            {members.map((employee) =>
                                                <option key={employee.employeeID} value={employee.employeeID}>
                                                    {employee.username}
                                                </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="from_date" className="form-label">From <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" id="from_date"
                                        name="from_date" value={reportForm.from_date} onChange={handleChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="to_date" className="form-label">To <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" id="to_date"
                                        name="to_date" value={reportForm.to_date} onChange={handleChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="pId" className="form-label">Project</label>
                                    <select className="form-select" id="pId" name="pId" value={reportForm.pId} onChange={handleChange}>
                                        <option hidden value="">Select Project</option>
                                        {projects.map((p) => <option key={p.pId} value={p.pId}> {p.pName} </option>)}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="ttId" className="form-label">Task Type</label>
                                    <select className="form-select" id="ttId" name="ttId" value={reportForm.ttId} onChange={handleChange}>
                                        <option hidden value="">Select Task Type</option>
                                        {taskTypes.map((t) => <option key={t.ttId} value={t.ttId}> {t.ttTitle} </option>)}
                                    </select>
                                </div>
                                <div className="col-4 mt-auto">
                                    <button type="submit" className="w-100 btn btn-success">Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">TASKS</h5>
                                <p><b><i>Total Time Spent: {totalTimeSpent}</i></b></p>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" width="200px">Title</th>
                                                <th scope="col">Task Type</th>
                                                <th scope="col">Priority</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col">Started</th>
                                                <th scope="col">Last Accessed</th>
                                                <th scope="col" className='text-center'>Time Required</th>
                                                <th scope="col" className='text-center'>Time Spent</th>
                                                <th scope="col" className='text-center'>Logs</th>
                                                <th scope="col" className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tasks.map((task, index) => (
                                                <tr key={task.taskId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                    <td>{task.ttTitle}</td>
                                                    <td>{task.taskpriority}</td>
                                                    <td>{new Date(task.createddate).toLocaleDateString("en-GB")}</td>
                                                    <td>{formatDateAndTime(task.startedon, 'Not Started')}</td>
                                                    <td>{formatDateAndTime(task.lastaccesstime, 'Not Accessed')}</td>
                                                    <td className='text-center'>{convertMinutesToHMS(task.timerequired)}</td>
                                                    <td className='text-center'>{task.time_spent}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            onClick={() => {
                                                                setLogs(task.taskLogs);
                                                                setLogIdAndTitle({
                                                                    taskId: task.taskId,
                                                                    title: task.title,
                                                                    assignedtoname: task.assignedtoname
                                                                })
                                                            }}
                                                            className='btn btn-secondary'>
                                                            <i className="bi bi-justify" />
                                                        </button>
                                                    </td>
                                                    {/* Task Actions */}
                                                    <td className='text-center'>
                                                        <Link className="btn btn-info" target='_blank' to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                        {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} givenLogs={logs} />}
                    </div>
                </div>
            </section>
        </main>
    )
}
