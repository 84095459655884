import React, { useState, useEffect } from 'react';

const ForwardTimer = ({ seconds, running }) => {
    const [timeElapsed, setTimeElapsed] = useState(seconds);

    useEffect(() => {
        // Reset the timer if seconds prop changes
        setTimeElapsed(seconds);
    }, [seconds]);

    useEffect(() => {
        let timerId;

        if (running) {
            timerId = setInterval(() => {
                setTimeElapsed((prevTime) => prevTime + 1);
            }, 1000);
        }

        return () => clearInterval(timerId);
    }, [running]);

    const formatTime = (totalSeconds) => {
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (formatTime(timeElapsed));

};

export default ForwardTimer;
