import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetTaskDetails } from '../../hooks/useGetTaskDetails';


const ViewTask = () => {

    const location = useLocation();
    const previousUrl = location.state == null ? '/tasks/taskslist' : location.state;
    const [approvalButton, setApprovalButton] = useState(false);
    const navigate = useNavigate();

    const { getTaskDetails } = useGetTaskDetails();
    const taskId = useParams().taskId;
    const [tDetails, setTDetails] = useState({
        taskId: null,
        title: '',
        description: '',
        timerequired: null,
        timetaken: null,
        createddate: '',
        assignedto: { employeeID: null, username: '' },
        assignedby: { employeeID: null, username: '' },
        doc1: '',
        doc2: '',
        doc3: '',
        taskstatus: '',
        approveddate: null,
        approvedby: { employeeID: null, username: '' },
        pId: null,
        pName: '',
        ttId: null,
        ttTitle: '',
        status: null,
        taskpriority: '',
        tName: ''
    });


    useEffect(() => {
        getTaskDetails(taskId).then(tDetails => {
            if (tDetails.approvedby.employeeID != null) {
                setApprovalButton(false);
            } else if (tDetails.taskstatus === 'In Review by Senior') {
                setApprovalButton(true);
            }
            console.log(tDetails.deadline);

            setTDetails(tDetails)
        });
    }, []);

    const handleApproval = async () => {
        const formData = new FormData();
        formData.append('taskId', tDetails.taskId);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/approveTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }
            if (responseData.success) {
                console.log(tDetails.approveddate);
                alert(responseData.message);
                setTDetails({
                    ...tDetails,
                    approvedby: responseData.approvedby,
                    taskstatus: 'In Review by Client',
                    approveddate: new Date()
                });
                setApprovalButton(false);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the approval of task:', error);
        }
    }
    return (
        <main className="main" id='main'>
            <section className='section'>
                <div className="card">
                    <div className="card-body p-3">
                        <div className="row g-3">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h3 className='card-title'>{tDetails.title}</h3>
                                <div className='me-2'>
                                    {
                                        approvalButton && (tDetails.approvedby.employeeID == null) &&
                                        <button onClick={handleApproval} className="btn btn-success">
                                            Approve
                                        </button>
                                    }
                                    {
                                        (tDetails.approvedby.employeeID != null) && (approvalButton == false) &&
                                        <p className='card-text'><b> Approved By: </b>{tDetails.approvedby.username}  {`(${new Date(tDetails.approveddate).toLocaleDateString("en-GB")})`}  </p>
                                    }
                                </div>
                            </div>
                            <div className="col-6 mt-0">
                                <p className='card-title'>Description</p>
                                <p className='card-text'>{tDetails.description}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Active</p>
                                <p className='card-text'>{tDetails.status === 1 ? 'Yes' : 'No'}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Time Required</p>
                                <p className='card-text'>{tDetails.timerequired} mins</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Document 1</p>
                                {tDetails.doc1 === null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${tDetails.doc1}`}>view</a>
                                }
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Document 2</p>
                                {tDetails.doc2 === null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${tDetails.doc2}`}>view</a>}
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Document 3</p>
                                {tDetails.doc3 === null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${tDetails.doc3}`}>view</a>}
                            </div>
                            <div className="col-3">
                                <p className="card-title">Team Name</p>
                                <p className="card-text">

                                    {tDetails.tName == null ? "NA" : tDetails.tName}
                                </p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Priority</p>
                                <p className='card-text'>{tDetails.taskpriority}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Task Type</p>
                                <p className='card-text'>{tDetails.ttTitle}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Project Name</p>
                                <p className='card-text'>{tDetails.pName}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Task Status</p>
                                <p className='card-text'>{tDetails.taskstatus}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Created Date</p>
                                <p className="card-text">{
                                    (tDetails.createddate === "0000-00-00" || tDetails.createddate == null) ? "NA"
                                        : new Date(tDetails.createddate).toLocaleDateString("en-GB")
                                }</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Assigned To</p>
                                <p className="card-text">{tDetails.assignedto.username}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Assigned By</p>
                                <p className="card-text">{tDetails.assignedby.username}</p>
                            </div>
                            {/* timetaken */}
                            <div className="col-3">
                                <p className='card-title'>Time Taken</p>
                                <p className="card-text">{tDetails.timetaken}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Start Date</p>
                                <p className="card-text">{new Date(tDetails.startDate).toLocaleDateString("en-GB")}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Dealine</p>
                                <p className="card-text">{tDetails.deadline !== '0000-00-00' ? new Date(tDetails.deadline).toLocaleDateString("en-GB") : "NA"}</p>
                            </div>
                            {/* {tDetails.approvedby.username !== '' &&
                                <>
                                    <div className="col-6">
                                        <p className='card-title'>Approved By</p>
                                        <p className="card-text">{tDetails.approvedby.username}</p>
                                    </div>
                                    <div className="col-6">
                                        <p className='card-title'>Approved Date</p>
                                        <p className="card-text">{
                                            (tDetails.approveddate == null || tDetails.approveddate === "0000-00-00") ? "NA"
                                                : new Date(tDetails.startDate).toLocaleDateString("en-GB")
                                        }</p>
                                    </div>
                                </>
                            } */}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ViewTask
