import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useGetMembers from '../../hooks/useGetMembers';
import { Loader } from '../Loader';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Modal } from 'react-bootstrap';
import $ from "jquery";

export const AttendanceReport = ({ rp }) => {

    let employeeID = JSON.parse(localStorage.getItem('edetails')).employeeID;
    const selectedRP = rp.find(role => role.name === 'Attendance Report') || {};
    const [loading, setLoading] = useState(false);
    const [totalTimeSpent, setTotalTimeSpent] = useState('00:00:00');
    const [presentDays, setPresentDays] = useState(0);

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);
    const [reportForm, setReportForm] = useState({
        employeeID: employeeID,
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10)
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReportForm({ ...reportForm, [name]: value });
    }

    const handleSearch = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getAttendanceReport.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.attendances === undefined) {
                    alert(responseData.message);
                    setAttendances([]);
                    setTotalTimeSpent('00:00:00');
                } else {
                    setAttendances(responseData.attendances);
                    setTotalTimeSpent(responseData.total_time_spent);
                    setPresentDays(responseData.present_days);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // For Modal
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setLogForm({});
        setShowModal(false)
    };
    const handleShow = () => setShowModal(true);

    const [logForm, setLogForm] = useState({});
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        handleLogs();
    }, [logForm.in_time]);

    const handleLogs = async () => {

        if (!logForm.in_time) return;

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getLogs.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...logForm, employeeID: reportForm.employeeID })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                console.log(responseData);

                setLogs(responseData.logs);
                handleShow();
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const navigate = useNavigate();
    const [attendances, setAttendances] = useState([]);

    // function convertMinutesToHMS(minutes) {
    //     var hours = Math.floor(minutes / 60);
    //     var remainingMinutes = minutes % 60;
    //     var seconds = Math.floor((minutes * 60) % 60);

    //     return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // }

    // function formatDateAndTime(data, str) {
    //     if (data == null || data == '' || data == undefined) {
    //         return str;
    //     }
    //     return new Date(data).toLocaleDateString("en-GB") + data.substr(10);
    // }

    useEffect(() => {
        (async () => setMembers((await getMembers()).filter(member => (member.status == 1))))();
    }, []);

    const handleExport = () => {
        const data = attendances.map((attendance, index) => ({
            '#': index + 1,
            'Date': attendance.created_at,
            'In Time': attendance.in_time,
            'Out Time': attendance.out_time,
            'Office Time': attendance.worked,
            'Break': attendance.break
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Report");

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'attendance_report.xlsx');
    };

    let createIndex = 1;

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form className='row m-4 justify-content-center align-items-center g-3' onSubmit={handleSearch}>
                                <div className="col-lg-2">
                                    <label htmlFor="employeeID" className="form-label">
                                        Select Employee
                                    </label>
                                    <div>
                                        <select className="form-select" id="employeeID"
                                            name="employeeID" value={reportForm.employeeID} onChange={handleChange}>
                                            <option hidden>Select Employee</option>
                                            {members.map((employee) =>
                                                <option key={employee.employeeID} value={employee.employeeID}>
                                                    {employee.username}
                                                </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="from_date" className="form-label">From</label>
                                    <input type="date" className="form-control" id="from_date"
                                        name="from_date" value={reportForm.from_date} onChange={handleChange} />
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="to_date" className="form-label">To</label>
                                    <input type="date" className="form-control" id="to_date"
                                        name="to_date" value={reportForm.to_date} onChange={handleChange} />
                                </div>
                                <div className="col-lg-2 mt-auto">
                                    <div className='mt-3'>
                                        <button type="submit" className="w-100 btn btn-info">Search</button>
                                    </div>
                                </div>
                                <div className="col-lg-2 mt-auto">
                                    <div className='mt-3'>
                                        <button type="button" onClick={handleExport} className="w-100 btn btn-success">Export to Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">ATTENDANCE REPORT</h5>
                                <p><b><i>Present Days: {presentDays} / </i></b><b><i>Total Time: {totalTimeSpent}</i></b></p>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col" width="200px">Date</th>
                                            <th scope="col">In Time</th>
                                            <th scope="col">Out Time</th>
                                            <th scope="col">Office Time</th>
                                            <th scope='col'>Break</th>
                                            <th scope="col">Total Task Time</th>
                                            <th scope="col">Tasks Accessed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendances.map((attendance) => {
                                            if (attendance.manual_added == true) {
                                                return (
                                                    <tr className='table-secondary' key={attendance.id}>
                                                        <th scope="row"></th>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><b>{attendance.worked}</b></td>
                                                        <td><b>{attendance.break}</b></td>
                                                        <td><b>{attendance.time_spent}</b></td>
                                                        <td>
                                                            {attendance.time_spent ?
                                                                <button onClick={() => setLogForm({
                                                                    in_time: attendance.in_time,
                                                                    out_time: attendance.out_time,
                                                                })}
                                                                    className='btn btn-secondary'>
                                                                    <i className="bi bi-justify" />
                                                                </button> : null
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return (
                                                <tr key={attendance.id}>
                                                    <th scope="row">{attendance.created_at && createIndex++}</th>
                                                    <td>{attendance.created_at}</td>
                                                    <td>{attendance.in_time}</td>
                                                    <td>{attendance.out_time}</td>
                                                    <td>{attendance.worked}</td>
                                                    <td>{attendance.break}</td>
                                                    <td>{attendance.time_spent}</td>
                                                    <td>
                                                        {attendance.time_spent ?
                                                            <button onClick={() => setLogForm({
                                                                in_time: attendance.in_time,
                                                                out_time: attendance.out_time,
                                                            })}
                                                                className='btn btn-secondary'>
                                                                <i className="bi bi-justify" />
                                                            </button> : null
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                    </div>
                </div>
                <Modal show={showModal} onHide={handleClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{$('#employeeID').find(':selected').text()} <span style={{ fontSize: '16px' }}>{`(${logForm.in_time}-${logForm.out_time})`}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200px">Title</th>
                                    <th scope="col">Task Type</th>
                                    <th scope="col">Time Required</th>
                                    <th scope="col">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs && logs.map((log) => (
                                    <tr key={log.taskId}>
                                        <td>{log.title}</td>
                                        <td>{log.ttTitle}</td>
                                        <td>{convertMinutesToHHMMSS(log.timerequired)}</td>
                                        <td>{log.time_spent}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </section>
        </main>
    )
}
