import React from 'react';
import Roles from '../Roles';

export const EmployeeForm = ({ employeeDetails, handleSubmit, setEmployeeDetails, editMode }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeDetails({ ...employeeDetails, [name]: value });
        console.log(employeeDetails);
    }

    const handleRadioChange = (value) => {
        if (value === "option1") {
            setEmployeeDetails({ ...employeeDetails, status: true });
        } else if (value === "option2") {
            setEmployeeDetails({ ...employeeDetails, status: false });
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">
                        Username <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputText"
                            name="username" value={employeeDetails.username} onChange={handleChange} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                        Email <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                        <input type="email" className="form-control" id="inputEmail"
                            name="email" value={employeeDetails.email} onChange={handleChange} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
                        Password {!editMode && <span className="text-danger">*</span>}
                    </label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" id="inputPassword"
                            name="password" value={employeeDetails.password} onChange={handleChange} />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">
                        Role <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                        <select className="form-select" name="rId" value={employeeDetails.roleId} onChange={handleChange}>
                            <option hidden>Select Role</option>
                            <Roles roleId={employeeDetails.rId} editMode={editMode} />
                        </select>
                    </div>
                </div>
                {/* create div for Phone Number */}
                <div className="row mb-3">
                    <label htmlFor="inputNumber" className="col-sm-2 col-form-label">
                        Phone Number <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                        <input type="tel" className="form-control" id="inputNumber" pattern='[0-9]{10}'
                            name="phoneNumber" value={employeeDetails.phoneNumber} onChange={handleChange} />
                    </div>
                </div>
                <fieldset className="row mb-3">
                    <legend className="col-form-label col-sm-2 pt-0">Status</legend>
                    <div className="col-sm-10">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="gridRadios"
                                onChange={() => handleRadioChange("option1")}
                                id="gridRadios1" value="option1"
                                checked={employeeDetails.status} />
                            <label className="form-check-label" htmlFor="gridRadios1">
                                Active
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="gridRadios"
                                onChange={() => handleRadioChange("option2")}
                                id="gridRadios2" value="option2"
                                checked={!employeeDetails.status} />
                            <label className="form-check-label" htmlFor="gridRadios2">
                                Inactive
                            </label>
                        </div>
                    </div>
                </fieldset>
                <div className="text-center">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>
        </>
    )
}
