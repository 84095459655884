import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useGetProjectTypes = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getProjectTypes = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/projectType/getList.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                /*
                => responseData.projectTypes (An Array of Objects)
                --------------------------------------------------
                [
                    {
                        "pTypeId": "2",
                        "pName": "AMC"
                    }, {...}, {...}
                ]
                */
                return responseData.projectTypes;
            } else {
                alert(responseData.error);
                return [];
            }
        } catch (error) {
            console.error("An error occurred in fetching project types: ", error);
            alert('An error occurred. Please try again later.');
            return [];
        } finally {
            setLoading(false);
        }
    }
    return { getProjectTypes, loading };
}
export default useGetProjectTypes;