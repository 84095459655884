import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useGetTaskDetails } from '../../hooks/useGetTaskDetails';
import useTimeCounter from '../../store/useTimeCounter';
import { Loader } from '../Loader';
import { LogsModal } from '../LogsModal';
import $ from 'jquery';
import useGetMembers from '../../hooks/useGetMembers';

export const TasksList = ({ rp }) => {

    const { employeeID, rId } = JSON.parse(localStorage.getItem('edetails'));

    const selectedRP = rp.find(role => role.name === 'Tasks List') || {};
    const { getTaskDetails } = useGetTaskDetails();
    const { startTimer, stopTimer } = useTimeCounter();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [allTasks, setAllTasks] = useState([]);

    const [members, setMembers] = useState([]);
    const { getMembers } = useGetMembers();

    useEffect(() => {
        (async () => setMembers((await getMembers()).filter(member => (member.status == 1))))();
        const fetchTasks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/getTasksList.php`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    let message = "";
                    if (response.status === 401) {
                        // Handle unauthorized access
                        message = 'Unauthorized access. Please login again.';
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        message = 'Network response was not ok';
                    }
                    throw new Error(message);
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    if (responseData.tasks === undefined) {
                        alert(responseData.message);
                    } else {
                        /*
                        => responseData.tasks (An Array of Objects)
                        -------------------------------------------
                        [
                            {
                                "taskId": 1,
                                "title": "Task 1",
                                "pId": 1,
                                "pName": "Project A",
                                "ttId": 1,
                                "ttTitle": "Development",
                                "assignedto": 1,
                                "assignedtoname": "john_doe",
                                "assignedby": "jane_smith",
                                "taskstatus": "Not Attempted",
                                "taskpriority": "High",
                                "taskmode": null
                            }, {...}, {...}
                        ]
                        */
                        const newTasks = responseData.tasks.filter((task) => task.taskstatus !== "Completed");
                        setTasks(newTasks);
                        setAllTasks(newTasks);

                        $(function () {
                            const dataTable = $('#tasksTable').DataTable({
                                order: [
                                    [9, 'desc'],
                                    [0, 'asc']
                                ],
                            });

                            $('#membersFilter').on('change', function () {
                                const value = $(this).val();
                                dataTable.column(2).search(value).draw();
                            });
                        });

                    }
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('Error fetching projects:', error);
                alert('An error occurred. Please try again later.');
            }
        }
        fetchTasks();

        // Cleanup function to destroy the DataTable instance when the component unmounts
        return () => {
            if ($.fn.DataTable.isDataTable('#tasksTable')) {
                $('#tasksTable').DataTable().destroy(true);
            }
        };
    }, []);

    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    function convertDateString(dateString) {
        // Split the date string into day, month, and year parts
        const [day, month, year] = dateString.split('-');

        // Reformat and return the date in YYYY-MM-DD format
        return `${year}-${month}-${day}`;
    }

    const handleFilter = () => {
        if (minDate === '' || maxDate === '') {
            alert('Please enter both dates.');
            return;
        }

        if (!(new Date(minDate) <= new Date(maxDate))) {
            alert('Minimum date cannot be greater than maximum date.');
            return;
        }

        // destroy the DataTable instance
        if ($.fn.DataTable.isDataTable('#tasksTable')) {
            $('#tasksTable').DataTable().destroy();
        }

        // Assuming `tasks` is a filtered array based on minDate and maxDate
        const filteredTasks = allTasks.filter(task => {

            // Replace slashes with dashes
            const formattedDateString = task.startDate.split('/').join('-');

            // Convert the formatted date string to YYYY-MM-DD format
            const convertedDateString = convertDateString(formattedDateString);

            // Create a Date object from the converted date string
            const startDate = new Date(convertedDateString);


            return startDate >= new Date(minDate) && startDate <= new Date(maxDate);
        });
        setTasks(filteredTasks);

        $(function () {
            // reinitialize the DataTable instance
            $('#tasksTable').DataTable({
                order: [[9, 'desc']]
            });

            // Set up event handler for filtering members
            $('#membersFilter').off('change').on('change', function () {
                const value = $(this).val();
                const dataTable = $('#tasksTable').DataTable();
                dataTable.column(2).search(value).draw();
            });
        });
    };

    const handleDelete = async (taskId) => {
        if (!window.confirm("Are you sure you want to delete this task?")) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/deleteTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                const updatedTasks = tasks.filter(task => task.taskId !== taskId);
                setTasks(updatedTasks);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    const handleStart = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/startTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                if (responseData.didPunchIn) {
                    window.location.reload();
                    return;
                }
                startTimer(responseData.timetaken);
                const taskDetails = await getTaskDetails(taskId);
                const updatedTasks = tasks.map(task => {
                    if (task.taskId == taskId) {
                        return {
                            ...task,
                            title: taskDetails.title,
                            pName: taskDetails.pName,
                            ttTitle: taskDetails.ttTitle,
                            taskstatus: taskDetails.taskstatus,
                            taskpriority: taskDetails.taskpriority,
                            taskmode: "Start"
                        };
                    }
                    return task;
                });
                setTasks(updatedTasks);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error starting task:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    const [stopModal, setStopModal] = useState(false);
    const stopModalClose = () => setStopModal(false);
    const stopModalShow = () => setStopModal(true);
    const [idAndReason, setIdAndReason] = useState({
        taskId: '',
        stopreason: ''
    });

    const handleStop = async (e) => {
        e.preventDefault();
        if (idAndReason.stopreason === '') {
            alert('Please enter stop reason');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/stopTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(idAndReason)
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                stopTimer();
                let taskId = idAndReason.taskId;
                const taskDetails = await getTaskDetails(taskId);
                const updatedTasks = tasks.map(task => {
                    if (task.taskId == taskId) {
                        return {
                            ...task,
                            title: taskDetails.title,
                            pName: taskDetails.pName,
                            ttTitle: taskDetails.ttTitle,
                            taskstatus: taskDetails.taskstatus,
                            taskpriority: taskDetails.taskpriority,
                            taskmode: "Stop"
                        };
                    }
                    return task;
                });
                setTasks(updatedTasks);
                setIdAndReason({ taskId: '', stopreason: '' });
                stopModalClose();
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error stopping task:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className='row m-4 justify-content-center align-items-center' >
                                <div className="col-1"></div>
                                <div className="col-4">
                                    <label htmlFor="from_date" className="form-label">From</label>
                                    <input type="date" className="form-control" id="from_date" name="from_date" value={minDate} onChange={e => setMinDate(e.target.value)} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="to_date" className="form-label">To</label>
                                    <input type="date" className="form-control" id="to_date" name="to_date" value={maxDate} onChange={e => setMaxDate(e.target.value)} />
                                </div>
                                <div className="col-2 mt-auto">
                                    <button onClick={handleFilter} className="btn btn-success w-100">Search</button>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">TASKS LIST</h5>
                                <div className="row">
                                    {members.length > 1 &&
                                        <div className="col-auto">
                                            <select id="membersFilter" className="form-select">
                                                <option selected hidden disabled>Select Member</option>
                                                <option value="">All</option>
                                                {members.map((employee) =>
                                                    <option key={employee.employeeID} value={employee.employeeID}>
                                                        {employee.username}
                                                    </option>)}
                                            </select>
                                        </div>
                                    }
                                    {selectedRP && (selectedRP.add == 1) && (
                                        <div className="col-auto">
                                            <Link to="/tasks/addtask" className="btn btn-secondary">
                                                <i className="bi bi-plus-lg" /><span className='ms-1'>ADD</span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <table id="tasksTable" className="table w-100">
                                    <thead>
                                        <tr>
                                            {/* taskId */}
                                            <th scope="col">#</th>
                                            {/* title */}
                                            <th scope="col" width="200px">Title</th>
                                            {/* assignedtoname, assignedby */}
                                            <th scope="col">Assigned</th>
                                            {/* ttId, ttTitle */}
                                            <th scope="col">Task Type</th>
                                            {/* taskpriority */}
                                            <th scope="col">Priority</th>
                                            <th scope="col">Start Date</th>
                                            {/* status */}
                                            <th scope="col">Task Status</th>
                                            {/* time required */}
                                            <th scope="col">Time Required/<br />Taken</th>
                                            {(selectedRP.view == 1) && (<th scope="col" className='text-center'>Logs</th>)}
                                            <th scope="col" className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.map((task, index) => (
                                            <tr key={task.taskId}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                <td>
                                                    <span className='d-none'>{task.assignedto}</span>
                                                    <span className='fw-bold'>to: </span>{task.assignedtoname}
                                                    <br />
                                                    <span className="fw-bold">by: </span>{task.assignedby}
                                                </td>
                                                <td>{task.ttTitle}</td>
                                                <td>{task.taskpriority}</td>
                                                <td>{task.startDate}</td>
                                                <td>{task.taskstatus}</td>
                                                <td>{convertMinutesToHHMMSS(task.timerequired)} / {task.timetaken}</td>
                                                {/* Task Logs */}
                                                {(selectedRP.view == 1) &&
                                                    (<td className='text-center'>
                                                        <button onClick={() => setLogIdAndTitle({
                                                            taskId: task.taskId,
                                                            title: task.title,
                                                            assignedtoname: task.assignedtoname
                                                        })}
                                                            className='btn btn-secondary'>
                                                            <i className="bi bi-justify" />
                                                        </button>
                                                    </td>)}
                                                {/* Task Actions */}
                                                <td>
                                                    <div className='d-flex gap-2 justify-content-center'>
                                                        {(selectedRP.view == 1) &&
                                                            (<Link className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}>
                                                                <i className="bi bi-eye-fill" />
                                                            </Link>)}
                                                        {(selectedRP.edit == 1) &&
                                                            (<Link className="btn btn-primary" to={`/tasks/edittask/${task.taskId}`}>
                                                                <i className="bi bi-pencil-fill" />
                                                            </Link>)}
                                                        {(selectedRP.delete == 1) &&
                                                            (<button className="btn btn-danger" onClick={() => handleDelete(task.taskId)}>
                                                                <i className="bi bi-trash3-fill" />
                                                            </button>)}
                                                        {(task.assignedto == employeeID) && (task.taskmode == "Stop" || task.taskmode == null) && (task.taskstatus === 'Not Attempted' || task.taskstatus === 'Pending') &&
                                                            (<button className="btn btn-warning" onClick={() => handleStart(task.taskId)}>
                                                                Start
                                                            </button>)}
                                                        {((task.assignedto == employeeID) || (rId == 1)) && (task.taskmode == "Start") && (task.taskstatus === 'In Progress') &&
                                                            (<button className="btn btn-danger"
                                                                onClick={() => {
                                                                    stopModalShow();
                                                                    setIdAndReason({ taskId: task.taskId, stopreason: '' });
                                                                }}>
                                                                Stop
                                                            </button>)}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                        {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} />}
                        <Modal show={stopModal} onHide={stopModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Stop Task</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleStop}>
                                <Modal.Body>
                                    {/* ('Break', 'Day Off', 'Meeting', 'Switch Project', 'Completed') */}
                                    <label className="form-label" htmlFor="stopreason"> Stop Reason </label>
                                    <select className="form-select" name="stopreason" id="stopreason"
                                        value={idAndReason.stopreason}
                                        onChange={e => setIdAndReason({ ...idAndReason, stopreason: e.target.value })}>
                                        <option hidden>Select Stop Reason</option>
                                        <option value="Break">Break</option>
                                        <option value="Day Off">Day Off</option>
                                        <option value="Meeting">Meeting</option>
                                        <option value="Switch Project">Switch Project</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-primary' type="submit">
                                        Submit
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </div>
                </div>
            </section>
        </main>
    )
}
