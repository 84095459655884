import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useGetTaskDetails } from '../../hooks/useGetTaskDetails';
import useGetTaskTypes from '../../hooks/useGetTaskTypes';
import { useGetProjectsList } from '../../hooks/useGetProjectsList';
import useGetMembers from '../../hooks/useGetMembers';
import useGetTeams from '../../hooks/useGetTeams';

const EditTask = () => {

    const location = useLocation();
    const previousUrl = location.state == null ? '/tasks/taskslist' : location.state;

    const navigate = useNavigate();
    const doc1Ref = useRef(null);
    const doc2Ref = useRef(null);
    const doc3Ref = useRef(null);
    const [approvalButton, setApprovalButton] = useState(false);

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);

    const { getTaskTypes } = useGetTaskTypes();
    const [taskTypes, setTaskTypes] = useState([]);

    const { getProjects } = useGetProjectsList();
    const [projects, setProjects] = useState([]);

    const { getTeams } = useGetTeams();
    const [teams, setTeams] = useState([]);

    const { getTaskDetails } = useGetTaskDetails();
    const taskId = useParams().taskId;
    const [task, setTask] = useState({
        taskId: null,
        title: '',
        description: '',
        timerequired: '',
        timetaken: null,
        createddate: '',
        assignedto: { employeeID: null, username: '' },
        assignedby: { employeeID: null, username: '' },
        doc1: null,
        doc2: null,
        doc3: null,
        taskstatus: '',
        approveddate: null,
        approvedby: { employeeID: null, username: '' },
        pId: '',
        pName: '',
        ttId: '',
        ttTitle: '',
        status: '',
        taskpriority: '',
        assignedTo: '',
        startDate: '',
        deadline: new Date().toISOString().slice(0, 10)
    });

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            setTask({ ...task, [name]: e.target.files[0] });
        } else {
            setTask({ ...task, [name]: value });
        }
    }

    useEffect(() => {
        (async () => setMembers(await getMembers()))();
        (async () => setTaskTypes(await getTaskTypes()))();
        (async () => setProjects(await getProjects()))();
        getTaskDetails(taskId).then(tDetails => {
            if (tDetails.approvedby.employeeID != null) {
                setApprovalButton(false);
            } else if (tDetails.taskstatus === 'In Review by Senior') {
                setApprovalButton(true);
            }
            setTask(
                {
                    ...tDetails,
                    ttId: tDetails.ttId?.toString(),
                    timerequired: tDetails.timerequired?.toString(),
                    assignedTo: tDetails.assignedto?.employeeID?.toString(),
                    pId: tDetails.pId?.toString(),
                    status: tDetails.status?.toString(),
                    team_id: tDetails.team_id == null ? '' : tDetails.team_id.toString(),
                    startDate: tDetails.startDate == null ? '' : tDetails.startDate,
                    deadline: tDetails.deadline == null ? '' : tDetails.deadline
                }
            )
        }
        );
        (async () => setTeams(await getTeams()))();
    }, []);

    const handleDeleteDoc = async (colName, docName) => {
        if (!window.confirm("Are you sure you want to delete this Document?")) {
            return;
        }
        const formData = new FormData();

        formData.append('docName', docName);
        formData.append('colName', colName);
        formData.append('taskId', task.taskId);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/deleteDoc.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setTask({ ...task, [colName]: null });
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error('An error occurred in deleting document:', error);
            alert('An error occurred. Please try again later.');
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // validation
        if (!task.title || !task.taskpriority || !task.ttId || !task.description || !task.timerequired || !task.assignedTo || !task.pId || !task.team_id || !task.startDate) {
            alert('Please fill all the required fields');
            return;
        }
        const formData = new FormData();
        Object.keys(task).forEach(key => {
            formData.append(key, task[key]);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/editTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                // it is not necessary
                navigate(previousUrl);
                alert(responseData.message);
                // getTaskDetails(taskId).then(tDetails => setTask(
                //     {
                //         ...tDetails,
                //         ttId: tDetails.ttId?.toString(),
                //         timerequired: tDetails.timerequired?.toString(),
                //         assignedTo: tDetails.assignedto?.employeeID?.toString(),
                //         pId: tDetails.pId?.toString(),
                //         status: tDetails.status?.toString(),
                //     }
                // ));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the edit of task:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    const handleApproval = async () => {
        const formData = new FormData();
        formData.append('taskId', task.taskId);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/approveTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }
            if (responseData.success) {
                alert(responseData.message);
                setTask({
                    ...task,
                    approvedby: responseData.approvedby,
                    taskstatus: 'In Review by Client',
                    approveddate: new Date()
                });
                setApprovalButton(false);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the approval of task:', error);
            alert('An error occurred. Please try again later.');
        }
    }
    return (
        <main className="mian" id="main">
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className="card-title">Edit Task</h5>
                                {
                                    approvalButton && (task.approvedby.employeeID == null) &&
                                    <button onClick={handleApproval} className="btn btn-success">
                                        Approve
                                    </button>
                                }
                                {
                                    (task.approvedby.employeeID != null) && (approvalButton == false) &&
                                    <p className='card-text'> Approved By: {task.approvedby.username} {`(${new Date(task.approveddate).toLocaleDateString("en-GB")})`}</p>
                                }
                            </div>
                            <form className="row g-3" onSubmit={handleSubmit}>
                                <div className="col-9">
                                    <label htmlFor="title" className="form-label">
                                        Title <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="title"
                                        name="title" value={task.title} onChange={handleChange} />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="taskpriority" className="form-label">
                                        Task Priority <span className="text-danger">*</span>
                                    </label>
                                    <select className="form-select"
                                        name="taskpriority" value={task.taskpriority} onChange={handleChange}>
                                        <option hidden>Select Priority</option>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                        <option value="Critical">Critical</option>
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="description" className="form-label">
                                        Description <span className="text-danger">*</span>
                                    </label>
                                    <textarea className="form-control" id="description"
                                        name="description" value={task.description} onChange={handleChange}>
                                    </textarea>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select className="form-select" name="status" value={task.status} onChange={handleChange}>
                                        <option hidden>Select Status</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="taskType" className="form-label">
                                        Task Type <span className="text-danger">*</span>
                                    </label>
                                    <select className="form-select" name="ttId" value={task.ttId} onChange={handleChange} >
                                        <option hidden>Select Task Type</option>
                                        {taskTypes.map((taskType) => <option key={taskType.ttId} value={taskType.ttId}>{taskType.ttTitle}</option>)}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="taskstatus" className="form-label">Task Status</label>
                                    <select className="form-select" name="taskstatus" value={task.taskstatus} onChange={handleChange}>
                                        <option hidden>Select Status of Task</option>
                                        <option value="Not Attempted">Not Attempted</option>
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Deployed">Deployed</option>
                                        <option value="Completed">Completed</option>
                                        <option value="In Review by Client">In Review by Client</option>
                                        <option value="In Review by Senior">In Review by Senior</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="timerequired" className="form-label">
                                        Time Required {"(in mins)"} <span className="text-danger">*</span>
                                    </label>
                                    <input type="number" step={"0.01"} className="form-control" id="timerequired"
                                        name="timerequired" value={task.timerequired} onChange={handleChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="assignedTo" className="form-label">
                                        Assign to <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="assignedTo"
                                            name="assignedTo" value={task.assignedTo} onChange={handleChange}>
                                            <option hidden>Select Member</option>
                                            {members.map((employee) => <option key={employee.employeeID} value={employee.employeeID}>{employee.username}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="projects" className="form-label">
                                        Project <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="projects"
                                            name="pId" value={task.pId} onChange={handleChange}>
                                            <option hidden>Select Project</option>
                                            {projects.map((project) => <option key={project.pId} value={project.pId}>{project.pName}</option>)}

                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="teams" className="form-label">
                                        Team <span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <select className="form-select" id="teams" required
                                            name="team_id" value={task.team_id} onChange={handleChange}>
                                            <option hidden>Select Team</option>
                                            {teams.map((team) => <option key={team.tId} value={team.tId}>{team.tName}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="startDate" className="form-label">Start Date <span className="text-danger">*</span></label>
                                    <input type="date" className="form-control" id="startDate"
                                        name="startDate" value={task.startDate} onChange={handleChange} required />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="deadline" className="form-label">Deadline</label>
                                    <input type="date" className="form-control" id="deadline"
                                        name="deadline" value={task.deadline} onChange={handleChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc1" className="form-label">Document 1</label>
                                    {
                                        (typeof task.doc1 === "string" ?
                                            (
                                                <>
                                                    <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${task.doc1}`}>view</a>
                                                    <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc1", task.doc1)}>Delete</a>
                                                </>
                                            ) :
                                            <input type="file" className="form-control" id="doc1" name="doc1" ref={doc1Ref} onChange={handleChange} />)
                                    }
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc2" className="form-label">Document 2</label>
                                    {
                                        (typeof task.doc2 === "string" ?
                                            (
                                                <>
                                                    <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${task.doc2}`}>view</a>
                                                    <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc2", task.doc2)}>Delete</a>
                                                </>
                                            ) :
                                            <input type="file" className="form-control" id="doc2" name="doc2" ref={doc2Ref} onChange={handleChange} />)
                                    }
                                </div>
                                <div className="col-4">
                                    <label htmlFor="doc3" className="form-label">Document 3</label>
                                    {
                                        (typeof task.doc3 === "string" ?
                                            (
                                                <>
                                                    <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${task.doc3}`}>view</a>
                                                    <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc3", task.doc3)}>Delete</a>
                                                </>
                                            ) :
                                            <input type="file" className="form-control" id="doc3" name="doc3" ref={doc3Ref} onChange={handleChange} />)
                                    }
                                </div>

                                {/* <div className="col-6">
                                    <label htmlFor="assignedby" className="form-label">Assign By</label>
                                    <input type="text" className="form-control" id="assignedby" name="assignedby" />
                                </div> */}
                                {/* <div className="col-6">
                                    <label htmlFor="approvedby" className="form-label">Approved By</label>
                                    <input type="text" className="form-control" id="approvedby" name="approvedby" />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="approveddate" className="form-label">Approved Date</label>
                                    <input type="date" className="form-control" id="approveddate" name="approveddate" />
                                </div> */}
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <Link to={previousUrl} className="btn btn-danger ms-2">Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default EditTask
