import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '../Loader';
import { LogsModal } from '../LogsModal';
import $ from 'jquery';

export const ProjectDetailReport = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Project Report') || {};
    const [loading, setLoading] = useState(false);
    const [totalTimeSpent, setTotalTimeSpent] = useState('00:00:00');
    const { rId } = JSON.parse(localStorage.getItem('edetails'));


    // Get the current location object
    const location = useLocation();

    // Use URLSearchParams to parse the query string
    const queryParams = new URLSearchParams(location.search);

    const reportForm = {
        from_date: queryParams.get('from_date'),
        to_date: queryParams.get('to_date'),
        pId: queryParams.get('pId'),
        ttId: queryParams.get('ttId') == "all" ? "" : queryParams.get('ttId'),
        pName: queryParams.get('pName')
    };

    const [devs, setDevs] = useState([]);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getProjectDetailReport.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.tasks === undefined) {
                    alert(responseData.message);
                    setTasks([]);
                    setTotalTimeSpent('00:00:00');
                } else {
                    /*
                    => responseData.tasks (An Array of Objects)
                    -------------------------------------------
                    */
                    setTasks(responseData.tasks);
                    setDevs(responseData.devs);
                    setTotalTimeSpent(responseData.total_time_spent);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {
        if (tasks.length > 0) {
            const dataTable = $('#tasks').DataTable({
                searching: true,
            });

            $('#devsFilter').on('change', function () {
                const value = $(this).val();
                dataTable.column(7).search(value).draw();
            });

            return () => {
                if ($.fn.DataTable.isDataTable('#tasks')) {
                    $('#tasks').DataTable().destroy(true);
                }
            };
        }
    }, [tasks]);

    const navigate = useNavigate();

    function convertMinutesToHMS(minutes) {
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        var seconds = Math.floor((minutes * 60) % 60);

        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    function formatDateAndTime(data, str) {
        if (data == null || data == '' || data == undefined) {
            return str;
        }
        return new Date(data).toLocaleDateString("en-GB") + data.substr(10);
    }

    const [logs, setLogs] = useState([]);

    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row mb-3">
                    <div className="card">
                        <div className="card-body d-flex flex-wrap justify-content-evenly mt-4">
                            <div className="col-auto">
                                <span className="card-title h6">Project Name: </span>
                                <span className="card-text p" style={{ fontSize: '18px' }}>{reportForm.pName}</span>
                            </div>
                            <div className="col-auto">
                                <span className="card-title">From Date: </span>
                                <span className="card-text p" style={{ fontSize: '18px' }}>{new Date(reportForm.from_date).toLocaleDateString("en-GB")}</span>
                            </div>
                            <div className="col-auto">
                                <span className="card-title">To Date: </span>
                                <span className="card-text p" style={{ fontSize: '18px' }}>{new Date(reportForm.to_date).toLocaleDateString("en-GB")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">TASKS</h5>
                                <div className="row justify-content-end">
                                    {(rId == 1) && (
                                        <div className="col-auto">
                                            <select id="devsFilter" className="form-select">
                                                <option value={''}>Select Employee</option>
                                                {devs.map(type => (
                                                    <option key={type.assignedto} value={type.assignedtoname}>{type.assignedtoname}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <div>
                                    <table className="table w-100" id="tasks">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" width="200px">Title</th>
                                                <th scope="col">Task Type</th>
                                                <th scope="col">Priority</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col">Started</th>
                                                <th scope="col">Last Accessed</th>
                                                <th scope="col">Employee Name</th>
                                                <th scope="col" className='text-center'>Time Required</th>
                                                <th scope="col" className='text-center'>Time Spent</th>
                                                <th scope="col" className='text-center'>Logs</th>
                                                <th scope="col" className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tasks.map((task, index) => (
                                                <tr key={task.taskId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                    <td>{task.ttTitle}</td>
                                                    <td>{task.taskpriority}</td>
                                                    <td>{new Date(task.createddate).toLocaleDateString("en-GB")}</td>
                                                    <td>{formatDateAndTime(task.startedon, 'Not Started')}</td>
                                                    <td>{formatDateAndTime(task.lastaccesstime, 'Not Accessed')}</td>
                                                    <td>{task.assignedtoname}</td>
                                                    <td className='text-center'>{convertMinutesToHMS(task.timerequired)}</td>
                                                    <td className='text-center'>{task.time_spent}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            onClick={() => {
                                                                setLogs(task.taskLogs);
                                                                setLogIdAndTitle({
                                                                    taskId: task.taskId,
                                                                    title: task.title,
                                                                    assignedtoname: task.assignedtoname
                                                                })
                                                            }}
                                                            className='btn btn-secondary'>
                                                            <i className="bi bi-justify" />
                                                        </button>
                                                    </td>
                                                    {/* Task Actions */}
                                                    <td className='text-center'>
                                                        <Link className="btn btn-info" target='_blank' to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                        {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} givenLogs={logs} />}
                    </div>
                </div>
            </section>
        </main>
    )
}
