import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useGetEmployeesList = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getEmployees = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/employeeDetails/getEmployeesList.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                /*
                => responseData.employees (An Array of Objects)
                -----------------------------------------------
                [
                  {
                      "username": "Sapan",
                      "email": "sapansidhwani4@gmail.com",
                      "password": "0a6f9ebaa55e21ce270b6df2e7d812c987d511ab0472d24b501622b5878f9e4b03011356f3c9f85b084cf763a995a93f142d5107fa9a92d8e60e78d3c96a614a",
                      "status": "0",
                      "phoneNumber": "6353713153",
                      "employeeID": "13",
                      "rId": "2",
                      "rName": "Junior PHP Developer"
                  }, {...}, {...}
                ]
                */
                return responseData.employees;
            } else {
                alert(responseData.error);
                return [];
            }
        } catch (error) {
            console.error('An error occurred in fetching employees: ', error);
            alert('An error occurred. Please try again later.');
            return [];
        } finally {
            setLoading(false);
        }
    }
    return { getEmployees, loading };
}
