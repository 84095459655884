import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const ViewDetails = () => {

    const navigate = useNavigate();
    const pId = useParams().pId;
    const [pDetails, setPDetails] = useState([]);
    const [assignees, setAssignees] = useState([]);

    useEffect(() => {
        const formData = new FormData();
        formData.append('pId', pId);

        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/getPDetails.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: formData
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                if (responseData.success) {
                    /*
                    => responseData.pDetails (An Object) 
                    ------------------------------------
                    {
                        "pId": 2,
                        "pName": "Project B",
                        "description": "Description of Project B",
                        "active": 1,
                        "startDate": "2024-02-01",
                        "endDate": null,
                        "createdDate": "2024-02-01",
                        "completedDate": null,
                        "pTypeId": 2,
                        "pTypeName": "AMC",
                        "status": "In Progress",
                        "doc1": null,
                        "doc2": null,
                        "doc3": null,
                        "estimatedHours": 15
                    }
                    
                    => responseData.assignees (An Array of Objects)
                    ------------------------------------------------
                    [
                        {
                            "employeeID": 13,
                            "username": "Sapan"
                        }, {...}, {...}
                    ]
                    */
                    setPDetails(responseData.pDetails);
                    setAssignees(responseData.assignees);
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('Error fetching project details:', error);
                alert('An error occurred. Please try again later.');
            }
        }
        fetchData();
    }, []);
    return (
        <main className="main" id='main'>
            <section className='section'>
                <div className="card">
                    <div className="card-body p-3">
                        <div className="row g-3">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h3 className='card-title'>{pDetails.pName}</h3>
                                <Link to={"/projects/projectslist"} className='btn btn-danger'>
                                    <i className="bi bi-arrow-left"></i>
                                </Link>
                            </div>
                            <div className="col-6 mt-0">
                                <p className='card-title'>Description</p>
                                <p className='card-text'>{pDetails.description}</p>
                            </div>
                            <div className="col-6 mt-0">
                                <p className='card-title'>Assignees</p>
                                <p className='card-text'>{assignees.map(a => a.username).join(", ")}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Active</p>
                                <p className='card-text'>{pDetails.active == 1 ? 'Yes' : 'No'}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Project Type</p>
                                <p className='card-text'>{pDetails.pTypeName}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Status</p>
                                <p className='card-text'>{pDetails.status}</p>
                            </div>
                            <div className="col-3">
                                <p className='card-title'>Estimated Hours</p>
                                <p className='card-text'>{pDetails.estimatedHours}</p>
                            </div>
                            <div className="col-4">
                                <p className='card-title'>Document 1</p>
                                {pDetails.doc1 == null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc1}`}>view</a>
                                }
                            </div>
                            <div className="col-4">
                                <p className='card-title'>Document 2</p>
                                {pDetails.doc2 == null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc2}`}>view</a>}
                            </div>
                            <div className="col-4">
                                <p className='card-title'>Document 3</p>
                                {pDetails.doc3 == null ?
                                    <p className='card-text'>NA</p> :
                                    <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc3}`}>view</a>}
                            </div>
                            <div className="col-6">
                                <p className='card-title'>Start Date</p>
                                <p className='card-text'>{
                                    (pDetails.startDate === '0000-00-00' || pDetails.startDate == null) ? "NA"
                                        : new Date(pDetails.startDate).toLocaleDateString("en-GB")
                                }</p>
                            </div>
                            <div className="col-6">
                                <p className='card-title'>End Date</p>
                                <p className="card-text">{
                                    (pDetails.endDate === '0000-00-00' || pDetails.endDate == null) ? "NA"
                                        : new Date(pDetails.endDate).toLocaleDateString("en-GB")
                                }</p>
                            </div>
                            <div className="col-6">
                                <p className='card-title'>Created Date</p>
                                <p className="card-text">{
                                    (pDetails.createdDate === '0000-00-00' || pDetails.createdDate == null) ? "NA"
                                        : new Date(pDetails.createdDate).toLocaleDateString("en-GB")
                                }</p>
                            </div>
                            <div className="col-6">
                                <p className='card-title'>Completed Date</p>
                                <p className="card-text">{
                                    (pDetails.completedDate === '0000-00-00' || pDetails.completedDate == null) ? "NA"
                                        : new Date(pDetails.completedDate).toLocaleDateString("en-GB")
                                }</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
