// import { useState } from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import usePunchInStore from '../store/usePunchIn';
import { Modal } from 'react-bootstrap';

export const Sidebar = ({ rp }) => {

    // to get the current location of the page
    const location = useLocation();
    // const [isPunchedIn, setIsPunchedIn] = useState(true);

    const check = (name) => {
        if (!Array.isArray(rp) || rp.length === 0) return false; // Check if rp is an array and has elements
        const role = rp.find(role => role.name === name); // Find role by name
        if (!role) return false; // If role is not found, return false
        // Check role permissions
        return role.view == 1;
    };

    const { punchIn, fetchAttendance, handleAttendance } = usePunchInStore();

    useEffect(() => {
        fetchAttendance();
    }, []);

    // For Punch Out Modal
    const [punchOutModal, setPunchOutModal] = useState(false);
    const punchOutModalClose = () => setPunchOutModal(false);
    const punchOutModalShow = () => setPunchOutModal(true);
    const [punchOutReason, setPunchOutReason] = useState('');

    const handleAttendanceButton = () => {
        if (punchIn) {
            handleAttendance();
        } else {
            const now = new Date();
            const startHour = 9;
            const endHour = 18;
            const currentHour = now.getHours();

            if (currentHour >= startHour && currentHour < endHour) {
                punchOutModalShow();
            } else {
                handleAttendance();
            }
        }
    }

    const handlePunchOutModal = () => {
        handleAttendance(punchOutReason);
    }

    return (
        <aside id="sidebar" className="sidebar">
            <div className='container-fluid'>
                <button className="w-100 btn mb-3 attendanceBtn"
                    style={{ backgroundColor: '#4154F1', color: '#fff' }}
                    onClick={handleAttendanceButton} >
                    {punchIn ? 'Punch In' : 'Punch Out'}
                </button>
            </div>
            <ul className="sidebar-nav" id="sidebar-nav">
                {
                    // check('Dashboard') &&
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/' ? "" : "collapsed"}`} to="/">
                            <i className="bi bi-speedometer" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                }
                {
                    check('Employee Details') &&
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname.startsWith('/edmain') ? "" : "collapsed"}`} to="/edmain">
                            <i className="bi bi-person-badge" />
                            <span>Employee Details</span>
                        </Link>
                    </li>
                }
                {
                    check('User Roles') &&
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/userroles' ? "" : "collapsed"}`} to="/userroles">
                            <i className="bi bi-person-gear" />
                            <span>User Roles</span>
                        </Link>
                    </li>
                }
                {
                    check('Projects') &&

                    <li className="nav-item">
                        <a className={`nav-link ${location.pathname.startsWith('/projects') ? "" : "collapsed"}`}
                            data-bs-target="#components-projects" data-bs-toggle="collapse" href="#">
                            <i className="bi bi-file-earmark-zip-fill" />
                            <span>Projects</span>
                            <i className="bi bi-chevron-down ms-auto" />
                        </a>
                        <ul id="components-projects"
                            className={`nav-content collapse ${location.pathname.startsWith('/projects') ? "show" : ""}`}
                            data-bs-parent="#sidebar-nav">
                            {
                                check('Projects List') &&
                                <li>
                                    <Link to="/projects/projectslist"
                                        className={`${location.pathname.startsWith("/projects/projectslist") ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Projects List</span>
                                    </Link>
                                </li>
                            }
                            {
                                check('Project Type') &&
                                <li>
                                    <Link to="/projects/project-type" className={`${location.pathname === "/projects/project-type" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Project Type</span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </li>
                }
                {
                    check('Tasks') &&
                    <li className="nav-item">
                        <a className={`nav-link ${location.pathname.startsWith('/tasks') ? "" : "collapsed"}`}
                            data-bs-target="#components-tasks" data-bs-toggle="collapse" href="#">
                            <i className="bi bi-menu-button-wide" />
                            <span>Tasks</span>
                            <i className="bi bi-chevron-down ms-auto" />
                        </a>
                        <ul id="components-tasks" className={`nav-content collapse ${location.pathname.startsWith('/tasks') ? "show" : ""}`} data-bs-parent="#sidebar-nav">
                            {
                                check('Tasks List') &&
                                <li>
                                    <Link to="/tasks/taskslist" className={`${(
                                        location.pathname === "/tasks/taskslist" ||
                                        location.pathname === "/tasks/addtask" ||
                                        location.pathname.startsWith('/tasks/viewtask') ||
                                        location.pathname.startsWith('/tasks/edittask')
                                    ) ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Tasks List</span>
                                    </Link>
                                </li>
                            }
                            {
                                check("Priority Tasks") &&
                                <li>
                                    <Link to="/tasks/priority-tasks" className={`${location.pathname === "/tasks/priority-tasks" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Priority Tasks</span>
                                    </Link>
                                </li>
                            }
                            {
                                check('Completed Tasks') &&
                                <li>
                                    <Link to="/tasks/completed-tasks"
                                        className={`${location.pathname === "/tasks/completed-tasks" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Completed Tasks</span>
                                    </Link>
                                </li>
                            }
                            {
                                check('Task Type') &&
                                <li>
                                    <Link to="/tasks/task-type" className={`${location.pathname === "/tasks/task-type" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Task Type</span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </li>
                }
                {
                    check('Teams') &&
                    <li className="nav-item">
                        <Link className={`nav-link ${location.pathname === '/teams' ? "" : "collapsed"}`} to="/teams">
                            <i className="bi bi-microsoft-teams" />
                            <span>Teams</span>
                        </Link>
                    </li>
                }
                {
                    check('Report') &&
                    <li className="nav-item">
                        <a className={`nav-link ${location.pathname.startsWith('/report') ? "" : "collapsed"}`}
                            data-bs-target="#components-report" data-bs-toggle="collapse" href="#">
                            <i className="bi bi-menu-button-wide" />
                            <span>Report</span>
                            <i className="bi bi-chevron-down ms-auto" />
                        </a>
                        <ul id="components-report" className={`nav-content collapse ${location.pathname.startsWith('/report') ? "show" : ""}`} data-bs-parent="#sidebar-nav">
                            {
                                check("Basic Report") &&
                                <li>
                                    <Link to="/report/basic-report" className={`${location.pathname === "/report/basic-report" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Basic Report</span>
                                    </Link>
                                </li>
                            }
                            {
                                check("Individual Report") &&
                                <li>
                                    <Link to="/report/individual-report" className={`${location.pathname === "/report/individual-report" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Individual Report</span>
                                    </Link>
                                </li>
                            }
                            {
                                check("Attendance Report") &&
                                <li>
                                    <Link to="/report/attendance-report" className={`${location.pathname === "/report/attendance-report" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Attendance Report</span>
                                    </Link>
                                </li>
                            }
                            {
                                check("Project Report") &&
                                <li>
                                    <Link to="/report/project-report" className={`
                                        ${(
                                            location.pathname === "/report/project-report" ||
                                            location.pathname === "/report/project-detail-report"
                                        ) ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Project Report</span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </li>
                }
                {
                    check('Leave') &&
                    <li className="nav-item">
                        <a className={`nav-link ${location.pathname.startsWith('/leave') ? "" : "collapsed"}`}
                            data-bs-target="#components-leave" data-bs-toggle="collapse" href="#">
                            <i className="bi bi-menu-button-wide" />
                            <span>Leave</span>
                            <i className="bi bi-chevron-down ms-auto" />
                        </a>
                        <ul id="components-leave" className={`nav-content collapse ${location.pathname.startsWith('/leave') ? "show" : ""}`} data-bs-parent="#sidebar-nav">
                            {
                                check("All Leaves") &&
                                <li>
                                    <Link to="/leave/all-leaves" className={`${location.pathname === "/leave/all-leaves" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>All Leaves</span>
                                    </Link>
                                </li>
                            }
                            {
                                check("Assign Leaves") &&
                                <li>
                                    <Link to="/leave/assign-leaves" className={`${location.pathname === "/leave/assign-leaves" ? "active" : ""}`}>
                                        <i className="bi bi-circle" />
                                        <span>Assign Leaves</span>
                                    </Link>
                                </li>
                            }
                        </ul>
                    </li>
                }
            </ul>
            {/* Punch Out Modal */}
            <Modal show={punchOutModal} onHide={punchOutModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Punch Out</Modal.Title>
                </Modal.Header>
                <form onSubmit={handlePunchOutModal}>
                    <Modal.Body>
                        {/* ('Break', 'Day Off', 'Meeting', 'Switch Project', 'Completed') */}
                        <label className="form-label" htmlFor="stopreason">Punch Out Reason</label>
                        <select className="form-select" name="stopreason" id="stopreason"
                            value={punchOutReason.stopreason}
                            onChange={e => setPunchOutReason(e.target.value)}>
                            <option hidden selected value="">Select Punch Out Reason</option>
                            <option value="Break">Break</option>
                            <option value="Day Off">Day Off</option>
                            <option value="Lunch Break">Lunch Break</option>
                            <option value="Going out for Personal Work">Going out for Personal Work</option>
                            <option value="Going out for Client Meeting">Going out for Client Meeting</option>
                            <option value="To attend Phone Call">To attend Phone Call</option>
                        </select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-primary' type="submit">
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </aside>
    )
}
