import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useGetTeams = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const getTeams = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/teams/getTeams.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response')
            }

            if (responseData.success) {
                /*
                => responseData.teams (An Array of Objects)
                -------------------------------------------
                [
                    {
                        "tId": "1",
                        "tName": "SEO",
                        "team_leader": '1',
                        "username": "john_doe",
                        "members": [
                            {
                                "employeeID": 3,
                                "username": "bob_jones"
                            },
                            {
                                "employeeID": 2,
                                "username": "jane_smith"
                            },
                            {
                                "employeeID": 1,
                                "username": "john_doe"
                            },
                            {
                                "employeeID": 4,
                                "username": "Sapan"
                            }
                        ]
                    }, {...}, {...}
                ]
                */
                return responseData.teams;
            } else {
                alert(responseData.error);
                return [];
            }
        } catch (error) {
            console.error('An error occurred in fetching teams:', error);
            alert('An error occurred. Please try again later.');
            return [];
        } finally {
            setLoading(false);
        }
    }

    return { getTeams, loading };
}

export default useGetTeams;