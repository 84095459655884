import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js

export default function DoughnutChart({ data }) {
    const [chart, setChart] = useState(null);
    const chartRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Cleanup previous chart instance
        if (chart) {
            chart.destroy();
        }

        // Aggregate working hours for each user
        const aggregatedData = data.reduce((acc, curr) => {
            acc[curr.workingHours + "  " + curr.username] = parseTimeToHours(curr.workingHours);
            return acc;
        }, {});

        const usernames = Object.keys(aggregatedData);
        setUsers(usernames);
        const workingHours = Object.values(aggregatedData);


        const ctx = chartRef.current.getContext('2d');
        const myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: usernames,
                datasets: [{
                    label: 'Working Hours',
                    data: workingHours,
                    backgroundColor: generateRandomColors(usernames.length),
                    borderWidth: 1
                }]
            },
            options: {
                // maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Working Hours Distribution', // Your title here
                        font: {
                            size: 18,
                            weight: 'bold'
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                return `${(context.label).substring(10)}: ${context.label.substring(0, 8)} hrs.`;
                            }
                        }
                    },
                    legend: {
                        display: false // Hide legend labels
                    },
                    // legend: {
                    //     position: 'right',
                    //     title: 'Working Hours',
                    //     labels: {
                    //         usePointStyle: true,
                    //         color: 'black',
                    //         font: {
                    //             size: 12
                    //         },
                    //         // Set padding for legend labels
                    //         padding: 20 // Adjust the value as needed
                    //     }
                    // }
                },
                animation: {
                    animateRotate: true,
                    animateScale: true
                },
                responsive: true,
                // layout: {
                //     padding: {
                //         top: 20, // Add padding to the top of the chart
                //         bottom: 20, // Add padding to the bottom of the chart
                //         // Set margin for the chart
                //         // margin: {
                //             // top: 20,
                //             // bottom: 20,
                //             // left: 20,
                //             // right: 20
                //         // }
                //     }
                // }
            }

        });

        setChart(myChart);

        return () => {
            if (myChart) {
                myChart.destroy();
            }
        };
    }, [data]);

    const parseTimeToHours = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
        return (hours + minutes / 60 + seconds / 3600).toFixed(0);
    };

    const generateRandomColors = (numColors) => {
        const colors = [];
        for (let i = 0; i < numColors; i++) {
            colors.push(`rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.90)`);
        }
        setColors(colors);
        return colors;
    };

    const handleUserHover = (index) => {
        if (chart && chart.data.datasets.length > 0) {
            chart.data.datasets[0].backgroundColor = colors.map((color, i) => (i === index ? color : 'rgba(0, 0, 0, 0.1)'));
            chart.update();
        }
    };

    const handleUserLeave = () => {
        chart.data.datasets[0].backgroundColor = colors;
        chart.update();
    };

    return (
        <>
            <div className='col-auto'>
                <div className="chart-container"
                    style={{
                        height: '450px',
                        width: '450px',
                    }}
                >
                    <canvas ref={chartRef} />
                </div>
            </div>
            <div className="col-auto overflow-auto" style={{ height: '450px' }}>
                <ul className='list-group'>
                    {users.map((user, index) => (
                        <li key={index} className="list-group-item"
                            onMouseEnter={() => handleUserHover(index)}
                            onMouseLeave={handleUserLeave}
                        >
                            <span style={{
                                backgroundColor: colors[index],
                                borderRadius: '50%',
                                height: '15px',
                                width: '15px',
                                display: 'inline-block',
                            }} className="me-2" />{user}</li>
                    ))}
                </ul>
            </div>
        </>
    );
}
