import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../Loader';
import { useGetProjectsList } from '../../hooks/useGetProjectsList';
import useGetTaskTypes from '../../hooks/useGetTaskTypes';

export const ProjectReport = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Project Report') || {};
    const [loading, setLoading] = useState(false);
    
    const { rId } = JSON.parse(localStorage.getItem('edetails'));

    const [reportForm, setReportForm] = useState({
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        pId: "all",
        ttId: "all"
    });

    const [projects, setProjects] = useState([]);
    const { getProjects } = useGetProjectsList();

    const [taskTypes, setTaskTypes] = useState([]);
    const { getTaskTypes } = useGetTaskTypes();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReportForm({ ...reportForm, [name]: value });
    }

    const handleSearch = async (e) => {

        if (e) e.preventDefault();

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getProjectReport.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.pList === undefined) {
                    alert(responseData.message);
                    setPList([]);
                } else {
                    /*
                    => responseData.tasks (An Array of Objects)
                    -------------------------------------------
                    */
                    setPList(responseData.pList);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const navigate = useNavigate();
    const [pList, setPList] = useState([]);

    useEffect(() => {
        (async () => setProjects(await getProjects()))();
        (async () => setTaskTypes(await getTaskTypes()))();

        handleSearch();
    }, []);

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form className='row m-4 justify-content-start align-items-center g-3' onSubmit={handleSearch}>
                                <div className="col-auto">
                                    <label htmlFor="pId" className="form-label">Project</label>
                                    <select className="form-select" id="pId" name="pId" value={reportForm.pId} onChange={handleChange}>
                                        <option value="all">All</option>
                                        {projects.map((p) => <option key={p.pId} value={p.pId}> {p.pName} </option>)}
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="ttId" className="form-label">Task Type</label>
                                    <select className="form-select" id="ttId" name="ttId" value={reportForm.ttId} onChange={handleChange}>
                                        <option value="all">All</option>
                                        {taskTypes.map((t) => <option key={t.ttId} value={t.ttId}> {t.ttTitle} </option>)}
                                    </select>
                                </div>
                                <div className="col-lg">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            <label htmlFor="from_date" className="form-label">From</label>
                                            <input type="date" className="form-control" id="from_date"
                                                name="from_date" value={reportForm.from_date} onChange={handleChange} />
                                        </div>
                                        <div className="col-sm-6 col-md-4">
                                            <label htmlFor="to_date" className="form-label mt-md-auto mt-3">To</label>
                                            <input type="date" className="form-control" id="to_date"
                                                name="to_date" value={reportForm.to_date} onChange={handleChange} />
                                        </div>
                                        <div className="col-sm-12 col-md-4 mt-md-auto mt-4">
                                            <button type="submit" className="w-100 btn btn-success">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">TASKS</h5>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Project Name</th>
                                                {(rId == 1) && <th scope="col">Developers</th>}
                                                <th scope="col">Estimated Hours</th>
                                                <th scope="col">Time Taken</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pList.map((p, index) => (
                                                <tr key={p.pId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{p.pName}</td>
                                                    {(rId == 1) && <td>{p.dev_count}</td>}
                                                    <td>{p.estimatedHours}</td>
                                                    <td>
                                                        <Link to={`/report/project-detail-report?${Object.keys(reportForm)
                                                            .map((key) => {
                                                                if (key == "pId")
                                                                    return `${encodeURIComponent(key)}=${encodeURIComponent(p.pId)}`;
                                                                return `${encodeURIComponent(key)}=${encodeURIComponent(reportForm[key])}`;
                                                            })
                                                            .join('&')}&pName=${encodeURIComponent(p.pName)}`}>
                                                            {p.total_time_taken}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
