import React from 'react';

// export const DynamicMenusList = ({ data, dropdownId, dropdown, handleName, handleEditing, roleDetails, setRoleDetails }) => {
export const DynamicMenusList = ({ data, dropdownId, dropdown, roleDetails, setRoleDetails }) => {

    const handleDropdown = (id) => {

        const ele = document.querySelector(`[myid='${id}']`)
        ele.classList.toggle('mb-2');
        ele.parentNode.classList.toggle('list-selected');

        const toggleButton = ele.children[2].classList;
        if (toggleButton.contains('bi-chevron-down')) {
            toggleButton.remove('bi-chevron-down');
            toggleButton.add('bi-chevron-up');
        } else {
            toggleButton.remove('bi-chevron-up');
            toggleButton.add('bi-chevron-down');
        }
    }

    const handleCheckboxChangeForItem = (id, type, value) => {
        value = value ? 1 : 0;
        setRoleDetails(roleDetails.map(item => {
            if (item.pcId == id) {
                return { ...item, [type]: value };
            }
            return item;
        }));
    };

    const handleMultipleCheck = (item, value) => {
        value = value ? 1 : 0;
        setRoleDetails(roleDetails.map(rd => {
            if (rd.pcId == item.pcId) {
                return { ...rd, add: value, edit: value, view: value, delete: value };
            }
            return rd;
        }));
    }

    // const prevData = useRef(data);
    // const prevRoleDetails = useRef(roleDetails);

    // useEffect(() => {

    //     // Optimize by comparing stringified data
    //     const currentDataString = JSON.stringify(data);
    //     const currentRoleDetailsString = JSON.stringify(roleDetails);

    //     // Check if data has actually changed
    //     if (currentDataString !== JSON.stringify(prevData.current) || currentRoleDetailsString !== JSON.stringify(prevRoleDetails.current)) {
    //         prevData.current = data;
    //         prevRoleDetails.current = roleDetails;

    //         data.forEach(item => {
    //             let thisItem = roleDetails.find(detail => detail.pcId == item.pcId);

    //             if (!thisItem) {
    //                 thisItem = { pcId: item.pcId, add: 0, edit: 0, view: 0, delete: 0 };
    //                 setRoleDetails(prevDetails => [...prevDetails, thisItem]);
    //             }
    //         });
    //     }
    // }, [data, roleDetails, setRoleDetails]);

    return (
        <ul id={dropdownId} className={`list-group ${dropdown ? 'collapse' : ''}`}>
            {data.map((item) => {

                let thisItem = roleDetails.find(id => id.pcId == item.pcId);

                // If role is not selected and item is not present in roleDetails, then add it
                if (!thisItem) {
                    thisItem = { pcId: item.pcId, add: 0, edit: 0, view: 0, delete: 0 };
                }

                if (item.children) {
                    return (
                        <li className="list-group-item" key={item.pcId}>
                            <div className="row align-items-center" myid={item.pcId}>
                                <div className="col-6">

                                    <input className="form-check-input me-2" type="checkbox"
                                        checked={thisItem.add == 1 && thisItem.edit == 1 && thisItem.view == 1 && thisItem.delete == 1}
                                        onChange={(e) => handleMultipleCheck(thisItem, e.target.checked)} />

                                    {/* {
                                        item.isEditing ?

                                            <input style={{ width: '25%', minWidth: '180px', display: 'inline', height: '25px' }}
                                                onBlur={() => handleEditing(item.pcId, false)}
                                                autoFocus={true}
                                                className="form-control me-2" type="text"
                                                onKeyDown={(e) => e.key === 'Enter' && handleEditing(item.pcId, false)}
                                                onChange={(e) => handleName(item.pcId, e.target.value)} value={item.name} /> : */}

                                    <label
                                        // onDoubleClickCapture={() => handleEditing(item.pcId, true)}
                                        className="form-check-label">{item.name}</label>
                                    {/* } */}

                                </div>
                                <div className="col-5">
                                    <input type="checkbox" checked={thisItem.add == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'add', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Add </label>

                                    <input type="checkbox" checked={thisItem.view == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'view', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> View </label>

                                    <input type="checkbox" checked={thisItem.edit == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'edit', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Edit </label>

                                    <input type="checkbox" checked={thisItem.delete == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'delete', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Delete </label>
                                </div>
                                <a className="border-0 btn collapsed col-1 bi bi-chevron-down"
                                    data-bs-toggle="collapse" data-bs-target={`#${item.pcId}`}
                                    aria-expanded="false"
                                    onClick={() => handleDropdown(item.pcId)}>
                                </a>
                            </div>
                            <DynamicMenusList
                                // handleName={handleName} handleEditing={handleEditing} key={item.pcId}
                                dropdownId={item.pcId} dropdown={true} data={item.children}
                                roleDetails={roleDetails} setRoleDetails={setRoleDetails} />
                        </li>
                    );

                } else {
                    return (
                        <li className="list-group-item" key={item.pcId}>
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <input className="form-check-input me-2" type="checkbox"
                                        checked={thisItem.add == 1 && thisItem.edit == 1 && thisItem.view == 1 && thisItem.delete == 1}
                                        onChange={(e) => handleMultipleCheck(thisItem, e.target.checked)} />

                                    {/* {
                                        item.isEditing ?

                                            <input style={{ width: '25%', minWidth: '180px', display: 'inline', height: '25px' }}
                                                onBlur={() => handleEditing(item.pcId, false)}
                                                autoFocus={true}
                                                className="form-control me-2" type="text"
                                                onKeyDown={(e) => e.key === 'Enter' && handleEditing(item.pcId, false)}
                                                onChange={(e) => handleName(item.pcId, e.target.value)} value={item.name} /> : */}

                                    <label
                                        // onDoubleClickCapture={() => handleEditing(item.pcId, true)}
                                        className="form-check-label">{item.name}</label>
                                    {/* } */}
                                </div>

                                <div className="col-6">
                                    <input type="checkbox" checked={thisItem.add == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'add', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Add </label>

                                    <input type="checkbox" checked={thisItem.view == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'view', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> View </label>

                                    <input type="checkbox" checked={thisItem.edit == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'edit', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Edit </label>

                                    <input type="checkbox" checked={thisItem.delete == 1}
                                        onChange={(e) => handleCheckboxChangeForItem(item.pcId, 'delete', e.target.checked)}
                                        className='form-check-input me-1' />
                                    <label htmlFor="" className='form-label me-3'> Delete </label>
                                </div>
                            </div>
                        </li>
                    );
                }
            })}
            {/* <li className="list-group-item" >
                <form action="">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <button className="btn btn-primary" type='submit' id="button-addon2">ADD</button>
                    </div>
                </form>
            </li> */}
        </ul>
    )
}
