import React, { useEffect, useState } from 'react';
import useGetRoles from '../hooks/useGetRoles';

const Roles = ({ roleId, editMode }) => {
    const { getRoles } = useGetRoles();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRoles();
            setRoles(response);
        }
        fetchData();
    }, []);

    return (
        roles.map((role) => (
            ((editMode == true) && (role.rName == 'Admin')) ? null :
                <option selected={roleId == role.rId} key={role.rId} value={role.rId}>{role.rName}</option>
        ))
    )
}

export default Roles
