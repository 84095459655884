import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetProjectsList } from '../../hooks/useGetProjectsList';
import $ from 'jquery';
import useGetProjectTypes from '../../hooks/useGetProjectTypes';

export const ProjectsList = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Projects List') || {};
    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const { getProjects } = useGetProjectsList();

    const [projectTypes, setProjectTypes] = useState([]);
    const { getProjectTypes } = useGetProjectTypes();

    useEffect(() => {
        const fetchData = async () => {
            const projectsData = await getProjects();
            setProjects(projectsData);

            const projectTypesData = await getProjectTypes();
            setProjectTypes(projectTypesData);

            $(function () {
                const dataTable = $('#projects').DataTable({
                    searching: true, // Enable searching/filtering
                    // columns: [
                    //     null, // First column: no specific options
                    //     { searchable: true }, // Second column: allow searching
                    //     null, // Third column: no specific options
                    //     null,
                    //     null,
                    //     { searchable: true }, // Fourth column: allow searching
                    //     null,
                    //     null
                    // ]
                });

                // Custom filtering function for project types
                $('#projectTypeFilter').on('change', function () {
                    const value = $(this).val();
                    dataTable.column(2).search(value).draw();
                });

                // Custom filtering function for project status
                $('#projectStatusFilter').on('change', function () {
                    const value = $(this).val();
                    dataTable.column(5).search(value).draw();
                });
            });
        };

        fetchData();

        // Cleanup function to destroy the DataTable instance when the component unmounts
        return () => {
            if ($.fn.DataTable.isDataTable('#projects')) {
                $('#projects').DataTable().destroy(true);
            }
        };
    }, []);

    async function handleDelete(pId) {
        if (!window.confirm("Are you sure you want to delete this project?")) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/deleteProject.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ pId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setProjects(projects.filter((project) => project.pId !== pId));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error deleting project:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    return (
        <main className="main" id='main'>
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">PROJECTS</h5>
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        {/* <label htmlFor="projectTypeFilter" className="form-label">Filter by Project Type:</label> */}
                                        <select id="projectTypeFilter" className="form-select">
                                            <option selected hidden disabled>Select Type</option>
                                            <option value="">All</option>
                                            {projectTypes.map(type => (
                                                <option key={type.pTypeId} value={type.pName}>{type.pName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        {/* <label htmlFor="projectStatusFilter" className="form-label">Filter by Project Status:</label> */}
                                        <select id="projectStatusFilter" className="form-select">
                                            <option selected hidden disabled>Select Status</option>
                                            <option value="">All</option>
                                            <option value="Not Started">Not Started</option>
                                            <option value="In Progress">In Progress</option>
                                            <option value="Deployed">Deployed</option>
                                            <option value="Completed">Completed</option>
                                        </select>
                                    </div>
                                    {selectedRP && (selectedRP.add == 1) && (
                                        <div className="col-auto">
                                            <Link to="/projects/projectslist/addproject" className="btn btn-secondary">
                                                <i className="bi bi-plus-lg" /><span className='ms-1'>ADD</span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <>
                                    <table className="table w-100" id="projects">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" width="200px">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Estimated Hours</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Active</th>
                                                {selectedRP &&
                                                    ((selectedRP.view == 1) || (selectedRP.edit == 1) || (selectedRP.delete == 1)) &&
                                                    (<th scope="col" className='text-center'>Actions</th>)}
                                            </tr>
                                        </thead>
                                        <tbody className='table-group-divider'>
                                            {projects.map((project, index) => (
                                                <tr key={project.pId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{project.pName}</td>
                                                    <td>{project.projectTypeName}</td>
                                                    <td>{project.estimatedHours}</td>
                                                    <td>
                                                        {new Date(project.startDate).toLocaleDateString("en-GB")}
                                                    </td>
                                                    {/* If it is completed then show the completion date in brackets */}
                                                    <td>{project.status === "Completed" ?
                                                        `${project.status} (${new Date(project.completedDate).toLocaleDateString("en-GB")})` :
                                                        project.status}</td>
                                                    <td>{project.active == '1' ? "Yes" : "No"}</td>
                                                    {selectedRP &&
                                                        ((selectedRP.view == 1) || (selectedRP.edit == 1) || (selectedRP.delete == 1)) &&
                                                        (<td>
                                                            <div className="d-flex gap-2 justify-content-center">
                                                                {selectedRP && (selectedRP.view == 1) &&
                                                                    (<Link to={`/projects/projectslist/viewdetails/${project.pId}`} type="button"
                                                                        className="btn btn-info">
                                                                        <i className="bi bi-eye-fill" />
                                                                    </Link>)}
                                                                {selectedRP && (selectedRP.edit == 1) &&
                                                                    (<Link to={`/projects/projectslist/editdetails/${project.pId}`} type="button"
                                                                        className="btn btn-primary">
                                                                        <i className="bi bi-pencil-fill" />
                                                                    </Link>)}
                                                                {selectedRP && (selectedRP.delete == 1) &&
                                                                    (<a onClick={() => handleDelete(project.pId)} type="button"
                                                                        className="btn btn-danger">
                                                                        <i className="bi bi-trash3-fill" />
                                                                    </a>)}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ) : <p className='text-center'>No Access</p>}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
