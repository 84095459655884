import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import { Login } from './components/Login';
import { Sidebar } from './components/Sidebar';
import { Navbar } from './components/Navbar';
import EDMain from './components/employeeDetails/EDMain';
import { EditEmployee } from './components/employeeDetails/EditEmployee';
import { AddEmployee } from './components/employeeDetails/AddEmployee';
import { URMain } from './components/userRoles/URMain';
import { ProjectsList } from './components/projects/ProjectsList';
import ProjectType from './components/projects/ProjectType';
import { ViewDetails } from './components/projects/ViewDetails';
import { EditDetails } from './components/projects/EditDetails';
import { AddProject } from './components/projects/AddProject';
import { useEffect, useState } from 'react';
import { TaskType } from './components/tasks/TaskType';
import { AddTask } from './components/tasks/AddTask';
import { TeamsMain } from './components/teams/TeamsMain';
import { Dashboard } from './components/dashboard/Dashboard';
import { TasksList } from './components/tasks/TasksList';
import ViewTask from './components/tasks/ViewTask';
import EditTask from './components/tasks/EditTask';
import { ErrorPage } from './ErrorPage';
import { MyProfile } from './components/MyProfile';
import useTimeCounter from './store/useTimeCounter';
import { CompletedTList } from './components/tasks/CompletedTList';
import { PriorityTList } from './components/tasks/PriorityTList';
import { IndividualReport } from './components/report/IndividualReport';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import { AttendanceReport } from './components/report/AttendanceReport';
import { AllLeaves } from './components/leave/AllLeaves';
import { AddLeave } from './components/leave/AddLeave';
import { AssignLeaves } from './components/leave/AssignLeaves';
import EditLeave from './components/leave/EditLeave';
import ViewLeave from './components/leave/ViewLeave';
import { ProjectReport } from './components/report/ProjectReport';
import { ProjectDetailReport } from './components/report/ProjectDetailReport';
import { BasicReport } from './components/report/BasicReport';

function RootLayout({ setToken, setRP, rp }) {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    // Dynamically set the title based on the current path
    if (path.includes('edmain')) document.title = 'Employee Details';
    
    else if (path.includes('userroles')) document.title = 'User Roles';

    else if (path.includes('projectslist')) document.title = 'Projects';
    else if (path.includes('project-type')) document.title = 'Project Type';

    else if (path.includes('taskslist')) document.title = 'Tasks';
    else if (path.includes('priority-tasks')) document.title = 'Priority Tasks';
    else if (path.includes('completed-tasks')) document.title = 'Completed Tasks';
    else if (path.includes('task-type')) document.title = 'Task Type';

    else if (path.includes('teams')) document.title = 'Teams';

    else if (path.includes('basic-report')) document.title = 'Basic Report';
    else if (path.includes('individual-report')) document.title = 'Individual Report';
    else if (path.includes('attendance-report')) document.title = 'Attendance Report';
    else if (path.includes('project-report')) document.title = 'Project Report';

    else if (path.includes('all-leaves')) document.title = 'All Leaves';
    else if (path.includes('assign-leaves')) document.title = 'Leave Management';

    else document.title = 'Dashboard'; // Default title


    // Optionally, you can append something common to the title
    // document.title = document.title + ' | My Company';

  }, [location]); // Dependency on location so it runs every time the route changes

  return (
    <>
      <Navbar setToken={setToken} setRP={setRP} />
      <Sidebar rp={rp} />
      <Outlet />
    </>
  );
}


function App() {

  const [token, setToken] = useState(localStorage.getItem('token') != null ? true : false);
  const [rp, setRP] = useState(JSON.parse(localStorage.getItem('roles')) || []);

  useEffect(() => {
    if (token) {
      useTimeCounter.getState().reinitialize();
    }
  }, [token]);

  const check = (name, type) => {
    if (!Array.isArray(rp) || rp.length === 0) return false; // Check if rp is an array and has elements
    const role = rp.find(role => role.name === name); // Find role by name
    if (!role) return false; // If role is not found, return false
    // Check role permissions
    if (type === 'any') {
      return (role.add == 1 || role.edit == 1 || role.delete == 1 || role.view == 1);
    } else if (type === 'add') {
      return role.add == 1;
    } else if (type === 'edit') {
      return role.edit == 1;
    } else if (type === 'delete') {
      return role.delete == 1;
    } else if (type === 'view') {
      return role.view == 1;
    }
  };


  const router = createBrowserRouter([
    {
      path: "/",
      element: token ? <RootLayout key="rootlayout" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/",
          element: <Dashboard key="dashboard" />
        },
        {
          path: "/dashboard/profile",
          element: <MyProfile />
        }
      ]
    },
    {
      path: "/edmain",
      element: token ? <RootLayout key="edmain" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/edmain",
          element: check("Employee Details", 'any') ? <EDMain rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/edmain/addnewemployee",
          element: check("Employee Details", 'add') ? <AddEmployee rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/edmain/editemployee/:employeeID",
          element: check("Employee Details", 'edit') ? <EditEmployee rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/userroles",
      element: token ? <RootLayout key="userroles" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/userroles",
          element: check("User Roles", 'any') ? <URMain rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/projects",
      element: token ? <RootLayout key="projects" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: '/projects/projectslist',
          element: check("Projects List", 'any') ? <ProjectsList rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/projects/projectslist/viewdetails/:pId",
          element: check("Projects List", 'view') ? <ViewDetails rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/projects/projectslist/editdetails/:pId",
          element: check("Projects List", 'edit') ? <EditDetails rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/projects/projectslist/addproject",
          element: check("Projects List", 'add') ? <AddProject rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/projects/project-type",
          element: check("Projects List", 'any') ? <ProjectType rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/tasks",
      element: token ? <RootLayout key="tasks" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/tasks/task-type",
          element: check("Task Type", 'any') ? <TaskType rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/tasks/addtask",
          element: check("Tasks List", 'add') ? <AddTask rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: '/tasks/taskslist',
          element: check("Tasks List", 'any') ? <TasksList rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/tasks/edittask/:taskId",
          element: check("Tasks List", 'edit') ? <EditTask rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/tasks/viewtask/:taskId",
          element: check("Tasks List", 'view') ? <ViewTask rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/tasks/completed-tasks",
          element: check("Completed Tasks", 'view') ? <CompletedTList rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/tasks/priority-tasks",
          element: check("Priority Tasks", 'view') ? <PriorityTList rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/report",
      element: token ? <RootLayout key="report" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/report/basic-report",
          element: check("Basic Report", 'view') ? <BasicReport rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/report/individual-report",
          element: check("Individual Report", 'view') ? <IndividualReport rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/report/attendance-report",
          element: check("Attendance Report", 'view') ? <AttendanceReport rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/report/project-report",
          element: check("Project Report", 'view') ? <ProjectReport rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/report/project-detail-report",
          element: check("Project Report", 'view') ? <ProjectDetailReport rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/leave",
      element: token ? <RootLayout key="leave" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: "/leave/all-leaves",
          element: check("All Leaves", 'view') ? <AllLeaves rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/leave/add-leave",
          element: check("All Leaves", 'add') ? <AddLeave rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/leave/edit-leave/:id",
          element: check("All Leaves", 'edit') ? <EditLeave rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/leave/view-leave/:id",
          element: check("All Leaves", 'view') ? <ViewLeave rp={rp} setRP={setRP} /> : <ErrorPage />
        },
        {
          path: "/leave/assign-leaves",
          element: check("Assign Leaves", 'view') ? <AssignLeaves rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: '/teams',
      element: token ? <RootLayout key="teams" rp={rp} setRP={setRP} setToken={setToken} /> : <Navigate to="/login" />,
      children: [
        {
          path: '/teams',
          element: check("Teams", 'any') ? <TeamsMain rp={rp} setRP={setRP} /> : <ErrorPage />
        }
      ]
    },
    {
      path: "/error",
      element: <ErrorPage key="404" />
    },
    {
      path: "/login",
      element: <Login key="login" setToken={setToken} setRP={setRP} />
      // element: token ? <Navigate to="/" key="dashboard" setToken={setToken} setRP={setRP} /> : <Login key="login" setToken={setToken} />
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;