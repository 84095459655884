import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorPage = () => {
    return (
        <main id="main" className="main">
            <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-start">
                <h1>404</h1>
                <h2>The page you are looking for doesn't exist.</h2>
                <div className='d-flex gap-3'>
                    <Link className="btn" to={"/"}>Back to home</Link>
                    <Link className="btn" to={"/login"} onClick={() => localStorage.clear()}>Login</Link>
                </div>
            </section>
        </main>
    )
}
