import React, { useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

const DateRangePicker = ({ from_date, to_date, setLeaveForm, editMode }) => {
    
    const [dateRange, setDateRange] = useState([from_date, to_date]);
    const [EM, setEM] = useState(editMode);

    useEffect(() => {
        // Update dateRange only if both from_date and to_date are not the same as current state
        if (from_date !== dateRange[0] && to_date !== dateRange[1] && EM) {
            setDateRange([from_date, to_date]);
            setLeaveForm(prevForm => ({
                ...prevForm,
                from_date: formatDate(from_date),
                to_date: formatDate(to_date),
            }));
            setEM(false);
        }
    }, [from_date, to_date]);

    const formatDate = (date) => {
        const d = new Date(date);

        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(d.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (selectedDates) => {
        setDateRange(selectedDates);
        if(selectedDates.length == 1) {
            setLeaveForm(prevForm => ({
                ...prevForm,
                from_date: "",
                to_date: "",
            }));
        }
        else{
            setLeaveForm(prevForm => ({
                ...prevForm,
                from_date: formatDate(selectedDates[0]),
                to_date: formatDate(selectedDates[1]),
            }));
        }
    };

    return (
        <Flatpickr
            id='dateRange'
            className='form-control'
            value={dateRange}
            onChange={handleDateChange}
            options={{
                mode: 'range',
                dateFormat: 'd/m/Y',
                time_24hr: true,
                utc: true,
            }}
        />
    );
};

export default DateRangePicker;
