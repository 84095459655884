import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Modal } from 'react-bootstrap';

export const BasicReport = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Basic Report') || {};
    const [loading, setLoading] = useState(false);

    const [reportForm, setReportForm] = useState({
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10)
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReportForm({ ...reportForm, [name]: value });
    }

    const handleSearch = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/basicReport.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.report === undefined) {
                    alert(responseData.message);
                    setAttendances([]);
                } else {
                    setAttendances(responseData.report);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // For Modal
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setLogForm({});
        setShowModal(false)
    };
    const handleShow = () => setShowModal(true);

    const [logForm, setLogForm] = useState({});
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        handleLogs();
    }, [logForm.in_time]);

    const handleLogs = async () => {

        if (!logForm.in_time) return;

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/report/getLogs.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(logForm)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                console.log(responseData);

                setLogs(responseData.logs);
                handleShow();
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const navigate = useNavigate();
    const [attendances, setAttendances] = useState([]);

    const handleExport = () => {
        const data = attendances.map((att, index) => ({
            "#": index + 1,
            "Employee Name": att.username,
            "Present Days": att.present_days,
            "Office Time / Average": `${att.total_time_spent} / ${att.average_total_time}`,
            "Total Task Time / Average": `${att.total_task_time} / ${att.average_task_time}`
        }));


        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Basic Report");

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        saveAs(blob, 'basic_report.xlsx');
    };

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form className='row m-4 justify-content-center align-items-center g-3' onSubmit={handleSearch}>
                                <div className="col-lg-3">
                                    <label htmlFor="from_date" className="form-label">From</label>
                                    <input type="date" className="form-control" id="from_date"
                                        name="from_date" value={reportForm.from_date} onChange={handleChange} />
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="to_date" className="form-label">To</label>
                                    <input type="date" className="form-control" id="to_date"
                                        name="to_date" value={reportForm.to_date} onChange={handleChange} />
                                </div>
                                <div className="col-lg-2 mt-auto">
                                    <div className='mt-3'>
                                        <button type="submit" className="w-100 btn btn-info">Search</button>
                                    </div>
                                </div>
                                <div className="col-lg-2 mt-auto">
                                    <div className='mt-3'>
                                        <button type="button" onClick={handleExport} className="w-100 btn btn-success">Export to Excel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">BASIC REPORT</h5>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Present Days</th>
                                            <th scope="col">Office Time / Average</th>
                                            <th scope="col">Total Task Time / Average </th>
                                            <th scope="col">Tasks Accessed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendances.map((att, index) =>
                                            <tr key={att.employeeID}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{att.username}</td>
                                                <td>{att.present_days}</td>
                                                <td>{att.total_time_spent} / {att.average_total_time}</td>
                                                <td>{att.total_task_time} / {att.average_task_time}</td>
                                                <td>
                                                    <button onClick={() => setLogForm({
                                                        employeeID: att.employeeID,
                                                        username: att.username,
                                                        in_time: att.in_time,
                                                        out_time: att.out_time,
                                                    })}
                                                        className='btn btn-secondary'>
                                                        <i className="bi bi-justify" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                    </div>
                </div>
                <Modal show={showModal} onHide={handleClose} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{logForm.username} <span style={{ fontSize: '16px' }}>{`(${logForm.in_time} - ${logForm.out_time})`}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200px">Title</th>
                                    <th scope="col">Task Type</th>
                                    <th scope="col">Time Required</th>
                                    <th scope="col">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs && logs.map((log) => (
                                    <tr key={log.taskId}>
                                        <td>{log.title}</td>
                                        <td>{log.ttTitle}</td>
                                        <td>{convertMinutesToHHMMSS(log.timerequired)}</td>
                                        <td>{log.time_spent}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </section>
        </main>
    )
}
