import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { PForm } from './PForm'

export const AddProject = () => {

    const navigate = useNavigate();
    const [pDetails, setPDetails] = useState({
        pName: "",
        description: "",
        active: true,
        startDate: "",
        endDate: "",
        completedDate: "",
        status: "",
        doc1: null,
        doc2: null,
        doc3: null,
        estimatedHours: "",
        pTypeId: "",
        assignedTo: []
    });

    const handleSubmit = async (e) => {

        e.preventDefault();

        if(pDetails.pTypeId == "" || pDetails.pName == "" || pDetails.description == "" || pDetails.status == "" || pDetails.estimatedHours == "" || pDetails.assignedTo.length == 0) {
            alert("Please fill the necessary details");
            return;
        }
        const formData = new FormData();

        //Append all form data to formData except assignedTo
        Object.keys(pDetails).forEach(key => {
            if (key !== "assignedTo") {
                formData.append(key, pDetails[key]);
            }
        });

        // Append all assignedTo data to formData
        Object.keys(pDetails.assignedTo).forEach(key => {
            formData.append("assignedTo[]", (pDetails.assignedTo[key]));
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/addProject.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                navigate("/projects/projectslist");
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the addition of project:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    return (
        <main id="main" className="main">
            <section className="section">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5 className="card-title">ADD PROJECT</h5>
                            <Link to={"/projects/projectslist"} className='btn btn-danger'>
                                <i className="bi bi-arrow-left"></i>
                            </Link>
                        </div>
                        <PForm handleSubmit={handleSubmit} pDetails={pDetails} setPDetails={setPDetails} parentName="AddProject" />
                    </div>
                </div>
            </section>
        </main>
    )
}
