import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DateRangePicker from './DateRangePicker';

export const LeaveForm = ({ leaveForm, setLeaveForm, handleFormSubmit, editMode }) => {

    const [leaveCategories, setLeaveCategories] = useState([]);
    const navigate = useNavigate();
    const [leave_statuses, setLeaveStatuses] = useState([]);

    const isAdmin = JSON.parse(localStorage.getItem('edetails'))['rName'] === 'Admin';

    const [noOfDays, setNoOfDays] = useState(0);
    const categoryRef = useRef(null);

    useEffect(() => {
        // Update days whenever dates are changed
        const selectedCategory = categoryRef.current?.options[categoryRef.current.selectedIndex].text;
        let days = ((new Date(leaveForm.to_date) - new Date(leaveForm.from_date)) / 86400000 + 1);

        if (isNaN(days)) days = 0;

        // Adjust the days for "Half Day" selection
        if (selectedCategory && selectedCategory === "Half Day") {
            setNoOfDays(days / 2);
        } else {
            setNoOfDays(days);
        }
    }, [leaveForm.from_date, leaveForm.to_date, categoryRef.current?.options[categoryRef.current.selectedIndex].text]);

    useEffect(() => {
        const fetchLeaveCategories = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/getLeaveCategories.php`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    let message = "";
                    if (response.status === 401) {
                        // Handle unauthorized access
                        message = 'Unauthorized access. Please login again.';
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        message = 'Network response was not ok';
                    }
                    throw new Error(message);
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    setLeaveCategories(responseData.leave_categories);
                } else {
                    alert(responseData.error);
                }

            } catch (error) {
                alert(error.message);
            }
        }

        fetchLeaveCategories();

        const fetchLeaveStatuses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/getLeaveStatuses.php`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    let message = "";
                    if (response.status === 401) {
                        // Handle unauthorized access
                        message = 'Unauthorized access. Please login again.';
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        message = 'Network response was not ok';
                    }
                    throw new Error(message);
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    setLeaveStatuses(responseData.leave_statuses);
                } else {
                    alert(responseData.error);
                }

            } catch (error) {
                alert(error.message);
            }
        }

        fetchLeaveStatuses();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLeaveForm({ ...leaveForm, [name]: value });
    }


    return (
        <form id='leaveForm' onSubmit={handleFormSubmit}>
            <div className='row justify-content-center align-items-center gap-3'>
                {
                    editMode &&
                    <>
                        <div className="col-lg-7">
                            <label htmlFor="current_status" className="form-label">Current Status</label>
                            <input type="text" disabled className="form-control" id="current_status" name="current_status" value={leaveForm.current_status} onChange={handleChange} />
                        </div>
                    </>
                }
                {
                    editMode && isAdmin &&
                    <>
                        <div className="col-lg-7">
                            <label htmlFor="username" className="form-label">Applicant Name</label>
                            <input type="text" className="form-control" id="username" name="username" disabled value={leaveForm.username} onChange={handleChange} />
                        </div>
                        <div className='col-lg-7'>
                            <label htmlFor="leave_status_id" className="form-label">Change Status</label>
                            <select className="form-select" name="leave_status_id" id="leave_status_id" value={leaveForm.leave_status_id} onChange={handleChange}>
                                <option hidden value="">Select leave status</option>
                                {leave_statuses.map((status) => (
                                    <option key={status.id} value={status.id}>{status.name}</option>
                                ))}
                            </select>
                        </div>
                    </>
                }
                <div className="col-lg-7">
                    <label htmlFor="dateRange" className="form-label">Leave Date/s (pick two dates)</label>
                    <DateRangePicker from_date={leaveForm.from_date} to_date={leaveForm.to_date} setLeaveForm={setLeaveForm} editMode={editMode} />
                    <span className="text-success">No. of days selected: {noOfDays}</span>
                </div>
                <div className="col-lg-7">
                    <label htmlFor="leave_category_id" className="form-label">Leave Category</label>
                    <select className="form-select" name="leave_category_id" id="leave_category_id" value={leaveForm.leave_category_id} onChange={handleChange} ref={categoryRef}>
                        <option hidden value="">Select leave category</option>
                        {leaveCategories.map((category) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-lg-7">
                    <label htmlFor="reason" className="form-label">Reason</label>
                    <textarea className="form-control" id="reason" name="reason" value={leaveForm.reason} onChange={handleChange} />
                </div>
                <div className="col-lg-7">
                    <label htmlFor="question" className="form-label">Who will look after for your work ?</label>
                    <input type="text" className="form-control" id="question" name="question" value={leaveForm.question} onChange={handleChange} placeholder='Name of the colleague' />
                </div>
                <div className="col-lg-7 text-center">
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-danger ms-2" onClick={() => navigate('/leave/all-leaves')}>Back to Leaves</button>
                </div>
            </div>
        </form>
    )
}
