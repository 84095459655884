import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useGetTasksList = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchTasks = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/getTasksList.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.tasks === undefined) {
                    alert(responseData.message);
                    return [];
                } else {
                    /*
                    => responseData.tasks (An Array of Objects)
                    -------------------------------------------
                    [
                        {
                            "taskId": 1,
                            "title": "Task 1",
                            "pId": 1,
                            "pName": "Project A",
                            "ttId": 1,
                            "ttTitle": "Development",
                            "assignedto": 1,
                            "assignedtoname": "john_doe",
                            "assignedby": "jane_smith",
                            "taskstatus": "Not Attempted",
                            "taskpriority": "High",
                            "taskmode": null
                        }, {...}, {...}
                    ]
                    */
                    return responseData.tasks;
                }
            } else {
                alert(responseData.error);
                return [];
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
            alert('An error occurred. Please try again later.');
            return [];
        } finally {
            setLoading(false);
        }
    }

    return { loading, fetchTasks }

}