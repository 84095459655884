import { create } from 'zustand';
import $ from "jquery";

const usePunchInStore = create((set) => ({
    punchIn: false,
    setPunchIn: (punchIn) => set({ punchIn }),
    fetchAttendance: async () => {
        $(".attendanceBtn").addClass("disabled");
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/sidebar/getAttendance.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                }
                throw new Error('Network response was not ok');
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                set({ punchIn: responseData.showPunchIn });
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error fetching data in fetchData:', error);
        } finally {
            $(".attendanceBtn").removeClass("disabled");
        }
    },

    handleAttendance: async (punchOutReason) => {
        const { punchIn } = usePunchInStore.getState();

        if (punchOutReason === "" || punchOutReason === null || punchOutReason === undefined) {
            punchOutReason = "";
        }

        $(".attendanceBtn").addClass("disabled");

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/sidebar/handleAttendance.php`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ punchIn, punchOutReason }),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    alert('Unauthorized access. Please login again. 3');
                    localStorage.clear();
                }
                throw new Error('Network response was not ok');
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                if (responseData.punchIn) {
                    // reload the page
                    window.location.reload();
                }
                set({ punchIn: responseData.showPunchIn });
                alert(responseData.message);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error fetching data in handleAttendance:', error);
        } finally {
            $(".attendanceBtn").removeClass("disabled");
        }
    }
}));

export default usePunchInStore;
