import React, { useEffect, useState } from 'react'
import { PForm } from './PForm'
import { Link, useNavigate, useParams } from 'react-router-dom'

export const EditDetails = () => {

    const navigate = useNavigate();
    const pId = useParams().pId;
    const [pDetails, setPDetails] = useState({
        pId: pId,
        pName: "",
        description: "",
        active: true,
        startDate: "",
        endDate: "",
        completedDate: "",
        createdDate: "",
        status: "",
        doc1: null,
        doc2: null,
        doc3: null,
        estimatedHours: "",
        pTypeId: "",
        assignedTo: []
    });

    useEffect(() => {

        const formData = new FormData();
        formData.append('pId', pId);

        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/getPDetails.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: formData
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    /*
                    => responseData.pDetails (An Object) 
                    ------------------------------------
                    {
                        "pId": 3,
                        "pName": "React Developer",
                        "description": "d",
                        "active": 0,
                        "startDate": "0000-00-00",
                        "endDate": "0000-00-00",
                        "createdDate": "2024-04-09",
                        "completedDate": "0000-00-00",
                        "pTypeId": 2,
                        "status": "In Progress",
                        "doc1": "17126610800.txt",
                        "doc2": null,
                        "doc3": null,
                        "estimatedHours": 58
                    }
                    
                    => responseData.assignees (An Array of Objects)
                    ------------------------------------------------
                    [
                        {
                            "employeeID": 13,
                            "username": "Sapan"
                        }, {...}, {...}
                    ]
                    */
                    let assignees = responseData.assignees.map(assignee => assignee.employeeID.toString());
                    let data = responseData.pDetails;
                    setPDetails({
                        ...data,
                        active: data.active == 1 ? true : false,
                        pTypeId: data.pTypeId?.toString(),
                        estimatedHours: data.estimatedHours?.toString(),
                        assignedTo: assignees
                    });
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('An error occurred in fetching project details in EditDetails:', error);
                alert('An error occurred. Please try again later.');
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();
        setPDetails({ ...pDetails, active: pDetails.active ? 1 : 0 });
        if(pDetails.pTypeId == "" || pDetails.pName == "" || pDetails.description == "" || pDetails.status == "" || pDetails.estimatedHours == "" || pDetails.assignedTo.length == 0) {
            alert("Please fill the necessary details");
            return;
        }

        const formData = new FormData();

        //Append all form data to formData
        Object.keys(pDetails).forEach(key => {
            if (key !== "assignedTo") {
                formData.append(key, pDetails[key]);
            }
        });

        Object.keys(pDetails.assignedTo).forEach(key => {
            formData.append("assignedTo[]", (pDetails.assignedTo[key]));
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/editPDetails.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                navigate('/projects/projectslist');
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in editing project details:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    return (
        <main id="main" className="main">
            <section className="section">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5 className="card-title">Edit Details</h5>
                            <Link to={"/projects/projectslist"} className='btn btn-danger'>
                                <i className="bi bi-arrow-left"></i>
                            </Link>
                        </div>
                        <PForm pDetails={pDetails} setPDetails={setPDetails} handleSubmit={handleSubmit} parentName={"EditDetails"} />
                    </div>
                </div>
            </section>
        </main>
    )
}
