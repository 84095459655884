import React from 'react';
// import Moment from 'react-moment';
import useTimeCounter from '../store/useTimeCounter';
import ForwardTimer from './ForwardTimer';

function TimeCounter() {
  const { startTime } = useTimeCounter();

  return (
    <div className="border p-2 text-center rounded ms-2 text-primary border-primary">
      <ForwardTimer seconds={(startTime / 1000).toFixed(0)} />
      {/* <Moment>{startTime}</Moment> */}
    </div>
  );
}

export default TimeCounter;