import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TimeCounter from './TimeCounter';
import useTimeCounter from '../store/useTimeCounter';

export const Navbar = ({ setToken, setRP }) => {

    const edetails = JSON.parse(localStorage.getItem('edetails'));
    const navigate = useNavigate();
    const { stopTimer } = useTimeCounter();
    const handleLogout = () => {
        stopTimer();
        setToken(false);
        localStorage.clear();
        setRP([]);
        navigate('/login');
    }

    return (
        <header id="header" className="header fixed-top d-flex align-items-center header-scrolled">
            <div className="d-flex align-items-center justify-content-between">
                <Link to={"/"}>
                    <img className="w-100 img-fluid" style={{ maxWidth: '250px' }} src={`${process.env.REACT_APP_BACKEND_URL}/my-assets/img/logo.png`} alt="" />
                </Link>
                <i className="bi bi-list toggle-sidebar-btn"
                    onClick={() => document.body.classList.toggle('toggle-sidebar')}></i>
            </div>
            <TimeCounter />
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item dropdown pe-3">
                        <a style={{ cursor: 'pointer' }} className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                            <span className="d-none d-md-block dropdown-toggle ps-2">{edetails?.username}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{edetails?.username}</h6>
                                <span>{edetails?.rName}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <Link className="dropdown-item d-flex align-items-center" to="/dashboard/profile">
                                    <i className="bi bi-person"></i>
                                    <span>My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <button onClick={handleLogout} className="dropdown-item d-flex align-items-center">
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Sign Out</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
