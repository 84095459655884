import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate } from 'react-router-dom';


const DatePicker = ({ setDoughnutChartData }) => {

    const navigate = useNavigate();
    const [state, setState] = useState({
        selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
        // compare: {
        //   startDate: new Date(),
        //   endDate: addDays(new Date(), 3),
        //   key: 'compare'
        // }
    });

    useEffect(() => {
        handleSubmit();
    }, []);

    function handleChange(item) {
        setState({
            ...state,
            ...item
        });
    }
    const handleSubmit = async (e) => {

        if (e) e.preventDefault();
        if (!state.selection.startDate || !state.selection.endDate || state.selection.startDate == null || state.selection.endDate == null) {
            alert("Please select dates");
            return;
        }
        const startDate = state.selection.startDate;
        const endDate = state.selection.endDate;

        // Function to format a date object to "Y-m-d" format
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/dashboard/adminDashboard.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    from: formatDate(startDate),
                    to: formatDate(endDate)
                })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                }
                throw new Error("Network response was not ok");
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                setDoughnutChartData(responseData.hoursCount);
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
            // alert(error.message);
        }
    }
    return (
        <div className="date-picker-container col-auto">
            <div className="custom-date-range-picker">
                <DateRangePicker
                    onChange={item => handleChange(item)}
                    months={1}
                    minDate={addDays(new Date(), -300)}
                    maxDate={addDays(new Date(), 900)}
                    direction="vertical"
                    ranges={[state.selection]}
                    className='m-auto'
                />
            </div>
            <button onClick={handleSubmit} className="btn btn-primary mt-2" style={{ width: '415px' }}>
                Submit
            </button>
        </div>
    );
}

export default DatePicker