import React, { useEffect, useState } from 'react'
import { RolesList } from './RolesList';
import { useNavigate } from 'react-router-dom';
import { DynamicMenusList } from './DynamicMenusList';

export const URMain = ({ rp, setRP }) => {

    const navigate = useNavigate();
    const selectedRP = rp.find(role => role.name === 'User Roles') || {};

    const [menuItems, setMenuItems] = useState([]);
    const [roles, setRoles] = useState([]);
    const [allMenuIds, setAllMenuIds] = useState([]);
    useEffect(() => {
        const getRoles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/roles/getRoles.php`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    /*
                    => responseData.roles (An Array of Objects)
                    -------------------------------------------
                    [
                        {
                            "rId": "1",
                            "rName": "Senior PHP Developer"
                        }, {...}, {...}
                    ]
                    */
                    setRoles(responseData.roles);
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        getRoles();

        const fetchMenuItems = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/getList.php`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    /* responseData.menuItems
                    -------------------------
                    [
                        {
                            "pcId": "1",
                            "name": "Dashboard",
                            "checked": false,
                            "isEditing": false
                        },
                        {
                            "pcId": "2",
                            "name": "Teams",
                            "checked": false,
                            "isEditing": false
                        },
                        {
                            "pcId": "3",
                            "name": "Projects",
                            "checked": false,
                            "isEditing": false
                        },
                        {
                            "pcId": "4",
                            "name": "Tasks",
                            "children": [
                                {
                                    "pcId": "5",
                                    "name": "Task Type",
                                    "children": [
                                        {
                                            "pcId": "10",
                                            "name": "justFun 1",
                                            "children": [
                                                {
                                                    "pcId": "11",
                                                    "name": "justFun 2",
                                                    "children": [
                                                        {
                                                            "pcId": "12",
                                                            "name": "justFun 3",
                                                            "children": [
                                                                {
                                                                    "pcId": "13",
                                                                    "name": "justFun 4",
                                                                    "checked": false,
                                                                    "isEditing": false
                                                                }
                                                            ],
                                                            "checked": false,
                                                            "isEditing": false
                                                        }
                                                    ],
                                                    "checked": false,
                                                    "isEditing": false
                                                }
                                            ],
                                            "checked": false,
                                            "isEditing": false
                                        }
                                    ],
                                    "checked": false,
                                    "isEditing": false
                                },
                                {
                                    "pcId": "6",
                                    "name": "Add Task",
                                    "checked": false,
                                    "isEditing": false
                                },
                                {
                                    "pcId": "7",
                                    "name": "Tasks List",
                                    "checked": false,
                                    "isEditing": false
                                }
                            ],
                            "checked": false,
                            "isEditing": false
                        },
                        {
                            "pcId": "8",
                            "name": "Employee Details",
                            "checked": false,
                            "isEditing": false
                        },
                        {
                            "pcId": "9",
                            "name": "User Roles",
                            "checked": false,
                            "isEditing": false
                        }
                    ]
                    */
                    setMenuItems(responseData.menuItems);
                    /* responseData.allMenuIds (An Array of Objects)
                    ------------------------------------------------
                    [
                        {
                            "pcId": "1",
                            "add": 0,
                            "edit": 0,
                            "delete": 0,
                            "view": 0
                        }, {...}, {...}
                    ]
                    */
                    setAllMenuIds(responseData.allMenuIds);
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('Error stopping task:', error);
                alert('An error occurred. Please try again later.');
            }
        }
        fetchMenuItems();
    }, []);

    const [roleDetails, setRoleDetails] = useState([]);
    const [rId, setRId] = useState('');

    useEffect(() => {
        const fetchRoleDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/getRoleDetails.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ rId })
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    /* responseData.roleDetails (An Array of Objects or an empty array)
                    ------------------------------------------------- 
                    [
                        {
                            "rId": 1,
                            "rName": "Senior PHP Developer",
                            "pcId": 1,
                            "add": 1,
                            "edit": 0,
                            "delete": 1,
                            "view": 1
                        }, {...}, {...}
                    ]
                    */
                    const roleDetails = responseData.roleDetails;
                    setRoleDetails(allMenuIds.map(item => {
                        const thisItem = roleDetails.find(rd => rd.pcId == item.pcId);
                        if (!thisItem) {
                            return item;
                        } else {
                            return {
                                pcId: item.pcId,
                                add: thisItem.add,
                                edit: thisItem.edit,
                                view: thisItem.view,
                                delete: thisItem.delete
                            };
                        }
                    }));
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchRoleDetails();

        // allMenuIds is present in dependency array because it will be an empty array when the page loads
    }, [rId, allMenuIds]);

    // const handleChange = (id, type, value) => {
    //     const updateItem = (item) => {
    //         if (item.pcId == id) {
    //             return { ...item, [type]: value };
    //         }

    //         if (item.children) {
    //             const updatedChildren = item.children.map(child => updateItem(child));
    //             return { ...item, children: updatedChildren };
    //         }

    //         return item;
    //     };

    //     setMenuItems(menuItems.map(item => updateItem(item)));
    // };

    // const handleName = (id, value) => {
    //     handleChange(id, 'name', value);
    // };

    // const handleEditing = (id, value) => {
    //     handleChange(id, 'isEditing', value);   
    // };

    // const handleCheckboxChange = (id, value) => {
    //     handleChange(id, 'checked', value);
    // };
    const handleRoleDetailsUpdate = (updatedDetails) => {
        setRoleDetails(updatedDetails);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/updateRoleDetails.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ rId, roleDetails })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
            console.error('Error:', error);
        }
    }
    return (
        <main className="main" id='main'>
            <section className="section">
                <div className="row">
                    <RolesList roles={roles} setRoles={setRoles} selectedRP={selectedRP} />
                </div>
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                {selectedRP && (selectedRP.view == 1) && (
                                    <>
                                        <div className="form-floating my-3">
                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example"
                                                onChange={(e) => setRId(e.target.value)} value={rId}>
                                                <option hidden>Open this select menu</option>
                                                {roles.map(role => (
                                                    <option key={role.rId} value={role.rId}>{role.rName}</option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">Select the Role</label>
                                        </div>
                                        <DynamicMenusList
                                            // handleName={handleName}
                                            // handleEditing={handleEditing}
                                            dropdown={false} data={menuItems}
                                            roleDetails={roleDetails} setRoleDetails={handleRoleDetailsUpdate} />
                                    </>
                                )}
                                {(selectedRP && (selectedRP.edit == 1)) &&
                                    (<button type="submit" className="my-2 btn btn-primary w-100">
                                        update
                                    </button>)}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
