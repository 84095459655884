import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login = ({ setToken, setRP }) => {

  const navigate = useNavigate();
  const [lDetails, setLDetails] = useState({
    username: "",
    password: ""
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/login.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(lDetails)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (error) {
        throw new Error('Error parsing JSON response');
      }

      if (responseData.success) {
        /*
        => responseData.roles (An array of object)
        ------------------------------------------
        [
          {
            "pcId": 1,
            "name": "Dashboard",
            "add": 1,
            "view": 1,
            "edit": 1,
            "delete": 1
          }, {...}, {...}
        ]
        */
        setRP(responseData.roles);
        localStorage.setItem('roles', JSON.stringify(responseData.roles));
        /*
        => responseData.token (string)
        ------------------------------
        eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbXBsb3llZUlEIjo0LCJ1c2VybmFtZSI6IlNhcGFuIiwiZW1haWwiOiJzYXBhbnNpZGh3YW5pNDBAZ21haWwuY29tIn0.IkGaxsiY5DuR_-81Rn4FBXU5GTwgk-CmFO4Z7x1-Ae4
        */
        localStorage.setItem('token', responseData.token);
        /*
        => responseData.edetails
        ------------------------
          {
            "employeeID": 4,
            "username": "Sapan",
            "email": "sapansidhwani40@gmail.com",
            "rId": 2,
            "rName": "Junior PHP Developer"
          }
        */
        localStorage.setItem('edetails', JSON.stringify(responseData.edetails));
        setToken(true);
        navigate('/');
      }
      else {
        alert(responseData.error);
      }
    } catch (error) {
      console.error('An error occurred in login:', error);
      alert('An error occurred. Please try again later.');
    }
  }

  const onChangeFunc = (e) => {
    setLDetails({
      ...lDetails,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
      setToken(true);
    } else {
      localStorage.clear();
      setToken(false);
    }
  }, []);
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <img className="w-100 img-fluid mb-4 mx-auto" style={{ maxWidth: '350px' }} src={`${process.env.REACT_APP_BACKEND_URL}/my-assets/img/logo.png`} alt="" />
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p className="text-center small">Enter your username &amp; password to login</p>
                    </div>
                    <form className="row g-3 needs-validation" onSubmit={handleLogin} noValidate="">
                      <div className="col-12">
                        <label htmlFor="yourUsername" className="form-label">Username</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text" id="inputGroupPrepend">@</span>
                          <input type="text" onChange={onChangeFunc} value={lDetails.username} name="username" className="form-control" id="yourUsername" required={true} />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">Password</label>
                        <input type="password" onChange={onChangeFunc} value={lDetails.password} name="password" className="form-control" id="yourPassword" required={true} />
                        <div className="invalid-feedback">Please enter your password!</div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-warning w-100" type="submit">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
