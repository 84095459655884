import React, { useEffect, useState } from 'react'
import { useGetTasksList } from '../../hooks/useGetTasksList';
import { Link, useLocation } from 'react-router-dom';
import { LogsModal } from '../LogsModal';
import $ from 'jquery';

export const CompletedTList = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Completed Tasks') || {};

    const [tasks, setTasks] = useState([]);
    const location = useLocation();
    const { fetchTasks } = useGetTasksList();
    useEffect(() => {
        fetchTasks().then((data) => {
            const newData = data.filter((task) => task.taskstatus === "Completed");
            setTasks(newData);

            $(function () {
                $('#completedTasksTable').DataTable();
            });
        }).catch((error) => {
            console.log(error);
        });

        // Cleanup function to destroy the DataTable instance when the component unmounts
        return () => {
            if ($.fn.DataTable.isDataTable('#completedTasksTable')) {
                $('#completedTasksTable').DataTable().destroy(true);
            }
        };
    }, []);

    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    return (
        <main className="main" id="main">
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">COMPLETED TASKS</h5>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <table id="completedTasksTable" className="table w-100">
                                    <thead>
                                        <tr>
                                            {/* taskId */}
                                            <th scope="col">#</th>
                                            {/* title */}
                                            <th scope="col" width="200px">Title</th>
                                            {/* assignedtoname, assignedby */}
                                            <th scope="col">Assigned</th>
                                            {/* ttId, ttTitle */}
                                            <th scope="col">Task Type</th>
                                            {/* taskpriority */}
                                            <th scope="col">Priority</th>
                                            <th scope="col">Start Date</th>
                                            {/* status */}
                                            <th scope="col">Task Status</th>
                                            {/* time required */}
                                            <th scope="col">Time Required/<br/>Taken</th>
                                            <th scope="col" className='text-center'>Logs</th>
                                            <th scope='col' className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.map((task, index) => (
                                            <tr key={task.taskId}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                <td>
                                                    <span className='fw-bold'>to: </span>{task.assignedtoname}
                                                    <br />
                                                    <span className="fw-bold">by: </span>{task.assignedby}
                                                </td>

                                                <td>{task.ttTitle}</td>
                                                <td>{task.taskpriority}</td>
                                                <td>{task.startDate == null ? task.createddate : task.startDate}</td>
                                                <td>{task.taskstatus}</td>
                                                <td>{convertMinutesToHHMMSS(task.timerequired)} / {task.timetaken}</td>
                                                {/* Task Logs */}
                                                <td className='text-center'>
                                                    <button onClick={() => setLogIdAndTitle({
                                                        taskId: task.taskId,
                                                        title: task.title,
                                                        assignedtoname: task.assignedtoname
                                                    })} className='btn btn-secondary'>
                                                        <i className="bi bi-justify" />
                                                    </button>
                                                </td>
                                                <td>
                                                    <div className='d-flex gap-2 justify-content-center'>
                                                        <Link state={location.pathname} className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                        {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} />}
                    </div>
                </div>
            </section>
        </main>
    );
}
