import React, { useEffect, useState } from 'react';
import useGetProjectTypes from '../../hooks/useGetProjectTypes';
import usePType from '../../store/usePType';
import { useNavigate } from 'react-router-dom';
import useGetMembers from '../../hooks/useGetMembers';

export const PForm = ({ handleSubmit, pDetails, setPDetails, parentName }) => {

    const navigate = useNavigate();

    const { projectTypes, setProjectTypes } = usePType();
    const { loading, getProjectTypes } = useGetProjectTypes();

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);

    useEffect(() => {
        if (projectTypes.length === 0) {
            (async () => {
                setProjectTypes(await getProjectTypes());
            })();
        }

        (async () => setMembers((await getMembers()).filter(member => member.status == 1)))();
    }, []);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            setPDetails({ ...pDetails, [name]: e.target.files[0] });
        } else {
            setPDetails({ ...pDetails, [name]: value });
        }
    }

    const handleCheckboxChange = (e) => {
        let { id } = e.target;

        // includes can give error if id is not a string
        if (typeof id !== 'string') {
            id = id.toString();
        }
        
        if (pDetails.assignedTo.includes(id)) {
            setPDetails({ ...pDetails, assignedTo: pDetails.assignedTo.filter(employee => employee != id) });
        } else {
            setPDetails({ ...pDetails, assignedTo: [...pDetails.assignedTo, id] });
        }
    }

    const handleDeleteDoc = async (colName, docName) => {
        if (!window.confirm("Are you sure you want to delete this Document?")) {
            return;
        }
        const formData = new FormData();

        formData.append('docName', docName);
        formData.append('colName', colName);
        formData.append('pId', pDetails.pId);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/deleteDoc.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setPDetails({ ...pDetails, [colName]: null });
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error('An error occurred in deleting document:', error);
            alert('An error occurred. Please try again later.');
        }

    }

    return (
        <form className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
            <div className="col-12">
                <label htmlFor="pName" className="form-label">
                    Project Name <span className="text-danger">*</span>
                </label>
                <input type="text" className="form-control" id="pName"
                    name="pName" value={pDetails.pName} onChange={handleChange} required />
            </div>
            <div className="col-12">
                <label htmlFor="description" className="form-label">
                    Description <span className="text-danger">*</span>
                </label>
                <textarea className="form-control" id="description"
                    name="description" value={pDetails.description} onChange={handleChange} required />
            </div>
            <div className="col-3">
                <label htmlFor="active" className="form-label">Active</label>
                <select className="form-select" name="active" value={pDetails.active} onChange={handleChange}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
            </div>
            <div className="col-3">
                <label htmlFor="projectType" className="form-label">
                    Project Type <span className="text-danger">*</span>
                </label>
                <select className="form-select" name='pTypeId' value={pDetails.pTypeId} onChange={handleChange} required>
                    <option hidden>Select Project Type</option>
                    {projectTypes.map((p) => <option key={p.pTypeId} value={p.pTypeId}>{p.pName}</option>)}
                </select>
            </div>
            <div className="col-3">
                <label htmlFor="status" className="form-label">
                    Project Status <span className="text-danger">*</span>
                </label>
                <select className="form-select" name="status" value={pDetails.status} onChange={handleChange} required>
                    <option hidden>Select Status</option>
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Deployed">Deployed</option>
                    <option value="Completed">Completed</option>
                </select>
            </div>
            <div className="col-3">
                <label htmlFor="estimatedHours" className="form-label">
                    Estimated Hours <span className="text-danger">*</span>
                </label>
                <input type="number" onChange={handleChange} name="estimatedHours" value={pDetails.estimatedHours} className="form-control" id="estimatedHours" required />
            </div>
            <div className="col-4">
                <label htmlFor="doc1" className="form-label">Document 1</label>

                {parentName === "AddProject" &&
                    <input type="file" className="form-control" id="doc1" name="doc1" onChange={handleChange} />
                }

                {parentName === "EditDetails" && (typeof pDetails.doc1 === "string" ?
                    (
                        <>
                            <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc1}`}>view</a>
                            <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc1", pDetails.doc1)}>Delete</a>
                        </>
                    ) :
                    <input type="file" className="form-control" id="doc1" name="doc1" onChange={handleChange} />)
                }
            </div>
            <div className="col-4">
                <label htmlFor="doc2" className="form-label">Document 2</label>

                {parentName === "AddProject" &&
                    <input type="file" className="form-control" id="doc2" name="doc2" onChange={handleChange} />}

                {parentName === "EditDetails" && (typeof pDetails.doc2 === "string" ?
                    (
                        <>
                            <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc2}`}>view</a>
                            <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc2", pDetails.doc2)}>Delete</a>
                        </>
                    ) :
                    <input type="file" className="form-control" id="doc2" name="doc2" onChange={handleChange} />)}
            </div>
            <div className="col-4">
                <label htmlFor="doc3" className="form-label">Document 3</label>

                {parentName === "AddProject" &&
                    <input type="file" className="form-control" id="doc3" name="doc3" onChange={handleChange} />}

                {parentName === "EditDetails" && (typeof pDetails.doc3 === "string" ?
                    (
                        <>
                            <a target="_blank" className='d-block' href={`${process.env.REACT_APP_BACKEND_URL}/uploads/${pDetails.doc3}`}>view</a>
                            <a className='btn btn-danger' onClick={() => handleDeleteDoc("doc3", pDetails.doc3)}>Delete</a>
                        </>
                    ) :
                    <input type="file" className="form-control" id="doc3" name="doc3" onChange={handleChange} />)}
            </div>
            <div className="col-6">
                <label htmlFor="assignedTo" className="form-label">
                    Assigned To <span className="text-danger">*</span>
                </label>
                <div className="dropdown">
                    <button className="btn btn-info dropdown-toggle w-100" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Select Members
                    </button>
                    <ul className="dropdown-menu w-100">
                        {
                            members &&
                            members.map((employee) => (
                                <li className="dropdown-item" key={employee.employeeID}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            id={employee.employeeID}
                                            name={employee.username}
                                            checked={pDetails.assignedTo.includes(employee.employeeID.toString())}
                                            onChange={handleCheckboxChange} />
                                        <label className="form-check-label"
                                            htmlFor={employee.employeeID}>{employee.username}</label>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="col-6">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input type="date" className="form-control" id="startDate"
                    name="startDate" value={pDetails.startDate} onChange={handleChange} />
            </div>
            <div className="col-6">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input type="date" className="form-control" id="endDate"
                    name="endDate" value={pDetails.endDate} onChange={handleChange} />
            </div>
            <div className="col-6">
                <label htmlFor="completedDate" className="form-label">Completed Date</label>
                <input type="date" className="form-control" id="completedDate"
                    name="completedDate" value={pDetails.completedDate} onChange={handleChange} />
            </div>
            <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </form>
    )
}
