import React, { useEffect, useState } from 'react'
import { EmployeeForm } from './EmployeeForm';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const EditEmployee = () => {

    const navigate = useNavigate();
    const [employeeDetails, setEmployeeDetails] = useState({
        username: "",
        email: "",
        password: "",
        status: true,
        phoneNumber: "",
        rId: ""
    });

    const employeeID = useParams().employeeID;

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/employeeDetails/selectedEmployeeDetails.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ employeeID })
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    /*
                    => responseData.selectedEmployeeDetails (An Object)
                    ---------------------------------------------------
                    {
                        employeeID: 13,
                        username: 'Sapan',
                        email: 'sapansidhwani4@gmail.com',
                        status: 0,
                        phoneNumber: '6353713153',
                        rId: 2,
                        status: 0,
                        username: "Sapan"
                    }
                    */
                    setEmployeeDetails(responseData.selectedEmployeeDetails);
                } else {
                    alert(responseData.error);
                }

            } catch (error) {
                console.error('An error occurred in fetching user data in edit employee:', error);
                alert('An error occurred. Please try again later.');
            }

        }
        fetchUserData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Define an array of field names
        const requiredFields = ['username', 'email'];

        // Iterate over the fields and check if any of them are empty
        for (const field of requiredFields) {
            if (!employeeDetails[field]) {
                alert(`Please fill the ${field} field`);
                return;
            }
        }
        if (!employeeDetails.rId) {
            alert('Please select the Role field');
            return;
        }
        if (!employeeDetails.phoneNumber) {
            alert('Please fill the phone number field');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/employeeDetails/editEmployee.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(employeeDetails)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }
            
            if (responseData.success) {
                alert(responseData.message);
                // it is not necessary
                navigate('/edmain');
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in the form submission of edit employee:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    return (
        <main id="main" className="main">
            <section className="section">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="card-title">EDIT EMPLOYEE</h5>
                            <Link className="btn btn-danger" to="/edmain"><i className="bi bi-arrow-left"></i></Link>
                        </div>
                        <EmployeeForm employeeDetails={employeeDetails} handleSubmit={handleSubmit} setEmployeeDetails={setEmployeeDetails} editMode={true} />
                    </div>
                </div>
            </section>
        </main>
    )
}
