import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetEmployeesList } from '../../hooks/useGetEmployeesList';

export const TeamsMain = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Teams') || {};
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [employees, setEmployees] = useState([]);
    const { getEmployees } = useGetEmployeesList();

    // Get Teams
    const getTeams = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/teams/getTeams.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }
            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response')
            }
            if (responseData.success) {
                /*
                => responseData.teams (An Array of Objects)
                -------------------------------------------
                [
                    {
                        "tId": "1",
                        "tName": "SEO",
                        "team_leader": '1',
                        "username": "john_doe",
                        "members": [
                            {
                                "employeeID": 3,
                                "username": "bob_jones"
                            },
                            {
                                "employeeID": 2,
                                "username": "jane_smith"
                            },
                            {
                                "employeeID": 1,
                                "username": "john_doe"
                            },
                            {
                                "employeeID": 4,
                                "username": "Sapan"
                            }
                        ]
                    }, {...}, {...}
                ]
                */
                setTeams(responseData.teams);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in fetching teams:', error)
            alert('An error occurred. Please try again later.')
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getEmployees();
            setEmployees(response.filter(employee=> employee.status == 1).map(employee => ({ username: employee.username, employeeID: employee.employeeID })));
        })();
        getTeams();
    }, []);

    // Delete Team
    const handleDelete = async (tId) => {
        if (!window.confirm("Are you sure you want to delete this team?")) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/teams/deleteTeam.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setTeams(teams.filter((team) => team.tId !== tId));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in deleting team:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    // Add Team
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [team, setTeam] = useState({
        tId: '',
        tName: '',
        team_leader: '',
        members: []
    });
    const [operation, setOperation] = useState('');

    const handleAdd = () => {
        setOperation('Add');
        setTeam({
            tId: '',
            tName: '',
            team_leader: '',
            members: []
        });
        handleShow();
    }

    const handleEdit = (team) => {
        const { tId, tName, members, team_leader } = team;
        setOperation('Edit');
        setTeam({
            tId: tId,
            tName: tName,
            team_leader: team_leader,
            members: members.map(member => member.employeeID.toString())
        });
        handleShow();
    }

    const handleChange = (e) => {
        const { name } = e.target;
        if (team.members.includes(name)) {
            setTeam({ ...team, members: team.members.filter(member => member != name) });
        } else {
            setTeam({ ...team, members: [...team.members, name] });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!team.tName || team.members.length == 0) {
            alert('Team name and members are required');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/teams/${operation.toLowerCase()}Team.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }
            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }
            if (responseData.success) {
                alert(responseData.message);
                // convert members into numbers
                let mem = responseData.members.map(member => Number(member));
                // Add or edit team
                if (operation == 'Edit') {
                    setTeams(teams.map((t) => t.tId == team.tId ? {
                        ...t,
                        tName: team.tName,
                        team_leader: team.team_leader,
                        username: employees.find(employee => employee.employeeID == team.team_leader).username,
                        members: mem.map(member => {
                            return { employeeID: member, username: employees.find(employee => employee.employeeID == member).username };
                        })
                    } : t));
                } else {
                    setTeams([...teams, {
                        ...team,
                        tId: responseData.tId,
                        username: employees.find(employee => employee.employeeID == team.team_leader).username,
                        members: mem.map(member => {
                            return { employeeID: member, username: employees.find(employee => employee.employeeID == member).username };
                        })
                    }]);
                }
                setTeam({ ...team, tId: '', tName: '', members: [] });
                setOperation('');
                handleClose();
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in adding team:', error);
            alert('An error occurred. Please try again later.');
        }
    }
    return (
        <main className="main" id='main'>
            <section className="section">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                                    <h5 className="card-title">TEAMS</h5>
                                    {(selectedRP && (selectedRP.add == 1)) && (
                                        <button className="btn btn-secondary" onClick={handleAdd}>
                                            <i className="bi bi-plus-lg" /><span className='ms-1'>ADD</span>
                                        </button>
                                    )}
                                </div>
                                {(selectedRP && (selectedRP.view == 1)) ?
                                    (<table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Team Leader</th>
                                                <th scope="col">Assigned Members</th>
                                                {(selectedRP && (selectedRP.edit == 1 || selectedRP.delete == 1)) &&
                                                    (<th scope="col" className='text-center'>Actions</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teams.map((team, index) => (
                                                <tr key={team.tId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{team.tName}</td>
                                                    <td>{team.username}</td>
                                                    <td>
                                                        {
                                                            !(team.members.length > 0) ?
                                                                (<p className='card-text'>No members assigned to this team.</p>) :
                                                                (<p className='card-text'>
                                                                    {team.members.map(member => member.username).join(", ")}
                                                                </p>)
                                                        }
                                                    </td>
                                                    {(selectedRP && (selectedRP.edit == 1 || selectedRP.delete == 1)) &&
                                                        (<td className='text-center'>
                                                            <div className="d-flex justify-content-center gap-2">
                                                                {(selectedRP && (selectedRP.edit == 1)) &&
                                                                    (<a onClick={() => handleEdit(team)} className="btn btn-primary">
                                                                        <i className="bi bi-pencil-fill" />
                                                                    </a>)}
                                                                {(selectedRP && (selectedRP.delete == 1)) &&
                                                                    (<a className="btn btn-danger" onClick={() => handleDelete(team.tId)}>
                                                                        <i className="bi bi-trash3-fill" />
                                                                    </a>)}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>) : (<p className='text-center'>No Access</p>)}
                                <Modal show={showModal} onHide={handleClose} >
                                    <Modal.Header closeButton>
                                        <Modal.Title> {operation} Team </Modal.Title>
                                    </Modal.Header>
                                    <Form onSubmit={handleSubmit}>
                                        <Modal.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Select Team Leader <span className="text-danger">*</span></Form.Label>
                                                <select className="form-select"
                                                    value={team.team_leader} onChange={(e) => setTeam({ ...team, team_leader: e.target.value })}>
                                                    <option hidden>Open this select menu</option>
                                                    {employees.map((employee) => (
                                                        <option key={employee.employeeID} value={employee.employeeID}>
                                                            {employee.username}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                            <Form.Group controlId="tName">
                                                <Form.Label>Team Name <span className="text-danger">*</span></Form.Label>
                                                <Form.Control
                                                    autoComplete='off'
                                                    type="text"
                                                    placeholder="Enter team name"
                                                    value={team.tName}
                                                    onChange={(e) => setTeam({ ...team, tName: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3" controlId="teamMembers">
                                                <Form.Label>Select Members <span className="text-danger">*</span></Form.Label>
                                                <div className="dropdown">
                                                    <button className="btn btn-success dropdown-toggle w-100" type="button"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        Select Members
                                                    </button>
                                                    <ul className="dropdown-menu bg-body-tertiary w-100">
                                                        {employees.map((employee) => {
                                                            if (team.team_leader && team.team_leader == employee.employeeID) {
                                                                return null;
                                                            }
                                                            return (<li className="dropdown-item w-100" key={employee.employeeID}>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox"
                                                                        id={employee.employeeID}
                                                                        name={employee.employeeID}
                                                                        checked={team.members.includes(employee.employeeID)}
                                                                        onChange={(e) => handleChange(e)}
                                                                    />
                                                                    <label className="form-check-label"
                                                                        htmlFor={employee.employeeID}> {employee.username} </label>
                                                                </div>
                                                            </li>)
                                                        })}
                                                    </ul>
                                                </div>
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="mt-3 btn btn-primary">Submit</button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
