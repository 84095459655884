import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const LogsModal = ({ logIdAndTitle, setLogIdAndTitle, givenLogs }) => {

    const { taskId, title, assignedtoname } = logIdAndTitle;
    const [totalTimeTaken, setTotalTimeTaken] = useState('00:00:00');
    const [logs, setLogs] = useState([]);
    const navigate = useNavigate();

    // For Modal
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setLogIdAndTitle({ taskId: '', title: '', assignedtoname: '' });
        setShowModal(false)
    };
    const handleShow = () => setShowModal(true);

    // Inside your component function or where you handle state
    useEffect(() => {

        // Initialize totalTimeTaken outside of the useEffect hook
        let totalMilliseconds = 0;

        if (!(logs == undefined || logs.length == 0)) {
            // Iterate through logs to calculate total time taken
            logs.forEach((log, ind) => {
                // 1, 3, 5, ...
                if (ind % 2 !== 0) {
                    let date1 = new Date(logs[ind - 1].dateandtime);
                    let date2 = new Date(log.dateandtime);
                    totalMilliseconds += date2 - date1;
                }
            });
            // Convert total milliseconds to HH:MM:SS format
            let totalSeconds = Math.floor(totalMilliseconds / 1000);
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);
            let seconds = totalSeconds % 60;

            // Update totalTimeTaken state with formatted time
            setTotalTimeTaken(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        } else {
            setTotalTimeTaken('00:00:00');
        }

    }, [logs]); // Update total time whenever logs change

    function formatDateAndTime(data, str) {
        if (data == null || data == '' || data == undefined) {
            return str;
        }
        return new Date(data).toLocaleDateString("en-GB") + data.substr(10);
    }


    const calculateTimeDifference = (startTime, stopTime) => {
        const start = new Date(startTime);
        const stop = new Date(stopTime);
        const diff = stop - start;

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const getLogs = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/getLogs.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                return responseData.logs;
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching logs for task:', error);
            alert('An error occurred. Please try again later.');
        }

    }

    const handleLogs = async (taskId, title, assignedtoname) => {
        // setLogIdAndTitle({ taskId, title, assignedtoname });
        setLogs(await getLogs(taskId));
        handleShow();
    }

    useEffect(() => {
        if(givenLogs == undefined) {
            handleLogs(taskId, title, assignedtoname);
        } else {
            setLogs(givenLogs);
            handleShow();
        }
    }, [taskId, title, assignedtoname]);
    return (
        <Modal show={showModal} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> {`${logIdAndTitle.title} (${logIdAndTitle.assignedtoname})`}  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Start Time</th>
                            <th scope="col">Stop Time</th>
                            <th scope="col">Stop Reason</th>
                            <th scope="col">Time Taken</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs && logs.map((log, ind) => {
                            if (log.taskmode === 'Start') {
                                // Find the corresponding Stop log entry
                                const startTime = formatDateAndTime(log.dateandtime);
                                const stopLog = logs[ind + 1];

                                let stopTime = 'NA';
                                let timeTaken = 'NA';
                                let stopreason = 'NA';

                                if (stopLog && stopLog.taskmode === 'Stop') {
                                    stopTime = formatDateAndTime(stopLog.dateandtime);
                                    timeTaken = calculateTimeDifference(log.dateandtime, stopLog.dateandtime);
                                    stopreason = stopLog.stopreason;
                                }

                                return (
                                    <tr key={log.logId}>
                                        <td>{startTime}</td>
                                        <td>{stopTime}</td>
                                        <td>{stopreason}</td>
                                        <td>{timeTaken}</td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <th>Total Time:</th>
                            <td>{totalTimeTaken}</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    )
}
