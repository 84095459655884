
import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const RolesList = ({ roles, setRoles, selectedRP }) => {

    const navigate = useNavigate();

    const [toAdd, setToAdd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [selectedRole, setSelectedRole] = useState({
        rId: '',
        rName: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedRole.rName) {
            alert('Please enter a role name');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/roles/${toAdd ? 'add' : 'edit'}Role.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ rId: selectedRole.rId, rName: selectedRole.rName })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                if (toAdd) {
                    /* => responseData.role (An Object)
                    ------------------------------
                    {
                        "rId": "1",
                        "rName": "Senior PHP Developer"
                    } 
                    */
                    setRoles([...roles, responseData.role]);
                } else {
                    /* => selectedRole (An Object)
                    ------------------------------
                    {
                        "rId": "1",
                        "rName": "Senior PHP Developer"
                    }
                    */
                    setRoles(roles.map(role => role.rId == selectedRole.rId ? selectedRole : role));
                }
            } else {
                alert(responseData.error);
            }
            setToAdd(false);
            setSelectedRole({ rId: '', rName: '' });
            handleClose(); // Close modal after form submission
        } catch (error) {
            console.error('An error occurred in editing or adding role:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    const handleAdd = () => {
        setToAdd(true);
        setSelectedRole({ rId: '', rName: '' });
        handleShow();
    };

    const handleEdit = (rName, rId) => {
        setSelectedRole({ rId, rName });
        setToAdd(false);
        handleShow();
    };

    const deleteRole = async (rId) => {
        if (!window.confirm('Are you sure you want to delete this role?')) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/userRoles/roles/deleteRole.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ rId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setRoles(roles.filter((role) => role.rId != rId));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error deleting role:', error);
            alert('An error occurred while deleting the role. Please try again later.');
        }
    };
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                    <h5 className="card-title">ROLES</h5>
                    {selectedRP && (selectedRP.add == 1) && (
                        <Button variant="secondary" name='add' onClick={handleAdd}>
                            <i className="bi bi-plus-lg" /><span className="ms-1">ADD</span>
                        </Button>)}
                </div>
                {(selectedRP && selectedRP.view == 1) ? (<table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Role Name</th>
                            {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) &&
                                (<th scope="col" className='text-center'>Actions</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map((role, index) => (
                            <tr key={role.rId}>
                                <th scope="row">{index + 1}</th>
                                <td>{role.rName}</td>
                                {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) &&
                                    (<td>
                                        <div className="d-flex gap-2 justify-content-center">
                                            {(selectedRP && (selectedRP.edit == 1)) &&
                                                (<a type='button' className="btn btn-primary"
                                                    onClick={() => handleEdit(role.rName, role.rId)}>
                                                    <i className="bi bi-pencil-fill" />
                                                </a>)}
                                            {(selectedRP && (selectedRP.delete == 1)) &&
                                                (<a type='button' onClick={() => deleteRole(role.rId)} className="btn btn-danger">
                                                    <i className="bi bi-trash3-fill" />
                                                </a>)}
                                        </div>
                                    </td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>) : (<p className='text-center'>No Access</p>)}
                <Modal show={showModal} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title> {toAdd ? 'Add Role' : 'Edit Role'} </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group controlId="roleName">
                                <Form.Label>Role Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    autoComplete='off'
                                    type="text"
                                    placeholder="Enter role name"
                                    value={selectedRole.rName}
                                    onChange={(e) => setSelectedRole({ ...selectedRole, rName: e.target.value })}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="mt-3 btn btn-primary">Submit</button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}
