import React, { useEffect, useState } from 'react'
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { Loader } from '../Loader';
import useGetMembers from '../../hooks/useGetMembers';
import $ from "jquery";

export const AllLeaves = ({ rp }) => {
    const [selectedRP, setSelectedRP] = useState(rp.find(role => role.name === 'All Leaves') || {});

    const [searchParams] = useSearchParams();

    const type = searchParams.get('type');
    const fromDate = searchParams.get('from_date');
    const toDate = searchParams.get('to_date');
    const duration = searchParams.get('duration');
    const status = searchParams.get('status');

    const edetails = JSON.parse(localStorage.getItem('edetails'));
    const isAdmin = edetails['rName'] === 'Admin';
    let employeeID = edetails.employeeID;

    const [loading, setLoading] = useState(false);

    const [leaves, setLeaves] = useState([]);
    const navigate = useNavigate();

    const fetchLeaves = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/getLeaves.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                let responseLeaves = responseData.leaves;
                if (status == "Pending") {
                    responseLeaves = responseLeaves.filter(l => l.current_status == "Pending");
                }
                setLeaves(responseLeaves);
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            alert(error.message);
        }
    }

    const { getMembers } = useGetMembers();
    const [members, setMembers] = useState([]);
    const [reportForm, setReportForm] = useState({
        employeeID: employeeID,
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10)
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReportForm({ ...reportForm, [name]: value });
    }

    useEffect(() => {
        if (fromDate != null && toDate != null) {
            setReportForm({
                ...reportForm,
                employeeID: employeeID,
                from_date: fromDate,
                to_date: toDate
            });
            setLoading(true);
            setTimeout(function () {
                $("#all-leaves-button").click();
                setLoading(false);
            }, 3000)
        } else if (isAdmin) {
            (async () => setMembers((await getMembers()).filter(member => (member.status == 1))))();
            setReportForm({ ...reportForm, employeeID: "" });

            if (duration != null) {
                setLoading(true);
                setTimeout(function () {
                    $("#all-leaves-button").click();
                    setLoading(false);
                }, 3000)
            }
        }
        fetchLeaves();
    }, []);

    const handleSearch = async (e) => {
        if (e) e.preventDefault();

        let postData = JSON.stringify(reportForm);
        if (duration != null) {
            postData = JSON.stringify({ duration });
        }
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/getLeaves.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: postData
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                const leaves = responseData.leaves;
                if (type == "Paid") {
                    const newLeaves = leaves.filter(l => l.current_status == "Granted" && (l.paid_leaves_count > 0))
                    setLeaves(newLeaves);
                } else if (type == "Unpaid") {
                    const newLeaves = leaves.filter(l => l.current_status == "Granted" && (l.no_of_days > l.paid_leaves_count))
                    setLeaves(newLeaves);
                } else {
                    setLeaves(responseData.leaves);
                }
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const deleteLeave = async (id) => {

        if (!window.confirm("Are you sure you want to delete this leave?")) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/leave/deleteLeave.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            });

            if (!response.ok) {
                let message = "";
                if (response.status === 401) {
                    // Handle unauthorized access
                    message = 'Unauthorized access. Please login again.';
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    message = 'Network response was not ok';
                }
                throw new Error(message);
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
            } else {
                alert(responseData.error);
            }

        } catch (error) {
            alert("Error: " + error.message);
        } finally {
            fetchLeaves();
        }
    };

    return (
        <main className="main" id="main">
            <Loader loading={loading} />
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form className='row m-4 justify-content-center align-items-center' onSubmit={handleSearch}>
                                {isAdmin &&
                                    (
                                        <div className="col-3">
                                            <label htmlFor="employeeID" className="form-label">
                                                Select Employee
                                            </label>
                                            <div>
                                                <select className="form-select" id="employeeID"
                                                    name="employeeID" value={reportForm.employeeID} onChange={handleChange}>
                                                    <option hidden>Select Employee</option>
                                                    {members.map((employee) =>
                                                        <option key={employee.employeeID} value={employee.employeeID}>
                                                            {employee.username}
                                                        </option>)}
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className={`col-${isAdmin ? "3" : "4"}`}>
                                    <label htmlFor="from_date" className="form-label">From</label>
                                    <input type="date" className="form-control" id="from_date"
                                        name="from_date" value={reportForm.from_date} onChange={handleChange} />
                                </div>
                                <div className={`col-${isAdmin ? "3" : "4"}`}>
                                    <label htmlFor="to_date" className="form-label">To</label>
                                    <input type="date" className="form-control" id="to_date"
                                        name="to_date" value={reportForm.to_date} onChange={handleChange} />
                                </div>
                                <div className={`col-${isAdmin ? "3" : "4"} mt-auto`}>
                                    <button type="submit" id="all-leaves-button" className="w-100 btn btn-success">Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                                <h5 className="card-title">ALL LEAVES</h5>
                                {selectedRP && (selectedRP.add == 1) && (
                                    <Link to="/leave/add-leave" className="btn btn-secondary btn-sm">
                                        <i className="bi bi-plus-lg" /><span className='ms-1'>ADD</span>
                                    </Link>)}
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ?
                                (<table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            {isAdmin && <th scope="col">Name</th>}
                                            <th scope="col">Duration</th>
                                            <th scope="col">No. of Days <br /> (Paid + Unpaid)</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Current Status</th>
                                            <th scope="col">Responded By</th>
                                            {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) && (<th scope="col" className='text-center'>Action</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {leaves.map((leave, index) => (
                                            <tr key={leave.id}>
                                                <th scope="row">{index + 1}</th>
                                                {isAdmin && <td>{leave.employee_name}</td>}
                                                <td>
                                                    {new Date(leave.from_date).toLocaleDateString("en-GB")} - {new Date(leave.to_date).toLocaleDateString("en-GB")}
                                                </td>
                                                <td>
                                                    {`
                                                        ${leave.paid_leaves_count} + 
                                                        ${leave.no_of_days - leave.paid_leaves_count} = 
                                                        `}
                                                    <span className="fw-medium">{leave.no_of_days} day(s)</span>
                                                </td>
                                                <td>{leave.category_name}</td>
                                                <td>{leave.current_status}</td>
                                                <td>{leave.responded_by_name}</td>
                                                {(selectedRP && ((selectedRP.edit == 1) || (selectedRP.delete == 1))) && (
                                                    <td>
                                                        <div className="d-flex gap-2 justify-content-center">
                                                            <Link to={`/leave/view-leave/${leave.id}`} className="btn btn-sm btn-info">
                                                                <i className="bi bi-eye-fill" />
                                                            </Link>
                                                            {(selectedRP && (selectedRP.edit == 1)) && (isAdmin || (leave.current_status == 'Pending')) && (
                                                                <Link to={`/leave/edit-leave/${leave.id}`} className="btn btn-sm btn-primary">
                                                                    <i className="bi bi-pencil-fill" />
                                                                </Link>
                                                            )}
                                                            {(selectedRP && (selectedRP.delete == 1)) && (isAdmin || (leave.current_status == 'Pending')) && (
                                                                <button onClick={() => deleteLeave(leave.id)} className="btn btn-sm btn-danger">
                                                                    <i className="bi bi-trash3-fill" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>) : <p className='text-center'>No Access</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
