import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useGetTasksList } from '../../hooks/useGetTasksList';
import { useGetTaskDetails } from '../../hooks/useGetTaskDetails';
import useTimeCounter from '../../store/useTimeCounter';
import { LogsModal } from '../LogsModal';
import $ from 'jquery';

export const PriorityTList = ({ rp }) => {

    let employeeID = JSON.parse(localStorage.getItem('edetails')).employeeID;
    const selectedRP = rp.find(role => role.name === 'Completed Tasks') || {};

    const [tasks, setTasks] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { fetchTasks } = useGetTasksList();
    useEffect(() => {
        fetchTasks().then((data) => {
            const newData = data.filter((task) =>
                ((task.taskstatus !== "Completed" && task.taskstatus !== "In Review by Senior" && task.taskstatus !== "In Review by Client") && (task.taskpriority === "High" || task.taskpriority === "Critical"))
            );
            setTasks(newData);

            $(function () {
                $('#priorityTable').DataTable();
            });
        }).catch((error) => {
            console.log(error);
        });

        // Cleanup function to destroy the DataTable instance when the component unmounts
        return () => {
            if ($.fn.DataTable.isDataTable('#priorityTable')) {
                $('#priorityTable').DataTable().destroy(true);
            }
        };
    }, []);

    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    const handleDelete = async (taskId) => {
        if (!window.confirm("Are you sure you want to delete this task?")) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/deleteTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                const updatedTasks = tasks.filter(task => task.taskId !== taskId);
                setTasks(updatedTasks);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error deleting task:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    const { getTaskDetails } = useGetTaskDetails();
    const { startTimer, stopTimer } = useTimeCounter();
    const [loading, setLoading] = useState(false);

    const handleStart = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/startTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ taskId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                startTimer(responseData.timetaken);
                const taskDetails = await getTaskDetails(taskId);
                const updatedTasks = tasks.map(task => {
                    if (task.taskId == taskId) {
                        return {
                            ...task,
                            title: taskDetails.title,
                            pName: taskDetails.pName,
                            ttTitle: taskDetails.ttTitle,
                            taskstatus: taskDetails.taskstatus,
                            taskpriority: taskDetails.taskpriority,
                            taskmode: "Start"
                        };
                    }
                    return task;
                });
                setTasks(updatedTasks);
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error starting task:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    const [stopModal, setStopModal] = useState(false);
    const stopModalClose = () => setStopModal(false);
    const stopModalShow = () => setStopModal(true);
    const [idAndReason, setIdAndReason] = useState({
        taskId: '',
        stopreason: ''
    });

    const handleStop = async (e) => {
        e.preventDefault();
        if (idAndReason.stopreason === '') {
            alert('Please enter stop reason');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/stopTask.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(idAndReason)
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                stopTimer();
                let taskId = idAndReason.taskId;
                const taskDetails = await getTaskDetails(taskId);
                const updatedTasks = tasks.map(task => {
                    if (task.taskId == taskId) {
                        return {
                            ...task,
                            title: taskDetails.title,
                            pName: taskDetails.pName,
                            ttTitle: taskDetails.ttTitle,
                            taskstatus: taskDetails.taskstatus,
                            taskpriority: taskDetails.taskpriority,
                            taskmode: "Stop"
                        };
                    }
                    return task;
                });
                setTasks(updatedTasks);
                setIdAndReason({ taskId: '', stopreason: '' });
                stopModalClose();
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error stopping task:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    return (
        <main className="main" id="main">
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                <h5 className="card-title">COMPLETED TASKS</h5>
                            </div>
                            {(selectedRP && (selectedRP.view == 1)) ? (
                                <table id="priorityTable" className="table w-100">
                                    <thead>
                                        <tr>
                                            {/* taskId */}
                                            <th scope="col">#</th>
                                            {/* title */}
                                            <th scope="col" width="200px">Title</th>
                                            {/* assignedtoname, assignedby */}
                                            <th scope="col">Assigned</th>
                                            {/* ttId, ttTitle */}
                                            <th scope="col">Task Type</th>
                                            {/* taskpriority */}
                                            <th scope="col">Priority</th>
                                            <th scope="col">Start Date</th>
                                            {/* status */}
                                            <th scope="col">Task Status</th>
                                            <th scope="col">Time Required/<br/>Taken</th>
                                            {/* Task Logs */}
                                            <th scope="col" className='text-center'>Logs</th>
                                            {/* Actions */}
                                            <th scope='col' className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.map((task, index) => (
                                            <tr key={task.taskId}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                <td>
                                                    <span className='fw-bold'>to: </span>{task.assignedtoname}
                                                    <br />
                                                    <span className="fw-bold">by: </span>{task.assignedby}
                                                </td>

                                                <td>{task.ttTitle}</td>
                                                <td>{task.taskpriority}</td>
                                                <td>{task.startDate == null ? task.createddate : task.startDate}</td>
                                                <td>{task.taskstatus}</td>
                                                <td>{convertMinutesToHHMMSS(task.timerequired)} / {task.timetaken}</td>
                                                {/* Task Logs */}
                                                <td className='text-center'>
                                                    <button onClick={() => setLogIdAndTitle({
                                                        taskId: task.taskId,
                                                        title: task.title,
                                                        assignedtoname: task.assignedtoname
                                                    })} className='btn btn-secondary'>
                                                        <i className="bi bi-justify" />
                                                    </button>
                                                </td>
                                                {/* Actions */}
                                                <td>
                                                    <div className='d-flex gap-2 justify-content-center'>
                                                        <Link state={location.pathname} className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                        {(selectedRP.edit == 1) &&
                                                            (<Link state={location.pathname} className="btn btn-primary" to={`/tasks/edittask/${task.taskId}`}>
                                                                <i className="bi bi-pencil-fill" />
                                                            </Link>)}
                                                        {(selectedRP.delete == 1) &&
                                                            (<button className="btn btn-danger" onClick={() => handleDelete(task.taskId)}>
                                                                <i className="bi bi-trash3-fill" />
                                                            </button>)}
                                                        {(task.assignedto == employeeID) && (task.taskmode == "Stop" || task.taskmode == null) && (task.taskstatus === 'Not Attempted' || task.taskstatus === 'Pending') &&
                                                            (<button className="btn btn-warning" onClick={() => handleStart(task.taskId)}>
                                                                Start
                                                            </button>)}
                                                        {(task.assignedto == employeeID) && (task.taskmode == "Start") && (task.taskstatus === 'In Progress') &&
                                                            (<button className="btn btn-danger"
                                                                onClick={() => {
                                                                    stopModalShow();
                                                                    setIdAndReason({ taskId: task.taskId, stopreason: '' });
                                                                }}>
                                                                Stop
                                                            </button>)}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center">No Access</p>
                            )}
                        </div>
                        {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} />}
                        <Modal show={stopModal} onHide={stopModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Stop Task</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleStop}>
                                <Modal.Body>
                                    {/* ('Break', 'Day Off', 'Meeting', 'Switch Project', 'Completed') */}
                                    <label className="form-label" htmlFor="stopreason"> Stop Reason </label>
                                    <select className="form-select" name="stopreason" id="stopreason"
                                        value={idAndReason.stopreason}
                                        onChange={e => setIdAndReason({ ...idAndReason, stopreason: e.target.value })}>
                                        <option hidden>Select Stop Reason</option>
                                        <option value="Break">Break</option>
                                        <option value="Day Off">Day Off</option>
                                        <option value="Meeting">Meeting</option>
                                        <option value="Switch Project">Switch Project</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-primary' type="submit">
                                        Submit
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </div>
                </div>
            </section>
        </main>
    );
}
