import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const MyProfile = () => {

    const navigate = useNavigate();

    const data = JSON.parse(localStorage.getItem('edetails'));
    const [myDetails, setMyDetails] = useState({
        username: data.username,
        email: data.email,
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMyDetails({ ...myDetails, [name]: value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(myDetails);
        if (!myDetails.username || !myDetails.email) {
            alert('Please fill the required fields');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/myProfile.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: myDetails.username, email: myDetails.email, password: myDetails.password })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                localStorage.setItem('edetails', JSON.stringify({
                    ...data,
                    username: myDetails.username,
                    email: myDetails.email
                }));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred. Please try again later.');
        }
    }
    return (
        <main className="main" id="main">
            <section className="section">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">My Profile</h5>
                            <form onSubmit={handleSubmit}>
                                <div className="row mb-3">
                                    <label htmlFor="inputText" className="col-sm-2 col-form-label">
                                        Username <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="inputText"
                                            name="username" value={myDetails.username} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                                        Email <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control" id="inputEmail"
                                            name="email" value={myDetails.email} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
                                        Password
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control" id="inputPassword"
                                            name="password" value={myDetails.password} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
