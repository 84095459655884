import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { Loader } from '../Loader';
import { LogsModal } from '../LogsModal';
import usePunchInStore from '../../store/usePunchIn';
import ForwardTimer from '../ForwardTimer';
import { Modal } from 'react-bootstrap';
// import useTimeCounter from '../../store/useTimeCounter';

const EmployeeDashboard = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { punchIn, fetchAttendance, handleAttendance } = usePunchInStore();
    // const { stopTimer } = useTimeCounter();

    const [preEmps, setPreEmps] = useState([]);
    const [absEmps, setAbsEmps] = useState([]);

    const [workedTasks, setWorkedTasks] = useState([]);
    const [criticalTasks, setCriticalTasks] = useState([]);
    const [hoursWorked, setHoursWorked] = useState({
        today: '00:00:00',
        week: '00:00:00',
        month: '00:00:00'
    });

    const [leaves, setLeaves] = useState({
        unpaidLeaves: 0,
        currentBalance: 0,
        paidLeaves: 0,
        totalAssignedLeaves: "",
        from_date: "",
        to_date: ""
    });

    // useEffect(() => {
    //     if (punchIn) {
    //         stopTimer();
    //     }
    // }, [punchIn]);

    const [attendanceHours, setAttendanceHours] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/dashboard/fetchDashboardData.php`, {

                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    let message = "";
                    if (response.status === 401) {
                        // Handle unauthorized access
                        message = 'Unauthorized access. Please login again.';
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        message = 'Network response was not ok';
                    }
                    throw new Error(message);
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {

                    setPreEmps(responseData.workingEmps.filter(emp => emp.punch_in_time));
                    setAbsEmps(responseData.workingEmps.filter(emp => !emp.punch_in_time));

                    setWorkedTasks(responseData.workedTasks);
                    setCriticalTasks(responseData.criticalTasks);
                    setDatesOfWeek(responseData.datesOfWeek);
                    setHoursWorked({
                        today: responseData.totalHoursToday,
                        week: responseData.totalHoursOfWeek,
                        month: responseData.totalHoursOfMonth
                    });
                    setLeaves(responseData.leaves);
                    // responseData.totalSecondsToday, responseData.last_time_stamp
                    if (responseData.last_time_stamp == null) {
                        // the user is punched out 
                        setAttendanceHours(responseData.totalSecondsToday);
                    } else {
                        // get the current time stamp and calculate the difference
                        const currentTimeStamp = new Date();
                        const lastTimeStamp = new Date(responseData.last_time_stamp);
                        const timeDifference = currentTimeStamp - lastTimeStamp;

                        // i need time difference in seconds
                        const totalSeconds = Math.floor(timeDifference / 1000) + responseData.totalSecondsToday;
                        setAttendanceHours(totalSeconds);
                    }

                    if (responseData.todaysTask && responseData.todaysTask.length > 0) {
                        setTodayTasks(responseData.todaysTask)
                        ttModalShow();
                    }
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error(error);
                // alert(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();

        fetchAttendance();
    }, []);

    const [datesOfWeek, setDatesOfWeek] = useState([]);
    const chartRef = useRef(null);
    useEffect(() => {

        const hoursWorked = datesOfWeek.map(time => {
            const [hours, minutes, seconds] = time.split(':').map(parseFloat);
            const totalHours = hours + minutes / 60 + seconds / 3600;
            return totalHours;
        });

        const ctx = chartRef.current.getContext('2d');
        const myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                datasets: [{
                    label: 'Hours Worked',
                    data: hoursWorked,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const totalHours = context.parsed.y;
                                const hours = Math.floor(totalHours);
                                const minutes = Math.floor((totalHours - hours) * 60);
                                const seconds = Math.round((((totalHours - hours) * 60) - minutes) * 60);

                                const formattedTime = [
                                    hours.toString().padStart(2, '0'),
                                    minutes.toString().padStart(2, '0'),
                                    seconds.toString().padStart(2, '0')
                                ].join(':');

                                return `Time Worked: ${formattedTime}`;
                            }
                        }
                    }
                }
            }
        });

        return () => {
            myChart.destroy();
        };
    }, [datesOfWeek]);


    // For Logs Modal
    const [logIdAndTitle, setLogIdAndTitle] = useState({ taskId: '', title: '', assignedtoname: '' });

    // For Punch Out Modal
    const [punchOutModal, setPunchOutModal] = useState(false);
    const punchOutModalClose = () => setPunchOutModal(false);
    const punchOutModalShow = () => setPunchOutModal(true);
    const [punchOutReason, setPunchOutReason] = useState('');

    const handleAttendanceButton = () => {
        if (punchIn) {
            handleAttendance();
        } else {
            const now = new Date();
            const startHour = 9;
            const endHour = 18;
            const currentHour = now.getHours();

            if (currentHour >= startHour && currentHour < endHour) {
                punchOutModalShow();
            } else {
                handleAttendance();
            }
        }
    }


    const handlePunchOutModal = () => {
        handleAttendance(punchOutReason);
    }

    const [todayTasksModal, setTodayTasksModal] = useState(false);
    const ttModalClose = () => setTodayTasksModal(false);
    const ttModalShow = () => setTodayTasksModal(true);
    const [todayTasks, setTodayTasks] = useState([]);

    const convertMinutesToHHMMSS = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        const seconds = Math.floor((totalMinutes * 60) % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <>
            <Loader loading={loading} />
            <main className="main mb-0" id="main">
                <section className="section">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div className="card revenue-card fancy p-2">
                                    <div className="card-body">
                                        <h5 className="card-title mb-0">ATTENDANCE</h5>
                                        <hr className="text-primary-emphasis mt-0" />
                                        <button className="w-100 btn mb-3 attendanceBtn"
                                            style={{ backgroundColor: '#4154F1', color: '#fff' }}
                                            onClick={handleAttendanceButton} >
                                            {punchIn ? 'Punch In' : 'Punch Out'}
                                        </button>
                                        <hr className="text-primary-emphasis mt-0" />
                                        <div className="d-flex align-items-center gap-5">
                                            <h6 className='text-primary-emphasis'><b className=''>Today:</b></h6>
                                            <h6 className='text-primary-emphasis ms-2'>
                                                <ForwardTimer seconds={attendanceHours} running={!punchIn} /> Hours
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card revenue-card fancy p-2">
                                    <div className="card-body">
                                        <h5 className="card-title mb-0">TASK HOURS WORKED</h5>
                                        <hr className="text-primary-emphasis mt-0" />
                                        <div className="d-flex align-items-center gap-5">
                                            <h6 className='text-primary-emphasis'><b className=''>Today:</b></h6>
                                            <h6 className='text-primary-emphasis ms-2'>{hoursWorked.today} Hours</h6>
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <h6 className='text-primary-emphasis'><b className=''>This Week:</b></h6>
                                            <h6 className='text-primary-emphasis ms-1'>{hoursWorked.week} Hours</h6>
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <h6 className='text-primary-emphasis'><b>This Month:</b></h6>
                                            <h6 className='text-primary-emphasis'>{hoursWorked.month} Hours</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card revenue-card fancy p-2">
                                    <div className="card-body">
                                        <h5 className="card-title">HOURS WORKED <span>| Today</span></h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className='text-primary-emphasis'>{hoursWorked.today} Hours</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card revenue-card fancy p-2">
                                    <div className="card-body">
                                        <h5 className="card-title">HOURS WORKED <span>| This Week</span></h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className='text-primary-emphasis'>{hoursWorked.week} Hours</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card revenue-card fancy p-2">
                                    <div className="card-body">
                                        <h5 className="card-title">HOURS WORKED <span>| This Month</span></h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className='text-primary-emphasis'>{hoursWorked.month} Hours</h6>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-body h-100">
                                    <h5 className="card-title">WORKING HOUR ANALYSIS</h5>
                                    <canvas ref={chartRef}
                                        style={{
                                            maxHeight: '400px',
                                            display: 'block',
                                            boxSizing: 'border-box',
                                            height: '318px',
                                            width: '636px'
                                        }}>
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card revenue-card fancy p-2">
                            <div className="card-body">
                                <h5 className="card-title mb-0">MY LEAVES</h5>
                                <hr className="text-primary-emphasis mt-0" />
                                <h6 className='text-primary-emphasis'><span className='fw-bold'>Assigned Paid Leaves</span> ({new Date(leaves.from_date).toLocaleDateString('en-GB')} - {new Date(leaves.to_date).toLocaleDateString('en-GB')}): <Link to={`/leave/all-leaves?from_date=${leaves.from_date}&to_date=${leaves.to_date}`}>{leaves.totalAssignedLeaves} day(s)</Link></h6>
                                <hr className="text-primary-emphasis mt-0" />
                                <h6 className='text-primary-emphasis'>Paid Leaves: <Link to={`/leave/all-leaves?type=Paid&from_date=${leaves.from_date}&to_date=${leaves.to_date}`}>{leaves.paidLeaves} day(s)</Link></h6>
                                <hr className="text-primary-emphasis mt-0" />
                                <h6 className='text-primary-emphasis'>Unpaid Leaves: <Link to={`/leave/all-leaves?type=Unpaid&from_date=${leaves.from_date}&to_date=${leaves.to_date}`}>{leaves.unpaidLeaves} day(s)</Link></h6>
                                <hr className="text-primary-emphasis mt-0" />
                                <h6 className='text-primary-emphasis'>Current Balance: {leaves.currentBalance} day(s)</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">LAST 5 ATTEMPTED TASKS</h5>
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* taskId */}
                                                <th scope="col">#</th>
                                                {/* title */}
                                                <th scope="col">Title</th>
                                                {/* assignedtoname, assignedby */}
                                                <th scope="col">Assigned</th>
                                                {/* ttId, ttTitle */}
                                                <th scope="col">Task Type</th>
                                                {/* taskpriority */}
                                                <th scope="col">Priority</th>
                                                {/* status */}
                                                <th scope="col">Task Status</th>
                                                <th scope="col" className='text-center'>Logs</th>
                                                <th scope='col' className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workedTasks.map((task, index) => (
                                                <tr key={task.taskId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                    <td>
                                                        <span className='fw-bold'>to: </span>{task.assignedtoname}
                                                        <br />
                                                        <span className="fw-bold">by: </span>{task.assignedby}
                                                    </td>
                                                    <td>{task.ttTitle}</td>
                                                    <td>{task.taskpriority}</td>
                                                    <td>{task.taskstatus}</td>
                                                    {/* Task Logs */}
                                                    <td className='text-center'>
                                                        <button onClick={() => setLogIdAndTitle({
                                                            taskId: task.taskId,
                                                            title: task.title,
                                                            assignedtoname: task.assignedtoname
                                                        })} className='btn btn-secondary'>
                                                            <i className="bi bi-justify" />
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Link state={location.pathname} className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">CRITICAL/HIGH TASKS</h5>
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* taskId */}
                                                <th scope="col">#</th>
                                                {/* title */}
                                                <th scope="col">Title</th>
                                                {/* assignedtoname, assignedby */}
                                                <th scope="col">Assigned</th>
                                                {/* ttId, ttTitle */}
                                                <th scope="col">Task Type</th>
                                                {/* taskpriority */}
                                                <th scope="col">Priority</th>
                                                {/* status */}
                                                <th scope="col">Task Status</th>
                                                <th scope="col" className='text-center'>Logs</th>
                                                <th scope='col' className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {criticalTasks.map((task, index) => (
                                                <tr key={task.taskId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                                    <td>
                                                        <span className='fw-bold'>to: </span>{task.assignedtoname}
                                                        <br />
                                                        <span className="fw-bold">by: </span>{task.assignedby}
                                                    </td>
                                                    <td>{task.ttTitle}</td>
                                                    <td>
                                                        {
                                                            task.taskpriority === "Critical" ?
                                                                <span className="badge bg-danger">{task.taskpriority}</span> :
                                                                <span className="badge bg-warning">{task.taskpriority}</span>
                                                        }
                                                    </td>
                                                    <td>{task.taskstatus}</td>
                                                    {/* Task Logs */}
                                                    <td className='text-center'>
                                                        <button onClick={() => setLogIdAndTitle({
                                                            taskId: task.taskId,
                                                            title: task.title,
                                                            assignedtoname: task.assignedtoname
                                                        })}
                                                            className='btn btn-secondary'>
                                                            <i className="bi bi-justify" />
                                                        </button>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Link state={location.pathname} className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}>
                                                            <i className="bi bi-eye-fill" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Online Employees</h5>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Last Punched In</th>
                                                <th scope="col">Office Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {preEmps.map((emp, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{emp.username}</td>
                                                    <td>{emp.punch_in_time}</td>
                                                    <td>{emp.worked}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Offline Employees</h5>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Last Punched Out</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {absEmps.map((emp, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{emp.username}</td>
                                                    <td>{emp.punch_out_time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {logIdAndTitle.taskId && <LogsModal logIdAndTitle={logIdAndTitle} setLogIdAndTitle={setLogIdAndTitle} />}

            {/* Punch Out Modal */}
            <Modal show={punchOutModal} onHide={punchOutModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Punch Out</Modal.Title>
                </Modal.Header>
                <form onSubmit={handlePunchOutModal}>
                    <Modal.Body>
                        {/* ('Break', 'Day Off', 'Meeting', 'Switch Project', 'Completed') */}
                        <label className="form-label" htmlFor="stopreason">Punch Out Reason</label>
                        <select className="form-select" name="stopreason" id="stopreason"
                            value={punchOutReason.stopreason}
                            onChange={e => setPunchOutReason(e.target.value)}>
                            <option hidden selected value="">Select Punch Out Reason</option>
                            <option value="Break">Break</option>
                            <option value="Day Off">Day Off</option>
                            <option value="Lunch Break">Lunch Break</option>
                            <option value="Going out for Personal Work">Going out for Personal Work</option>
                            <option value="Going out for Client Meeting">Going out for Client Meeting</option>
                            <option value="To attend Phone Call">To attend Phone Call</option>
                        </select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-primary' type="submit">
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Create modal to show today's tasks */}
            <Modal show={todayTasksModal} onHide={ttModalClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Today's Tasks List - {new Date().toLocaleDateString("en-GB")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* taskId */}
                                <th scope="col">#</th>
                                {/* title */}
                                <th scope="col">Title</th>
                                {/* Assigned by */}
                                <th scope="col">Assigned By</th>
                                {/* ttId, ttTitle */}
                                <th scope="col">Task Type</th>
                                {/* taskpriority */}
                                <th scope="col">Priority</th>
                                {/* time required */}
                                <th scope="col">Time Required /  Taken</th>
                                {/* status */}
                                <th scope="col">Task Status</th>
                                {/* Action */}
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todayTasks.map((task, index) => (
                                <tr key={task.taskId}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{task.title} <br /> {" (" + task.pName + ")"}</td>
                                    <td>{task.assignedbyname}</td>
                                    <td>{task.ttTitle}</td>
                                    <td>{task.taskpriority}</td>
                                    <td>{convertMinutesToHHMMSS(task.timerequired)} / {task.timetaken}</td>
                                    <td>{task.taskstatus}</td>
                                    <td>
                                        <Link target='_blank' className="btn btn-info" to={`/tasks/viewtask/${task.taskId}`}> <i className="bi bi-eye-fill" /> </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EmployeeDashboard
