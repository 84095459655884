import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useGetTaskTypes = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getTaskTypes = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/tasks/taskType/getTaskTypes.php`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                    return [];
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                /*
                => responseData.taskTypes (An Array of Objects)
                -----------------------------------------------
                [
                    {
                        "ttId": "1",
                        "ttTitle": "Development 1",
                        "status": "0"
                    }, {...}, {...}
                ]
                */
                return responseData.taskTypes;
            } else {
                alert(responseData.error);
                return [];
            }
        } catch (error) {
            console.error('An error occurred in getting task types: ', error);
            alert('An error occurred. Please try again later.');
            return [];
        } finally {
            setLoading(false);
        }
    }
    return { getTaskTypes, loading };
}
export default useGetTaskTypes;