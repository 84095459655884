import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import usePType from '../../store/usePType';
import useGetProjectTypes from '../../hooks/useGetProjectTypes';
import { useNavigate } from 'react-router-dom';

const ProjectType = ({ rp }) => {

    const selectedRP = rp.find(role => role.name === 'Project Type') || {};
    const navigate = useNavigate();
    const { projectTypes, setProjectTypes } = usePType();
    const { loading, getProjectTypes } = useGetProjectTypes();

    // For read
    useEffect(() => {
        if (projectTypes.length === 0) {
            (async () => {
                setProjectTypes(await getProjectTypes());
            })();
        }
    }, []);

    // For delete
    const handleDelete = async (pTypeId) => {
        if (!window.confirm("Are you sure you want to delete this project type?")) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/projectType/deletePType.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ pTypeId })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized access
                    alert('Unauthorized access. Please login again.');
                    localStorage.clear();
                    navigate('/login');
                } else {
                    // Handle other error responses
                    throw new Error('Network response was not ok');
                }
            }

            let responseData;
            try {
                responseData = await response.json();
            } catch (error) {
                throw new Error('Error parsing JSON response');
            }

            if (responseData.success) {
                alert(responseData.message);
                setProjectTypes(projectTypes.filter((projectType) => projectType.pTypeId !== pTypeId));
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('An error occurred in deleting project type:', error);
            alert('An error occurred. Please try again later.');
        }
    }

    // For create and update 
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [pType, setPType] = useState({
        operationName: '',
        value: ''
    });
    const handleAdd = () => {
        setPType({
            pTypeId: '',
            operationName: 'Add',
            value: ''
        });
        handleShow();
    }

    const handleEdit = (pTypeId, pName) => {
        setPType({
            pTypeId: pTypeId,
            operationName: 'Edit',
            value: pName
        });
        handleShow();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { pTypeId, operationName, value } = pType;
        const submitData = async () => {
            if (!value) {
                alert('Please fill the name field');
                return;
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/controllers/projects/projectType/CUPType.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ pTypeId, operationName, value })
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized access
                        alert('Unauthorized access. Please login again.');
                        localStorage.clear();
                        navigate('/login');
                    } else {
                        // Handle other error responses
                        throw new Error('Network response was not ok');
                    }
                }

                let responseData;
                try {
                    responseData = await response.json();
                } catch (error) {
                    throw new Error('Error parsing JSON response');
                }

                if (responseData.success) {
                    alert(responseData.message);
                    handleClose();
                    if (operationName === 'Add') {
                        /*
                        => responseData.projectType (An Object)
                        ---------------------------------------
                        {
                            "pTypeId": "5",
                            "pName": "AMC"
                        }
                        */
                        setProjectTypes([...projectTypes, responseData.projectType]);
                    } else if (operationName === 'Edit') {
                        /*
                        => responseData.projectType (An Object)
                        ---------------------------------------
                        {
                            "pTypeId": "5",
                            "pName": "AMC"
                        }
                        */
                        setProjectTypes(projectTypes.map((pType) => pType.pTypeId === pTypeId ? responseData.projectType : pType));
                    }
                } else {
                    alert(responseData.error);
                }
            } catch (error) {
                console.error('An error occurred in editing or adding project type:', error);
                alert('An error occurred. Please try again later.');
            }
        }
        submitData();
    }
    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                {/* Button */}
                                <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
                                    <h5 className="card-title">TYPES OF PROJECT</h5>
                                    {(selectedRP && (selectedRP.add == 1)) && (
                                        <button type="button" className="btn btn-secondary" name='add' onClick={handleAdd}>
                                            <i className="bi bi-plus-lg" /><span className="ms-1">ADD</span>
                                        </button>)}
                                </div>
                                {/* Start Table */}
                                {(selectedRP && (selectedRP.view == 1)) ?
                                    (<table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                {selectedRP && (selectedRP.edit == 1 || selectedRP.delete == 1) &&
                                                    (<th scope="col" className='text-center'>Actions</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectTypes.map((projectType, index) => (
                                                <tr key={projectType.pTypeId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{projectType.pName}</td>
                                                    {selectedRP && (selectedRP.edit == 1 || selectedRP.delete == 1) &&
                                                        (<td>
                                                            <div className='d-flex justify-content-center gap-2'>
                                                                {selectedRP && (selectedRP.edit == 1) &&
                                                                    (<button type="button" className="btn btn-primary"
                                                                        onClick={() => handleEdit(projectType.pTypeId, projectType.pName)}>
                                                                        <i className="bi bi-pencil-fill" />
                                                                    </button>)}
                                                                {selectedRP && (selectedRP.delete == 1) &&
                                                                    (<button type="button" onClick={() => handleDelete(projectType.pTypeId)} className='btn btn-danger'>
                                                                        <i className="bi bi-trash3-fill" />
                                                                    </button>)}
                                                            </div>
                                                        </td>)}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>) : <p className='text-center'>No Access</p>}
                                {/* End Table */}
                                {/* Modal */}
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <h5 className="modal-title">  {pType.operationName} Project Type </h5>
                                    </Modal.Header>
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-body">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="name" placeholder="Enter Type of Project"
                                                    value={pType.value} onChange={(e) => setPType({ ...pType, value: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type='submit' className="btn btn-primary">Save changes</button>
                                        </div>
                                    </form>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default ProjectType
